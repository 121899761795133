<template>
  <div>
    <div>
      <div class="relative">
        <div class="absolute" style="right: 8px; top: 8px">
          <v-btn color="white" icon @click="close()">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <img
            v-if="!loading"
            src="https://s3.amazonaws.com/br.com.appdaturma.bucket/concursos/campanha-2024.jpeg"
            style="width: 100%;"
          />
          <div
            style="width: 100%; height: 100px; background-color: rgb(70,120,220);display: flex; align-items: center; justify-content: center"
            v-else
          >
            <v-progress-circular
              size="40"
              indeterminate
              color="white"
            ></v-progress-circular>
          </div>
        </div>
      </div>
      <div id="content">
        <div
          v-if="loading"
          class="text-center pa-5"
          style="height: 300px; display: flex; align-items: center; justify-content: center"
        >
          <v-progress-circular
            size="50"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="pa-4 pt-2 text-center">
            <div style="font-weight: 600; font-size: 24px; margin-bottom: 5px">
              Programa Formatura Custo Zero
            </div>
            <div
              style="font-family: 18px; width: 100%; margin:0 auto;"
              v-if="concurso"
            >
              <div v-html="concurso.descricao"></div>
            </div>
          </div>
          <div id="ofertas">
            <div
              v-for="item in promotions"
              :key="item.id"
              class="d-block text-center"
            >
              <div
                class="ofertaContain"
                :class="{ ativo: promotionSelected === item }"
                @click="selectPromotion(item)"
              >
                <div class="ofertaTitulo">
                  {{ item.titulo }}
                </div>
                <div class="ofertaPreco">
                  +
                  {{
                    Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(item.valor)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="actionsPrice" class="Actions" v-if="!loading">
      <div class="text-left pl-2">
        Aceita os
        <a href="" target="_termos_e_contrato">
          termos e condições da promoção
        </a>
      </div>
      <div class="text-right">
        <v-btn
          class="mr-2"
          @click="declinedOption()"
          :disabled="!promotionSelected"
          color="primary"
          text
        >
          <span style="text-transform: none">
            Não adicionar
          </span>
        </v-btn>
        <v-btn
          @click="confirmedOption()"
          :disabled="!promotionSelected"
          color="primary"
          depressed
        >
          <span style="text-transform: none">
            Adicionar bilhetes
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceBilhetes from "@/middleware/bilhetes";
import { gtag } from "@/utils/gtag";
/**
 * @param {Object} contribuicao
 * @event declinedOption - Quando o botão 'Nao adicionar' for clicado
 * @event confirmedOption - Quando o botão 'Adicionar bilhetes' for clicado
 *
 */
export default {
  props: ["contribuicao"],
  data() {
    return {
      concurso: null,
      promotions: [],
      promotionSelected: null,
      loading: true
    };
  },
  mounted() {
    gtag("event", "screen_fcz_offers_promotion");
    this.getPromocoes();
  },
  methods: {
    getPromocoes() {
      ServiceBilhetes.getConcurso()
        .then(resp => {
          if (resp.data.ofertas.length === 0) {
            this.$emit("declinedOption");
          } else {
            this.concurso = resp.data;
            this.promotions = resp.data.ofertas;
            this.promotionSelected = this.promotions[0];
            this.loading = false;
          }
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
          this.$emit("close");
        });
    },
    selectPromotion(item) {
      gtag("event", "fcz_select_promotion");
      this.promotionSelected = item;
    },
    close() {
      this.$emit("close");
    },
    declinedOption() {
      this.$emit("declinedOption");
    },
    confirmedOption() {
      this.$emit("confirmedOption", this.promotionSelected);
    }
  }
};
</script>

<style lang="scss" scoped>
.Actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 14px;
  margin-top: 30px;
  align-items: center;
}
.ofertaContain {
  display: inline-block;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  padding: 6px;
  border-radius: 10px;
  background-color: white;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(210, 210, 210);
  cursor: pointer;
  &.ativo {
    border-color: rgba(102, 34, 238, 0.7);
    outline: 5px solid rgba(102, 34, 238, 0.5);
  }
  @media screen and (max-width: 600px) {
    width: 96%;
  }
}
.ofertaTitulo {
  font-size: 20px;
  font-weight: bold;
}
.ofertaPreco {
  font-size: 30px;
  font-weight: bold;
  color: #62b445;
}
</style>
