<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <div
        class="close text-right pb-2 d-grid grid-col-1-auto align-center justify-center"
      >
        <div class="text-left text-h6">
          Detalhes do depósito
        </div>
        <v-btn class="pa-0 ma-0" text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <div
          class="pa-4 text-body-2 font-weight-normal text-break grey lighten-4 rounded-lg"
        >
          <div
            class="pa-1 text-subtitle-2 grey--text text--darken-1 letter-spacing-2"
          >
            DESCRIÇÃO
          </div>
          <div class="pa-1 text-body-1">
            {{ item.descricao ? item.descricao : "S/DESCRIÇÃO" }}
          </div>
        </div>
        <div class="py-2  d-grid grid-col-1-1">
          <div class="pa-3 text-center">
            <div class="font-weight-bold">Data de Vencimento</div>
            <div>{{ item.dataVencimento | dataReal }}</div>
          </div>
          <div class="pa-3 text-center">
            <div class="font-weight-bold">Data de Pagamento</div>
            <div>{{ item.dataPagamento | dataReal }}</div>
          </div>
          <div class="pa-3 text-center">
            <div class="font-weight-bold">Nome</div>
            <div>{{ item.nome }}</div>
          </div>
          <div class="pa-3 text-center">
            <div class="font-weight-bold">CPF/CNPJ</div>
            <div>{{ item.cpfCnpj }}</div>
          </div>
          <div class="pa-3 text-center">
            <div class="font-weight-bold">Data de Pagamento</div>
            <div>{{ item.dataPagamento | dataReal }}</div>
          </div>
          <div class="pa-3 text-center">
            <div class="font-weight-bold">Status</div>
            <div>
              <v-chip outlined :color="getColor(item.status)">
                {{ item.status | realName }}
              </v-chip>
            </div>
          </div>
          <div class="pa-3 text-center">
            <div class="font-weight-bold">Valor</div>
            <div>{{ item.valor | currency }}</div>
          </div>
        </div>
        <div class="my-2">
          <div class="pa-0 d-grid grid-col-auto-1 text-center customCard">
            <div>
              <div class="pa-4">
                <div
                  class="pa-3 grey--text text--darken-1 text-break"
                  @click="copiaCodigoDeBarra()"
                >
                  {{ item.codigoBarras }}
                </div>
                <v-textarea
                  v-show="false"
                  id="codbolcreated"
                  class="align-center text-center justify-center"
                  no-resize
                  :value="item.codigoBarras"
                  readonly
                  cols="1"
                  rows="2"
                  hide-details
                  @click="copiaCodigoDeBarra()"
                ></v-textarea>
              </div>
              <div class="pa-2">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn
                      color="primaryDash"
                      depressed
                      class="mx-1"
                      dark
                      @click="copiaCodigoDeBarra(item.codigoBarras)"
                      >Copiar</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn
                      color="secondaryDash"
                      depressed
                      dark
                      class="mx-1 pa-4"
                      @click="downloadPDF()"
                    >
                      <v-icon class="mr-2">
                        mdi-file-pdf
                      </v-icon>
                      Baixar PDF
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { URL_BASE } from "@/constants/base";
import {
  colorsForType,
  statusDisplay
} from "@/constants/participante/statusDePagamento";
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  filters: {
    realName(status) {
      return statusDisplay[status];
    },
    dataReal(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "S/REGISTRO";
      }
    }
  },
  data() {
    return {
      dialog: true,
      imageUrl: "",
      image: ""
    };
  },
  methods: {
    clickSelectFile() {
      this.$refs.fileInput.click();
    },
    getColor(status) {
      return colorsForType[status];
    },
    copiaCodigoDeBarra() {
      //let self = this;
      //let cb = this.resultadoFinal.codigoBarras;
      // release em andamento
      let copyTextarea = document.querySelector("#codbolcreated");
      copyTextarea.focus();
      copyTextarea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$root.infoModal("Copiado com sucesso!");
        } else {
          this.$root.errorModal("Não foi possível copiar");
        }
      } catch (err) {
        this.$root.errorModal("Não foi possível copiar");
        console.error(err);
      }
      /*
      CopiaIsso(cb, {
        onCopy: function() {
          self.$root.infoModal("Copiado com sucesso!");
        }
      });*/
    },
    downloadPDF() {
      window.open(URL_BASE + this.item.boletoUrl);
      alert("Download não implementado");
    }
  }
};
</script>

<style lang="scss" scoped>
.customCard {
  border: 1px solid rgba(230, 230, 230, 1) !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  .containClick {
    border-radius: 0px !important;
  }
}
</style>
