<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="1200px"
    transition="dialog-transition"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card class="cardContain">
      <div class="cardHeader">
        <div class="primaryDash--text font-weight-bold text-h5 tituloModal">
          Contribuições
        </div>
        <v-btn text @click="$emit('close')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <vCurrentUser :user="user" />
      <div v-if="$vuetify.breakpoint.xs">
        <vTimeline
          @abrirGerarContribuicao="abrirGerarContribuicao"
          @excluirContribuicao="excluirContribuicao"
          @editarItem="abrirEditar"
          :lista="listaFaturas"
        />
        <v-pagination
          v-model="page"
          :length="getTotalPages"
          color="#7275FF"
          circle
        ></v-pagination>
      </div>
      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-data-table
          :headers="headersFaturas"
          :items="listaFaturas"
          class="elevation-1 "
          :items-per-page="itemsPerPage"
          item-key="arquivoNome"
          :page.sync="page"
          hide-default-footer
          :loading="loadingCotas"
          loading-text="Carregando cotas..."
        >
          <template v-slot:no-data>
            Não existe faturas registradas
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.arquivoNome">
                <td>
                  {{ item.dataVencimento | getDataTime }}
                </td>
                <td>{{ item.descricao | existeRegistro }}</td>
                <td>
                  <v-chip outlined :color="getColorType(item.status)">
                    {{ getNameType(item.status) }}
                  </v-chip>
                </td>
                <td>{{ item.tipoPagamento | pagamentoGeradoType }}</td>
                <td>{{ item.valor | currency }}</td>
                <td style="width: auto">
                  <div v-if="item" class="text-right">
                    <v-btn
                      icon
                      color="secondary"
                      @click="abrirEditar(item)"
                      title="Editar"
                      :disabled="!PermissaoParaEditar(item)"
                    >
                      <v-icon
                        v-if="!PermissaoParaEditar(item)"
                        size="20"
                        color="black"
                        >mdi-pencil</v-icon
                      >
                      <v-icon v-else size="20" color="#7275FF">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="secondary"
                      @click="abrirConfirm(item)"
                      title="Editar"
                      :disabled="!PermissaoParaRemover(item)"
                    >
                      <v-icon
                        v-if="!PermissaoParaRemover(item)"
                        size="20"
                        color="black"
                      >
                        mdi-delete</v-icon
                      >
                      <v-icon v-else size="20" color="red">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                    <v-btn
                      primary
                      :disabled="!PermissaoParaGerarPAG(item)"
                      @click="abrirGerarContribuicao(item)"
                      icon
                    >
                      <v-icon size="20" color="green">
                        mdi-cash-sync
                      </v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-if="loadingCotas" v-slot:progress>
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
          </template>
        </v-data-table>
        <div class="pagination pa-4 text-center">
          <v-pagination
            v-model="page"
            :length="getTotalPages"
            color="#7275FF"
            circle
            :loading="loadingCotas"
          ></v-pagination>
        </div>
      </div>
    </v-card>
    <vEditar
      :userUID="user.usuarioId"
      :contribuicao="payloadEditar"
      @update="buscarContribuicoes"
      @close="closeEditar"
      v-if="modalEditar"
    />
    <vGerarMetodoPagamento
      v-if="modalGerarContribuicao"
      :divida="payloadDivida"
      :tipo="tipoDivida"
      @close="closeGerarContribuicao"
      @update="buscarContribuicoes"
    />
    <vConfirmModal
      v-if="confirmToggle"
      @close="closeConfirm()"
      @confirm="onConfirmOption()"
      mensagem="Tem certeza que deseja excluir essa parcela ?"
    />
  </v-dialog>
</template>

<script>
import vCurrentUser from "./currentUserCard.vue";
import serviceContribuicoes from "@/middleware/contribuicoes";
import {
  colorsForType,
  statusDisplay
} from "@/constants/participante/statusDePagamento";
import moment from "moment";
import vEditar from "./editor.vue";
import vTimeline from "./TimelineContribuicoes.vue";
import vGerarMetodoPagamento from "../pagamentoParcelas/gerarMetodoPagamento/index.vue";
import { TIPO_DIVIDA_CONTRIBUICAO } from "@/constants";
import vConfirmModal from "@/components/ConfirmModal.vue";

export default {
  components: {
    vCurrentUser,
    vEditar,
    vTimeline,
    vGerarMetodoPagamento,
    vConfirmModal
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contribuicaoSelecionada: null,
      confirmToggle: false,
      tipoDivida: TIPO_DIVIDA_CONTRIBUICAO,
      modalEditar: false,
      modalGerarContribuicao: false,
      payloadDivida: {},
      payloadEditar: {},
      loadingCotas: true,
      totalDePaginas: 0,
      page: 1,
      itemsPerPage: 8,
      filtros: {
        status: null
      },
      dialog: true,
      headersFaturas: [
        { text: "Data", value: "dataVencimento", align: "left" },
        { text: "Descrição", value: "descricao", align: "left" },
        { text: "Status", value: "status", align: "left" },
        { text: "Tipo Pagto", value: "tipoPagamento", align: "left" },
        { text: "Valor", value: "valor", align: "left" },
        { text: "", sortable: false, value: "", align: "right" }
      ],
      listaFaturas: []
    };
  },
  watch: {
    page: function() {
      this.buscarContribuicoes();
    }
  },
  beforeMount() {
    if (!this.$store.getters["aluno/userIsAdmin"]) {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] != "ADMIN") {
        this.$root.errorModal("Você não tem autorização para acessa essa rota");
        this.$router.push("/aluno/dashboard/perfil");
      }
    }
  },
  mounted() {
    this.buscarContribuicoes();
  },
  filters: {
    getDataTime(tempo) {
      if (!tempo) return "Sem registro";
      return moment(tempo).format("DD/MM/YYYY");
    },
    existeRegistro(item) {
      if (!item) return "Sem registro";
      else return item;
    },
    pagamentoGeradoType(tipoPG) {
      if (tipoPG) {
        if (tipoPG == "PIX") return "PIX";
        else if (tipoPG === "BOLETO") return "Boleto";
        else if (tipoPG === "TRANSFERENCIA_INTERNA")
          return "Transferencia Interna";
        else return tipoPG;
      } else {
        return "-";
      }
    }
  },
  computed: {
    getTotalPages() {
      return this.totalDePaginas;
    }
  },
  methods: {
    onConfirmOption() {
      this.confirmToggle = false;
      this.excluirContribuicao();
    },
    closeConfirm() {
      this.confirmToggle = false;
      this.contribuicaoSelecionada = null;
    },
    abrirConfirm(item) {
      this.confirmToggle = true;
      this.contribuicaoSelecionada = item;
    },
    abrirGerarContribuicao(dividaSelecionada) {
      this.payloadDivida = dividaSelecionada;
      this.modalGerarContribuicao = true;
    },
    closeGerarContribuicao() {
      this.modalGerarContribuicao = false;
      this.payloadDivida = {};
    },
    excluirContribuicao() {
      // if (!item || !this.PermissaoParaRemover(item)) {
      //   return false;
      // }
      //  A contribuição é setada quando o modal de confirm é aberta
      const contribuicaoID = this.contribuicaoSelecionada.contribuicaoId;
      serviceContribuicoes
        .excluirContribuicao(contribuicaoID)
        .then(resp => {
          this.buscarContribuicoes();
          this.$root.infoModal(resp.data);
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    isAdminEventUser() {
      return (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] == "ADMIN" ||
        this.$store.getters["aluno/userIsAdmin"]
      );
    },
    PermissaoParaGerarPAG(item) {
      if (!this.isAdminEventUser()) {
        return false;
      }
      if (item.status == "PAGO") {
        return false;
      }
      if (item.status == "EXPIRADO" || item.status == "NAO_PAGO") {
        return true;
      }
    },
    PermissaoParaRemover(item) {
      // EXPIRADO, NAO_PAGO, PAGO
      if (!this.isAdminEventUser()) {
        return false;
      }
      if (item.status == "PAGO") {
        return false;
      }
      if (item.tipoPagamento) {
        return false;
      }
      if (item.status == "EXPIRADO" || item.status == "NAO_PAGO") {
        return true;
      }
    },
    PermissaoParaEditar(item) {
      if (!this.isAdminEventUser()) {
        return false;
      }
      if (item.status == "PAGO") {
        return false;
      }
      if (item.status == "EXPIRADO" || item.status == "NAO_PAGO") {
        return true;
      }
    },
    closeEditar() {
      this.modalEditar = false;
      this.payloadEditar = {};
    },
    abrirEditar(item) {
      if (!this.PermissaoParaEditar(item)) {
        return false;
      }
      this.modalEditar = true;
      this.payloadEditar = item;
    },
    getNameType(type) {
      return statusDisplay[type];
    },
    getColorType(type) {
      return colorsForType[type];
    },
    trocaPagina(page) {
      this.page = page;
      this.buscarContribuicoes();
    },
    buscarContribuicoes() {
      this.loadingCotas = true;
      serviceContribuicoes
        .getPaginationCotaIndividual(
          this.user.usuarioId,
          this.page - 1,
          this.itemsPerPage,
          this.filtros
        )
        .then(resp => {
          this.totalDePaginas = resp.data.totalPages;
          this.listaFaturas = resp.data.content;
          this.loadingCotas = false;
        })
        .catch(err => {
          this.listaFaturas = [];
          this.loadingCotas = false;
          console.error(err.data);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
//d-grid grid-col-1-auto
.cardHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 20px;
}
.tituloModal {
  //padding: 20px;
  padding-left: 20px;
}
.cardContain {
  padding: 10px;
}
</style>
