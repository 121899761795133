import { render, staticRenderFns } from "./modalContribuicoes.vue?vue&type=template&id=f984e2a8&scoped=true&"
import script from "./modalContribuicoes.vue?vue&type=script&lang=js&"
export * from "./modalContribuicoes.vue?vue&type=script&lang=js&"
import style0 from "./modalContribuicoes.vue?vue&type=style&index=0&id=f984e2a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f984e2a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VCard,VChip,VDataTable,VDialog,VIcon,VPagination,VProgressLinear,VTimeline})
