<template>
  <div>
    <div class="iconContent">
      <div class="designIcon">
        <v-icon color="rgb(74, 76, 200)" size="50">mdi-check</v-icon>
      </div>
    </div>
    <div class="text-center mt-5 font-weight-medium">
      Pagamento efetuado com sucesso!
    </div>
    <div v-if="responsePix && responsePix.bilhetes">
      <div class="descricaoBilhetes">
        Esses são seus bilhetes comprados nesta transação
      </div>
      <div class="listaCards">
        <div
          class="itemCard"
          v-for="bilhete in responsePix.bilhetes"
          :key="bilhete.id"
        >
          {{ bilhete }}
        </div>
      </div>
    </div>
    <div class="observacao">
      <div>
        Você pode verificar novamente seus bilhetes no site
      </div>
      <a href="https://app.formaturacustozero.com.br/">
        https://app.formaturacustozero.com.br/
      </a>
    </div>
    <div class="text-center">
      <button class="btnOK" @click="close()">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagamentoPixSucesso",
  props: ["responsePix"],
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.descricaoBilhetes {
  color: #858585;
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.listaCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.itemCard {
  width: auto;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(94, 96, 255, 0.1);
  //border: 1px solid rgb(94, 96, 255);
  color: rgb(74, 76, 200);
}
.btnOK {
  background-color: #7275ff;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  width: 50%;
  &:hover {
    background-color: rgb(94, 96, 255);
  }
}
.observacao {
  color: #858585;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.iconContent {
  text-align: center;
}
.designIcon {
  border-radius: 100px;
  padding: 10px;
  user-select: none;
  display: inline-block;
  background-color: rgba(94, 96, 255, 0.1);
}
</style>
