<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    persistent
    :overlay="false"
    transition="dialog-transition"
    class="z-index: 50"
  >
    <v-card>
      <div
        class="text-center justify-center align-content-center align-center d-flex fill-height"
      >
        <div class="d-block">
          <div class="pa-5">
            <img src="@/assets/logo-deitada.png" style="width: 280px;" />
          </div>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div style="color: rgb(100,100,120); margin-top:20px;">
            {{ mensagem }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      mensagem: "Atualizando dados"
    };
  }
};
</script>

<style></style>
