<template>
  <div>
    <div class="tituloModal">
      <div style="flex: 1">
        Pagamento pelo saldo atual
      </div>
      <!--<div>
        <v-btn icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>-->
    </div>
    <div v-if="chapter === 1">
      <div class="containsInfo">
        <div class="infoItem">
          <div class="accountTitle">
            <span>
              <v-icon color="black">mdi-wallet-bifold-outline</v-icon>
              Em conta
            </span>
          </div>
          <div class="accountValue">{{ getMyValue | currency }}</div>
        </div>
        <div class="infoItem">
          <div class="accountTitle">
            <v-icon color="black">mdi-hand-coin-outline</v-icon> Valor a pagar
          </div>
          <div class="accountValue">{{ price | currency }}</div>
        </div>
      </div>
      <div>
        <div class="frasePagamento">
          Ao confirmar o pagamento será debitado em sua conta
        </div>
      </div>
      <div class="submenu">
        <div>
          <v-btn @click="cancelarOperacao()" depressed>
            voltar
          </v-btn>
        </div>
        <div>
          <v-btn color="secondary" @click="confirmPay()" depressed>
            Pagar
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="chapter === 2"></div>
  </div>
</template>

<script>
import serviceContribuicao from "@/middleware/contribuicoes";
import serviceDeposito from "@/middleware/depositos";
import { errorCompose } from "@/tools";
import { TIPO_DIVIDA_CONTRIBUICAO, TIPO_DIVIDA_DEPOSITO } from "@/constants";
export default {
  props: {
    price: {
      type: Number,
      required: true
    },
    divida: {
      type: Object,
      required: true
    },
    tipo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      wallet: 0,
      chapter: 1,
      loading: false
    };
  },
  mounted() {
    // * verificar saldo em conta novamente (por segurança)
    this.wallet = this.$store.getters["aluno/getSaldoBilhetes"];

    if (this.price <= 0 || this.price > this.wallet) {
      this.$emit("cancel");
      this.$root.errorModal(
        "Ocorreu um erro durante o carregamento de valores"
      );
      console.error(this.price, this.wallet);
    }
  },
  computed: {
    getMyValue() {
      return this.wallet;
    }
  },
  methods: {
    confirmPay() {
      if (this.tipo === TIPO_DIVIDA_CONTRIBUICAO) {
        const contribID = this.divida.contribuicaoId;
        serviceContribuicao
          .pagarContribuicaoComSaldo(contribID)
          .then(() => {
            this.$root.sucessoModal("Pagamento efetuado com sucesso!");
            this.$store.dispatch("aluno/REQUEST_USER");
            this.$emit("pago");
          })
          .catch(err => {
            this.$root.errorModal(errorCompose(err));
          });
        return;
      }
      if (this.tipo === TIPO_DIVIDA_DEPOSITO) {
        serviceDeposito
          .pagarDepositoComSaldo(this.divida.depositoId)
          .then(() => {
            this.$root.sucessoModal("Pagamento efetuado com sucesso!");
            this.$store.dispatch("aluno/REQUEST_USER");
            this.$emit("pago");
          })
          .catch(err => {
            this.$root.errorModal(errorCompose(err));
          });
        return;
      }
    },
    cancelarOperacao() {
      this.$emit("cancel");
    },
    ruleComponent() {
      if (this.price <= 0 || this.price < this.wallet) {
        this.$emit("cancel");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.frasePagamento {
  color: rgb(120, 120, 120);
  font-weight: 300;
  padding: 10px 20px;
  text-align: center;
}
.submenu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}
.tituloModal {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 8px;
  color: rgb(30, 30, 30);
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.containsInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5;
  @media screen and (max-width: 599px) {
    grid-template-columns: 1fr;
  }
}
.infoItem {
  padding: 10px;
  text-align: center;
}
.accountTitle {
  padding: 10px;
  font-size: 16px;
  color: black;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
.accountValue {
  font-size: 20px;
  font-weight: 500;
  color: #7275ff;
}
</style>
