<template>
  <div>
    <h2>
      Desculpe, esse link não consta no nosso sistema
    </h2>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h2 {
  padding: 20px;
  color: $colorDarkPurple;
}
</style>
