import axios from "axios";

export default {
  concordarCustoZero() {
    return axios.post("/v1/usuario/aceite-fcz", {});
  },
  naoExibirMaisFCZ() {
    return axios.post("/v1/usuario/nao-exibir-mais-fcz", {});
  }
};
