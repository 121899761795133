<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-4 ">
        <v-row justify="center">
          <v-col cols="12" sm="10">
            <div class="px-2 text-h6 font-weight-normal">
              Legendas
            </div>
          </v-col>
          <v-col cols="0" sm="2" v-if="!$vuetify.breakpoint.xs">
            <v-btn text @click="close()">
              <v-icon color="grey darken-2">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="mt-n1 mt-sm-0">
            <v-card flat class="ma-1">
              <v-icon color="#43AE2E" class="pa-2">
                mdi-circle
              </v-icon>
              <div class="d-inline-block">
                Pago
              </div>
            </v-card>
            <v-card flat class="ma-1">
              <v-icon color="#D63031" class="pa-2">
                mdi-circle
              </v-icon>
              <div class="d-inline-block">
                Recusado
              </div>
            </v-card>
            <v-card flat class="ma-1">
              <v-icon color="#0984E3" class="pa-2">
                mdi-circle
              </v-icon>
              <div class="d-inline-block">
                Aprovado
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat class="ma-1">
              <v-icon color="#B2BEC3" class="pa-2">
                mdi-circle
              </v-icon>
              <div class="d-inline-block">
                Cancelado
              </div>
            </v-card>
            <v-card flat class="ma-1">
              <v-icon color="#6C5CE7" class="pa-2">
                mdi-circle
              </v-icon>
              <div class="d-inline-block">
                Em votação
              </div>
            </v-card>
            <v-card flat class="ma-1">
              <v-icon color="#ffa801" class="pa-2">
                mdi-circle
              </v-icon>
              <div class="d-inline-block">
                Empate
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="$vuetify.breakpoint.xs">
          <v-btn text block @click="close()">
            <v-icon color="grey darken-2">mdi-close</v-icon>
            <div class="grey--text text--darken-2">
              FECHAR
            </div>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
