<template>
  <div>
    <vTitulo>Participantes</vTitulo>
    <div class="px-5 mb-3 mt-n5 text-left font-weight-bold text-left">
      <div class="text-sm-h6 text-subtitle-1 primary--text">
        {{ GET_TURMA.nome }}
      </div>
    </div>
    <div class="mx-4 lowShadow rounded-lg  mt-2 mb-2 white pa-2 px-4">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="search"
            hide-details
            dense
            placeholder="Nome do Participante"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-row justify="center" align="center">
            <v-col cols="4">
              <v-menu
                top
                offset-y
                :close-on-content-click="false"
                v-model="menuFiltro"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    style="background-color: white;background-color: silver"
                    color="grey lighten-5"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                  >
                    <v-icon style="color:#9EA2A9;">
                      mdi-filter-variant
                    </v-icon>
                    <span class="secondaryDash--text">Filtros</span>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="item in filterOptions" :key="item.id">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="filtro"
                        :label="item.label"
                        color="primary"
                        :value="item.id"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                top
                offset-y
                :close-on-content-click="false"
                v-model="menuSort"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    style="background-color: white;background-color: silver"
                    color="grey lighten-5"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                  >
                    <v-icon style="color:#9EA2A9;">
                      mdi-sort
                    </v-icon>
                    <span class="secondaryDash--text">Ordenar</span>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="item in sortOptions" :key="item.id">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="sort"
                        :label="item.label"
                        color="primary"
                        :value="item.id"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="4" class="text-center">
              <v-row justify="center" align="center">
                <v-col cols="6">
                  <v-avatar size="55">
                    <v-icon
                      size="35"
                      class="mx-auto"
                      @click="tipoLista = false"
                      :color="tipoLista == false ? 'black' : 'grey'"
                    >
                      mdi-view-grid
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="6">
                  <v-avatar size="55">
                    <v-icon
                      size="45"
                      class="mx-auto"
                      @click="tipoLista = true"
                      :color="tipoLista == true ? 'black' : 'grey'"
                    >
                      mdi-view-list
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="alunos mx-2 mx-sm-2">
      <div v-if="tipoLista">
        <v-list two-line style="background-color:rgba(255,255,255,0);">
          <div v-if="getPermissaoAdminTurma">
            <!-- add novo aluno aqui -->
            <vNovoParticipanteLine
              @novoParticipante="NovoParticipanteModal()"
              v-if="getPermissaoAdminTurma"
            />
          </div>
          <template v-for="(aluno, index) in GET_LISTA_ALUNOS">
            <vLineAluno
              @abrirContribuicao="abrirContribuicoes(aluno)"
              @callUpdate="ATUALIZA_LISTA"
              :key="index"
              :item="aluno"
              :permissao="getPermissao"
            />
          </template>
        </v-list>
      </div>
      <div v-else>
        <div class="d-flex justify-start flex-wrap">
          <div
            v-if="getPermissaoAdminTurma"
            class="cardParticipante plusBtn lowShadow"
          >
            <v-avatar
              class="click"
              size="100"
              color="#ECEFF5"
              @click="NovoParticipanteModal()"
            >
              <v-icon size="35">mdi-plus</v-icon>
            </v-avatar>
            <div class="descricaoADD px-4 pt-3">
              Você pode adicionar novos participantes
            </div>
            <div class="grey--text text--darken-4 py-3 pa-4 text-break">
              <v-btn
                class="br-20 text-body-2 text-break text-wrap"
                depressed
                large
                color="primaryDash"
                dark
                @click="NovoParticipanteModal()"
                >ADICIONAR</v-btn
              >
            </div>
          </div>
          <vCardParticipante
            @abrirContribuicao="abrirContribuicoes(aluno)"
            @callUpdate="ATUALIZA_LISTA"
            v-for="(aluno, index) in GET_LISTA_ALUNOS"
            :key="index"
            :item="aluno"
          />
        </div>
      </div>
    </div>
    <vAddAluno
      @handleUpdate="ATUALIZA_LISTA"
      @handleClose="closeNovoParticipante"
      :turmaAtiva="GET_TURMA_ATIVA"
      v-if="modalNovoAluno && getPermissaoAdminTurma"
    />
    <vModalContribuicoes
      :user="payloadParticipante"
      @close="closeModalContribuicoes()"
      v-if="modalContribuicoes"
    />
  </div>
</template>

<script>
import vCardParticipante from "./vCardParticipante.vue";
import vLineAluno from "./ParticipanteLine.vue";
import vAddAluno from "./addAlunoModal.vue";
import { normalize } from "@/constants/stringUtils";
import vNovoParticipanteLine from "./NovoParticipanteViewLine.vue";
import vModalContribuicoes from "../gerarParcelas/modalContribuicoes.vue";

export default {
  name: "alunos",
  components: {
    vCardParticipante,
    vLineAluno,
    vAddAluno,
    vNovoParticipanteLine,
    vModalContribuicoes
  },
  data() {
    return {
      menuFiltro: false,
      menuSort: false,
      sort: "nomeFormando",
      filtro: 6,
      search: "",
      tipoLista: false,
      modalNovoAluno: false,
      modalContribuicoes: false,
      payloadParticipante: null,
      filterOptions: [
        { id: 1, label: "Todos" },
        { id: 6, label: "Autorizado" },
        { id: 2, label: "Comissão" },
        { id: 3, label: "Pendentes" },
        { id: 4, label: "Não autorizados" },
        { id: 5, label: "Contrato cancelado" }
      ],
      sortOptions: [
        { id: "nomeFormando", label: "Nome Formando" },
        { id: "nome", label: "Nome Responsável" },
        { id: "usuarioId", label: "Código" }
      ]
    };
  },
  methods: {
    closeModalContribuicoes() {
      this.modalContribuicoes = false;
      this.payloadParticipante = null;
    },
    abrirContribuicoes(participante) {
      this.modalContribuicoes = true;
      this.payloadParticipante = participante;
    },
    closeNovoParticipante() {
      this.modalNovoAluno = false;
    },
    NovoParticipanteModal() {
      this.modalNovoAluno = true;
    },
    ATUALIZA_LISTA() {
      this.$store
        .dispatch("aluno/REQUEST_ALUNOS_DA_TURMA", { sort: this.sort })
        .catch(err => {
          this.$root.errorModal(err);
        });
    }
  },
  mounted() {
    this.ATUALIZA_LISTA();
  },
  watch: {
    GET_TURMA_ATIVA() {
      this.ATUALIZA_LISTA();
    }
  },
  computed: {
    viewPortWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      } else {
        return "250";
      }
    },
    getAlunosDaTurma() {
      return this.$store.getters["aluno/getAlunosDaTurma"];
    },
    GET_FILTROS() {
      return this.filtros;
    },
    getPermissaoAdminTurma() {
      if (this.$store.getters["aluno/userIsAdmin"]) return true;
      else if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN")
        return true;
      else return false;
    },
    getPermissao() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    },
    GET_LISTA_ALUNOS() {
      let arrayOne = this.$store.getters["aluno/getAlunosDaTurma"].filter(
        item => {
          let NameUp = normalize(item.nome);
          let nomeFormandoUp = normalize(item.nomeFormando);
          let SearchUp = normalize(this.search);
          let SobreNome = normalize(item.sobreNome);
          let userId = String(item.usuarioId);
          return (
            NameUp.match(SearchUp) ||
            SobreNome.match(SearchUp) ||
            nomeFormandoUp.match(SearchUp) ||
            userId.match(SearchUp)
          );
        }
      );

      arrayOne.sort((a, b) => {
        let field = this.sort || "nomeFormando";
        const nameA = normalize(a[field]);
        const nameB = normalize(b[field]);

        if (!nameA) {
          return 1;
        }
        if (!nameB) {
          return -1;
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      if (this.filtro == "1") {
        return arrayOne;
      }
      return arrayOne.filter(item => {
        if (this.filtro == "1") return true;
        else if (this.filtro == "2") return item.permissaoTurma == "COMISSAO";
        else if (this.filtro == "3") return item.conviteStatus == "PENDENTE";
        else if (this.filtro == "4")
          return item.conviteStatus == "NAO_AUTORIZADO";
        else if (this.filtro == "5")
          return item.conviteStatus == "CONTRATO_CANCELADO";
        else if (this.filtro == "6") return item.conviteStatus == "AUTORIZADO";
      });
    },
    GET_TURMA_ATIVA() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    GET_TURMA() {
      return this.$store.getters["aluno/getTurma"];
    }
  }
};
</script>
<style lang="scss" scoped>
.descricaoADD {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding-top: 5px;
  color: #778192;
}
.cardParticipante {
  display: inline-grid;
  margin: 10px;
  &.userContain {
    //grid-template-columns: 1fr;
    display: inline-block;
  }
  background-color: white;
  width: 250px;
  min-height: 350px;
  border-radius: 20px;
  text-align: center;
  &.plusBtn {
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    vertical-align: center;
    min-height: 350px;
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}

$corAtivo: #9ae6b4;
$corPendente: #d53f8c;
$corBloqueado: #f1f1f1;
.itemlist {
  border-left-width: 5px;
  border-left-style: solid;
  box-shadow: 1px 1px 10px rgba(50, 50, 50, 0.3);
  margin: 3px;
  &.autorizado {
    border-left-color: $corAtivo;
  }
  &.naoAutorizado {
    border-left-color: $corBloqueado;
    opacity: 0.5;
  }
  &.pendente {
    border-left-color: $corPendente;
  }
}
span.icon {
  color: #5f5f5f;
  font-size: 32px;
  &:hover {
    cursor: pointer;
  }
  &.ativo {
    color: black;
  }
}

.ui {
  background-color: white;
}
.roundStep {
  padding: 30px;
  background-color: #eceff5;
  color: #818181;
  border-radius: 100px;
  font-size: 120px;
  display: inline-grid;
}
.smallText {
  margin-top: 10px;
  color: #778192;
  font-size: 16px;
}
.grid {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
}
</style>
