<template>
  <v-dialog
    v-model="dialog"
    max-width="1024px"
    persistent
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <div
        class="d-block text-right"
        v-if="getTurma.diasRestanteTeste !== 0 && !impeditivo"
      >
        <v-icon @click="closemodal()">mdi-close</v-icon>
      </div>
      <vSelectPacs @close="closemodal" :isModalMode="true" />
    </v-card>
  </v-dialog>
</template>

<script>
import vSelectPacs from "./selectPacote";
export default {
  props: ["impeditivo"],
  components: {
    vSelectPacs
  },
  data() {
    return {
      dialog: true
    };
  },
  computed: {
    getTurma() {
      return this.$store.getters["aluno/getTurma"];
    }
  },
  methods: {
    closemodal() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
