<template>
  <div>
    <div class="pr-4 pt-0 mb-2 align-center">
      <v-titulo class="text-center text-sm-left">Pagamento parcelas</v-titulo>
    </div>
    <div>
      <div class="hidden-sm-and-up">
        <v-card class="ma-2 pa-2 text-center lowShadow">
          <div class="my-2">
            <div class="saldoTitulo">Parcela atual</div>
            <div class="saldoValor">{{ parcelaAtual | currency }}</div>
          </div>
          <div class="my-2">
            <div class="saldoTitulo">Total pago</div>
            <div class="saldoValor">{{ totalPago | currency }}</div>
          </div>
          <div class="my-2">
            <div class="saldoTitulo">Total a pagar</div>
            <div class="saldoValor primary--text">
              {{ meta | currency }}
            </div>
          </div>
        </v-card>
      </div>
      <v-row justify="center">
        <!-- CARD 01 -->
        <v-col cols="12" sm="12" md="7" class="order-2 order-sm-1">
          <v-card class="card card01 lowShadow ma-2 ma-md-0 ml-md-2">
            <div class="saldos pa-3 d-none d-sm-block">
              <v-row dense>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="text-center text-sm-left"
                >
                  <v-card elevation="0">
                    <div class="saldoTitulo">Parcela atual</div>
                    <div class="saldoValor">{{ parcelaAtual | currency }}</div>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="text-center text-sm-left"
                >
                  <v-card elevation="0">
                    <div class="saldoTitulo">Total pago</div>
                    <div class="saldoValor">{{ totalPago | currency }}</div>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="text-center text-sm-left"
                >
                  <v-card elevation="0">
                    <div class="saldoTitulo">Total a pagar</div>
                    <div class="saldoValor primary--text">
                      {{ meta | currency }}
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <div class="actions text-center" id="topmylist">
              <v-select
                v-model="filtroListaSelected"
                :items="listaDeFiltros"
                item-text="descricao"
                item-value="id"
                solo
              >
              </v-select>
            </div>
            <div class="lista">
              <div class="d-block d-sm-none">
                <vTimelineContribuicoes
                  @selectPagamento="openSelectMethodsPayment"
                  @abrirInfoBoleto="abrirInfoBoleto"
                  @abrirInfoPix="abrirInfoPix"
                  @abrirRegerarPix="abrirRegerarPix"
                  :lista="listaFaturas"
                />
              </div>
              <v-data-table
                :headers="headersFaturas"
                :items="listaFaturas"
                class="elevation-0 d-none d-sm-block"
                :items-per-page="itemsPerPage"
                item-key="arquivoNome"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                :loading="loadingListaBoletos"
                loading-text="carregando..."
              >
                <template v-slot:no-data>
                  Não existe faturas registradas
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.arquivoNome">
                      <td style="min-width: 20px">
                        {{ item.dataVencimento | ItsNullDate }}
                      </td>
                      <td style="min-width: 20px">
                        {{ item.dataPagamento | ItsNullDate }}
                      </td>
                      <td>
                        <v-chip
                          v-if="item.status == 'PAGO'"
                          outlined
                          :color="getColorStatus(item.status)"
                        >
                          {{ item.status | getStatusDisplay }}
                        </v-chip>
                        <v-chip
                          v-else
                          outlined
                          :color="getColorStatus(item.status)"
                        >
                          {{ item.status | getStatusDisplay }}
                        </v-chip>
                      </td>
                      <td>{{ item.valor | currency }}</td>
                      <td style="min-width: 50px" class="text-center">
                        <div v-if="item.status == 'EXPIRADO'">
                          <v-btn
                            color="secondaryDash"
                            depressed
                            dark
                            small
                            @click="openSelectMethodsPayment(item)"
                          >
                            PAGAR
                          </v-btn>
                        </div>
                        <div v-if="item.status == 'NAO_PAGO'">
                          <div v-if="item.tipoPagamento == 'BOLETO'">
                            <v-btn
                              text
                              small
                              color="primary"
                              @click="abrirInfoBoleto(item)"
                              title="Abrir boleto"
                            >
                              <v-icon size="30"> mdi-barcode </v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                              color="secondaryDash"
                              depressed
                              dark
                              small
                              @click="openSelectMethodsPayment(item)"
                            >
                              PAGAR
                            </v-btn>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <div class="pagination">
                <v-pagination
                  :disabled="loadingListaBoletos"
                  color="#7275FF"
                  circle
                  :length="pageCount"
                  v-model="page"
                ></v-pagination>
              </div>
            </div>
          </v-card>
        </v-col>
        <!-- CARD 02, 03 -->
        <v-col cols="12" sm="12" md="5" class="order-1 order-sm-2">
          <!-- CARD 02 -->
          <v-card class="card card02 lowShadow ma-2 ma-md-0 mr-md-2">
            <v-row justify="center">
              <v-col
                cols="12"
                sm="6"
                class="text-subtitle-1 text-center text-sm-left text-sm-h6 dashPrimary--text"
              >
                Meta de Contribuições
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <v-select
                  :items="listSelectYears"
                  v-model="filterChartFaturamento"
                  :value="valorInicialFiltroMeta"
                  solo
                  dense
                  @change="requestDadosChart01"
                  v-if="listSelectYears.length > 0"
                >
                  <template v-slot:item="{ item }">
                    <v-row justify="center" style="z-index: 22">
                      <v-col cols="3"
                        ><v-icon>mdi-calendar-range</v-icon></v-col
                      >
                      <v-col cols="9">{{ item }}</v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <apexchart
              width="100%"
              height="200px"
              type="bar"
              :options="chart01.chartOptions"
              :series="chart01.series"
            ></apexchart>
          </v-card>
          <br />
          <!-- CARD 03 -->
          <v-card class="card card03 lowShadow ma-2 ma-md-0 mr-md-2">
            <!-- donut -->
            <div>
              <apexchart
                width="100%"
                height="300"
                type="donut"
                :options="chart02.options"
                :series="chart02.series"
                v-if="openChart2"
              ></apexchart>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <vModalGerarContribuicao
      @close="modalGerarContribuicao = false"
      v-if="modalGerarContribuicao == true"
      @update="updateDashContribuicoes"
    />
    <vGerarMetodoPagamento
      v-if="modalControlePagamento"
      :divida="payloadItem"
      :tipo="tipoDivida"
      @close="modalControlePagamento = false"
      @update="updateDashContribuicoes"
    />
    <vModalPixRegerar
      @update="updateDashContribuicoes"
      @close="modalRegerarPix = false"
      v-if="modalRegerarPix"
      :divida="payloadItem"
      :tipo="tipoDivida"
    />
    <vModalBoletoInfo
      style="z-index: 9999"
      @update="updateDashContribuicoes"
      @close="modalViewBoleto = false"
      v-if="modalViewBoleto"
      :divida="payloadItem"
      :tipo="tipoDivida"
    />
    <vModalInfoPix
      style="z-index: 9999"
      @update="updateDashContribuicoes"
      @close="modalInfoPix = false"
      v-if="modalInfoPix"
      :divida="payloadItem"
      :tipo="tipoDivida"
    />
  </div>
</template>

<script>
import serviceContribuicoes from "@/middleware/contribuicoes";
import moment from "moment";
import vModalGerarContribuicao from "./gerarContribuicao.vue";
import vTimelineContribuicoes from "./vTimeline.vue";
import serviceTypes from "@/middleware/tiposController";
import vGerarMetodoPagamento from "./gerarMetodoPagamento/index.vue";
import vModalPixRegerar from "./gerarMetodoPagamento/regerarPix.vue";
import vModalBoletoInfo from "./gerarMetodoPagamento/infoBoleto.vue";
import vModalInfoPix from "./gerarMetodoPagamento/infoPix.vue";
import {
  colorsForType,
  statusDisplay,
  defined
} from "@/constants/participante/statusDePagamento";
import { TYPE_PAYMENT } from "@/constants/participante/tiposDePagamentos";
import { TIPO_DIVIDA_CONTRIBUICAO } from "@/constants";
export default {
  components: {
    vTimelineContribuicoes,
    vModalGerarContribuicao,
    vGerarMetodoPagamento,
    vModalPixRegerar,
    vModalBoletoInfo,
    vModalInfoPix
  },
  data() {
    return {
      tipoDivida: TIPO_DIVIDA_CONTRIBUICAO,
      payloadItem: {},
      loadingListaBoletos: false,
      meta: 0,
      parcelaAtual: 0,
      totalPago: 0,
      selectCopyItem: null,
      modalRegerarPix: false,
      modalControlePagamento: false,
      modalCopyToggle: false,
      modalGerarContribuicao: false,
      modalViewBoleto: false,
      modalInfoPix: false,
      openChart2: false,
      chart01: {
        chartOptions: {
          colors: ["#9B51E0", "#E2E8F0"],
          dataLabels: {
            style: {
              fontSize: "10px"
            }
          },
          chart: {
            stackType: "100%",
            stacked: true,
            id: "vuechart-example",
            type: "bar",
            toolbar: {
              show: true
            }
          },
          xaxis: {
            categories: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez"
            ]
          },
          yaxis: {}
        },

        series: [
          {
            name: "Contribuição",
            data: []
          },
          {
            name: "Meta Restante",
            data: []
          }
        ]
      },
      chart02: {
        options: {
          noData: {
            text: "Sem dados",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "rgb(50,50,50)",
              fontSize: "14px"
            }
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {},
                  value: {}
                }
              }
            }
          },
          chart: {
            type: "donut"
          },
          legend: {
            show: true
          },
          tooltip: {
            enabled: false
          },
          dataLabels: {
            style: {
              fontSize: "8px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold"
            },
            offset: Number
          },
          labels: ["Pendentes", "Completas", "Atrasadas"],
          colors: ["#FFB946", "#2ED47A", "#F7685B"],
          responsive: [
            {
              breakpoint: 400,
              options: {
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        },
        series: []
        // PEND, COMP, ATRAS
      },
      filtroListaSelected: "NAO_PAGO",
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      listaDeFiltros: [],
      searchFaturas: "",
      headersFaturas: [
        { text: "DT Vencimento", value: "dataVencimento", align: "left" },
        { text: "DT Pagamento", value: "dataPagamento", align: "left" },
        { text: "Status", value: "status", align: "left" },
        { text: "Valor", value: "valor", align: "left" },
        { text: "Opções", value: "", align: "right" }
      ],
      listaFaturas: [],
      filterChartFaturamento: "",
      listSelectYears: [],
      valorInicialFiltroMeta: "",
      i: 1 //necessario para não emitir erro VERIFICAR OCORRENCIA
    };
  },
  filters: {
    getStatusDisplay(codename) {
      return statusDisplay[codename];
    },
    ItsNullDate: function(snap) {
      if (snap == null) {
        return "S/Registro";
      } else {
        return moment(snap).format("DD/MM/YYYY");
      }
    },
    statusReplace: function(str) {
      if (str == "NAO_PAGO") {
        return "Não pago";
      } else if (str == "PAGO") {
        return "Pago";
      } else if (str == "CANCELADO") {
        return "Cancelado";
      }
    }
  },
  watch: {
    page: function() {
      this.requestListaDeBoletos();
    },
    filtroListaSelected: function() {
      this.requestListaDeBoletos();
    }
  },
  methods: {
    abrirInfoPix(item) {
      this.modalInfoPix = true;
      this.payloadItem = item;
    },
    abrirInfoBoleto(item) {
      this.modalViewBoleto = true;
      this.payloadItem = item;
    },
    abrirRegerarPix(item) {
      this.modalRegerarPix = true;
      this.payloadItem = item;
    },
    openSelectMethodsPayment(item) {
      this.modalControlePagamento = true;
      this.payloadItem = item;
    },
    AbrirModalCopia(item) {
      this.selectCopyItem = item;
      this.modalCopyToggle = true;
    },
    fecharModalGerarContribuicao() {
      this.modalGerarContribuicao = false;
      this.requestListaDeBoletos();
    },
    abrirNovaContribuicao() {
      this.modalGerarContribuicao = true;
    },
    getColorStatus(code) {
      return colorsForType[code];
    },
    requestListaDeBoletos() {
      this.loadingListaBoletos = true;

      if (this.$vuetify.breakpoint.xsOnly) {
        document.getElementById("topmylist").scrollIntoView();
      }
      let config = {
        status: this.filtroListaSelected
      };
      serviceContribuicoes
        .paginacaoContribuicoes(this.page - 1, this.itemsPerPage, config)
        .then(resp => {
          this.pageCount = resp.data.totalPages;
          this.listaFaturas = resp.data.content;
          this.loadingListaBoletos = false;
        })
        .catch(err => {
          this.listaFaturas = [];
          this.$root.errorModal(err.data);
          this.loadingListaBoletos = false;
        });
    },
    requestDadosChart01() {
      let ano = this.filterChartFaturamento;
      serviceContribuicoes.getChartMetas(ano).then(resp => {
        let contrib = resp.data.contribuicao;
        let metaRest = resp.data.metaRestante;
        this.chart01.series = [contrib, metaRest];
      });
    },
    requestDadosChart02() {
      serviceContribuicoes
        .getChartContribuicao()
        .then(resp => {
          let bin = resp.data;
          // PEND, COMP, ATRAS
          this.openChart2 = false;
          if (bin.pendentes > 0 || bin.pagos > 0 || bin.atrasados > 0) {
            this.chart02.series = [bin.pendentes, bin.pagos, bin.atrasados];
            this.openChart2 = true;
          } else {
            this.chart02.series = [];
            this.openChart2 = true;
          }
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.error(err);
        });
    },
    requestResumo() {
      serviceContribuicoes.getResumoContribuicao().then(resp => {
        let bin = resp.data;
        this.meta = bin.meta;
        this.parcelaAtual = bin.parcelaAtual;
        this.totalPago = bin.totalPago;
      });
    },
    updateDashContribuicoes() {
      this.requestResumo();
      this.requestDadosChart01();
      this.requestDadosChart02();
      this.requestListaDeBoletos();
    },
    isDue(dataVencimento) {
      var hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      hoje.setMinutes(0, 0, 0);
      hoje.setMilliseconds(0);
      return new Date(dataVencimento) < hoje;
    }
  },
  computed: {
    getCodeTipoPagamento() {
      return TYPE_PAYMENT;
    },
    statusCODE() {
      return defined;
    },
    getAnoAtual() {
      return moment().format("yyyy");
    },
    GET_TURMA() {
      return this.$store.getters["aluno/getTurma"];
    },
    getPermissaoTurma() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    }
  },
  beforeMount() {
    let arraySpread = [];
    let i = 1;
    for (i; i < 15; i++) {
      //Fith
      if (i == 1) {
        let pay1 = moment().format("yyyy");
        let out1 = Number(pay1);
        arraySpread.push(out1);
      }
      let payload = moment()
        .subtract(i, "y")
        .format("yyyy");
      let output = Number(payload);
      arraySpread.push(output);
    }
    this.listSelectYears = arraySpread;
    this.valorInicialFiltroMeta = moment().format("yyyy");
    this.filterChartFaturamento = moment().format("yyyy");

    serviceTypes.getStatusContribuicoes().then(resp => {
      this.listaDeFiltros.push({ descricao: "Todos", id: null });
      let prelista = resp.data;
      this.listaDeFiltros = this.listaDeFiltros.concat(prelista);
      this.filtroListaSelected = null;
    });
    let yearSetter = moment().format("YYYY");
    this.valorInicialFiltroMeta = yearSetter;
    this.filterChartFaturamento = yearSetter;
  },
  mounted() {
    this.requestListaDeBoletos();
    this.requestResumo();
    this.requestDadosChart01();
    this.requestDadosChart02();
    let yearSetter = moment().format("YYYY");
    this.valorInicialFiltroMeta = Number(yearSetter);
    this.filterChartFaturamento = Number(yearSetter);
  }
};
</script>

<style lang="scss" scoped>
.card02 {
  .headerGrid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto;
    vertical-align: center;
  }
  .titulo {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #37447e;
  }
}
.pagination {
  padding: 10px;
  text-align: center;
  .v-avatar {
    margin: 10px;
    cursor: pointer;
    color: #7275ff;
    font-weight: 900;
    &.ativo {
      background-color: rgba(150, 10, 250, 1);
      color: white;
    }
  }
}
.processo {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
}
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  vertical-align: center;
  align-content: center;
  align-items: center;
  .action {
    padding: 10px 0px;
    margin: 10px;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    .titulo {
      text-align: center;
    }
    .action {
      text-align: center;
    }
  }
}
.card {
  padding: 15px 20px;
}
.saldoTitulo {
  font-weight: 300;
  font-family: "Poppins";
  color: rgba(130, 130, 130, 1);
  font-size: 14px;
}
.saldoValor {
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
}
</style>
