<template>
  <div class="header scrollClass lowShadow">
    <!-- MOBILE -->
    <div
      class="d-grid grid-col-1-auto-1"
      v-if="$vuetify.breakpoint.smAndDown"
      id="teste"
    >
      <div class="d-flex justify-left align-center pl-5">
        <v-avatar size="40" @click="toggleMenu()" id="btnMenuPC">
          <v-icon color="primary">mdi-menu</v-icon>
        </v-avatar>
      </div>
      <div>
        <div style="image-rendering: crisp-edges;" class="text-center">
          <img
            v-if="$vuetify.breakpoint.smAndUp"
            src="@/assets/logo-deitada.png"
            width="180px"
          />
          <img
            v-if="$vuetify.breakpoint.xs"
            src="@/assets/logo.png"
            width="50px"
          />
        </div>
      </div>
      <div></div>
    </div>
    <v-row v-else justify="center" align="center" class="pa-0 ma-0">
      <v-col cols="12" sm="6" class="text-left">
        <div class="d-grid grid-col-auto-1">
          <div>
            <v-avatar size="40" @click="toggleMenu()" id="btnMenuPC">
              <v-icon color="primary">mdi-menu</v-icon>
            </v-avatar>
          </div>
          <div style="image-rendering: crisp-edges;" class="text-left">
            <img src="@/assets/logo-deitada.png" width="180px" id="logomarca" />
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <div class="d-grid grid-col-1-auto-auto">
          <div id="menu-web-criar-turma">
            <div v-if="renderTurmas && renderTurmas.length > 0">
              <v-select
                :items="renderTurmas"
                item-text="nome"
                item-value="id"
                v-model="selecionado"
                label="Selecione a Turma"
                v-on:change="selecionaTurmaAlvo()"
                hide-details
                :disabled="lockSelect"
              >
              </v-select>
            </div>
          </div>

          <div>
            <div class="d-none d-sm-block">
              <div class="d-inline-block mx-0" id="notificacoes" v-if="false">
                <v-menu
                  offset-y
                  :close-on-content-click="false"
                  :nudge-width="200"
                  class="scrollY"
                  v-model="showMenuNotification"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      left
                      dot
                      color="primary"
                      overlap
                      :value="temNotificacao"
                    >
                      <v-icon
                        size="30"
                        @click="loadingNotifications()"
                        v-bind="attrs"
                        v-on="on"
                        color="rgba(50,50,50,0.3)"
                      >
                        mdi-bell-outline
                      </v-icon>
                    </v-badge>
                  </template>
                  <div class="white maxH300 mxW150">
                    <div class="maxH white">
                      <div class="text-body-1 py-2 px-1">Notificações</div>
                      <v-divider></v-divider>
                      <div v-if="loadingNotify" class="pa-2 text-center">
                        <v-progress-circular
                          indeterminate
                        ></v-progress-circular>
                      </div>
                      <div v-else>
                        <div
                          class="text-body-2 pa-2 nth2d"
                          :key="index"
                          v-for="(item, index) in getNotificacoes"
                          :class="{
                            'grey--text text--darken-2':
                              item.visualizado == true
                          }"
                        >
                          <div class="font-weight-light">
                            {{ item.data | dataFormate }}
                          </div>
                          <div class="font-weight-bold">
                            {{ item.title }}
                          </div>
                          <div>
                            {{ item.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-menu>
              </div>
              <div class="d-inline-block mx-3">
                <div class="d-none d-sm-block">
                  <router-link class="link" to="/aluno/dashboard/perfil">
                    <v-btn fab depressed class="lowShadow">
                      <v-avatar size="55">
                        <v-img :src="renderImagePerfil"></v-img>
                      </v-avatar>
                    </v-btn>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";
import Shepherd from "shepherd.js";
import { UserController } from "../../../middleware";

// const tour = new Shepherd.Tour({
//   useModalOverlay: true,
//   defaultStepOptions: {
//     classes: "shadow-md bg-purple-dark"
//   }
// });

const TOGGLE_MENU = "TOGGLE_MENU";

export default {
  data() {
    return {
      showMenuNotification: false,
      lockSelect: false,
      selecionado: "",
      loadingNotify: false,
      listSteps: [],
      exibirTutorial: true,
      tour: null
    };
  },
  mounted() {
    let turmaAtiva = this.$store.getters["aluno/getTurmaAtiva"];
    this.exibirTutorial = this.$store.getters["aluno/getExibirTutorial"];
    this.selecionado = turmaAtiva;
    this.awayLoad();
    let self = this;
    window.onscroll = function() {
      self.onScrollPage();
    };
  },
  watch: {
    getTurmaAtiva(valor) {
      this.selecionado = valor;
    }
  },
  methods: {
    onScrollPage() {
      if (document.querySelector(".scrollClass") !== null) {
        var header = document.querySelector(".scrollClass");
        var sticky = header.offsetTop;
        if (this.$vuetify.breakpoint.xs) {
          if (window.scrollY > sticky) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }
        } else {
          header.classList.remove("sticky");
        }
      } else {
        return false;
      }
    },
    awayLoad() {
      if (this.$store.getters["aluno/getContentLoader"]) {
        setTimeout(() => {
          this.awayLoad();
        }, 500);
      } else {
        this.tutorial();
      }
    },
    criarTituloTour(titulo) {
      return `<div style='font-size:22px;color: rgba(20,30,100,1); padding: 10px 0px; font-weight: bold;'>${titulo}</div>`;
    },
    criarTexto() {
      return ``;
    },
    tutorialWebMenu() {
      this.tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: "shadow-md bg-purple-dark"
        }
      });
      this.$nextTick(() => {});
      this.tour.addStep({
        id: "webdash",
        text:
          this.criarTituloTour("Menu") +
          "No menu estará tudo que você precisa para iniciar seu planejamento, você pode expandir e reduzir o menu conforme a necessidade.",
        attachTo: {
          element: "#btnMenuPC",
          on: "bottom"
        },
        classes: "shepherd-step-highlight",
        buttons: [
          {
            text: "Fechar",
            action: () => {
              return this.tour.cancel();
            }
          },
          {
            text: "Próximo",
            action: () => {
              this.$store.commit("aluno/setMenu", true);
              this.$store.commit("aluno/setMini", false);
              this.tour.next();
            }
          }
        ]
      });
      this.tour.addStep({
        id: "webdash",
        text:
          this.criarTituloTour("Início") +
          "No início você terá dados resumidos em uma visão geral",
        attachTo: {
          element: "#menu-web-inicio",
          on: "right"
        },
        classes: "",
        buttons: [
          {
            text: "Fechar",
            action: () => {
              return this.tour.cancel();
            }
          },
          {
            text: "Próximo",
            action: () => {
              return this.tour.next();
            }
          }
        ]
      });
      this.tour.addStep({
        id: "webdash",
        text:
          this.criarTituloTour("Perfil") +
          "No perfil você pode alterar sua foto e algumas informações.",
        attachTo: {
          element: "#menu-web-perfil",
          on: "right"
        },
        classes: "",
        buttons: [
          {
            text: "Fechar",
            action: () => {
              return this.tour.cancel();
            }
          },
          {
            text: "Próximo",
            action: () => {
              return this.tour.next();
            }
          }
        ]
      });
      this.tour.addStep({
        id: "webdash",
        text:
          this.criarTituloTour("Central de Atendimento") +
          "A central de atendimento está a sua disposição para resolver qualquer problema.",
        attachTo: {
          element: "#menu-web-central-atendimento",
          on: "right"
        },
        classes: "",
        buttons: [
          {
            text: "Fechar",
            action: () => {
              return this.tour.cancel();
            }
          },
          {
            text: "Próximo",
            action: () => {
              return this.tour.next();
            }
          }
        ]
      });
      this.tour.addStep({
        id: "webdash",
        text:
          this.criarTituloTour("Ajuda") +
          "Temos algumas perguntas frequentes que pode te ajudar com alguma dúvida nesse menu",
        attachTo: {
          element: "#menu-web-ajuda",
          on: "right"
        },
        classes: "exemplo",
        buttons: [
          // {
          //   text: "Fechar",
          //   action: () => {
          //     return this.tour.cancel();
          //   }
          // },
          {
            text: "Finalizar",
            action: () => {
              return this.completarTutorial();
            }
          }
        ]
      });
      this.tour.start();
    },
    // tutorialMobileMenu() {
    //   tour.addStep({
    //     id: "mobiledash",
    //     text:
    //       this.criarTituloTour("Início") +
    //       "No início você terá dados resumidos em uma visão geral",
    //     attachTo: {
    //       element: "#menu-mobile-inicio",
    //       on: "bottom"
    //     },
    //     classes: "",
    //     buttons: [
    //       {
    //         text: "Fechar",
    //         action: () => {
    //           return tour.cancel();
    //         }
    //       },
    //       {
    //         text: "Próximo",
    //         action: () => {
    //           return tour.next();
    //         }
    //       }
    //     ]
    //   });
    //   tour.addStep({
    //     id: "mobiledash",
    //     text:
    //       this.criarTituloTour("Perfil") +
    //       "No perfil você pode alterar sua foto e algumas informações.",
    //     attachTo: {
    //       element: "#menu-mobile-perfil",
    //       on: "bottom"
    //     },
    //     classes: "",
    //     buttons: [
    //       {
    //         text: "Fechar",
    //         action: () => {
    //           return tour.cancel();
    //         }
    //       },
    //       {
    //         text: "Próximo",
    //         action: () => {
    //           return tour.next();
    //         }
    //       }
    //     ]
    //   });
    //   tour.addStep({
    //     id: "mobiledash",
    //     text:
    //       this.criarTituloTour("Central de Atendimento") +
    //       "A central de atendimento está a sua disposição para resolver qualquer problema.",
    //     attachTo: {
    //       element: "#menu-mobile-central-atendimento",
    //       on: "bottom"
    //     },
    //     classes: "",
    //     buttons: [
    //       {
    //         text: "Fechar",
    //         action: () => {
    //           return tour.cancel();
    //         }
    //       },
    //       {
    //         text: "Próximo",
    //         action: () => {
    //           return tour.next();
    //         }
    //       }
    //     ]
    //   });
    //   tour.addStep({
    //     id: "mobiledash",
    //     text:
    //       this.criarTituloTour("Ajuda") +
    //       "Temos algumas perguntas frequentes que pode te ajudar com alguma dúvida nesse menu",
    //     attachTo: {
    //       element: "#menu-mobile-ajuda",
    //       on: "bottom"
    //     },
    //     classes: "exemplo",
    //     buttons: [
    //       {
    //         text: "Fechar",
    //         action: () => {
    //           return tour.cancel();
    //         }
    //       },
    //       {
    //         text: "Próximo",
    //         action: () => {
    //           return tour.next();
    //         }
    //       }
    //     ]
    //   });
    //   tour.start();
    // },
    tutorial() {
      if (this.exibirTutorial) {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$store.commit("aluno/setMenu", false);
          setTimeout(() => {
            this.$nextTick(() => {
              this.$store.commit("aluno/setMenu", false);
              // FIX: Bob na versão mobile quando vai pro servidor não funciona
              // this.tutorialMobileMenu();
            });
          }, 1000);
        } else {
          this.$store.commit("aluno/setMenu", true);
          this.$store.commit("aluno/setMini", false);
          setTimeout(() => {
            this.$nextTick(() => {
              this.tutorialWebMenu();
            });
          }, 1000);
        }
      }
    },
    loadingNotifications() {
      this.loadingNotify = true;
      this.$store
        .dispatch("aluno/REQUEST_NOTIFICACOES")
        .then(() => {
          this.$store.commit("aluno/setNotificacaoView");
          this.loadingNotify = false;
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal(err);
          this.loadingNotify = false;
        });
    },
    toggleMenu() {
      bus.$emit(TOGGLE_MENU, "");
    },
    selecionaTurmaAlvo() {
      let turmaID = this.selecionado;
      this.lockSelect = true;
      this.$store.dispatch("aluno/SET_TURMA_ATIVA", turmaID).then(() => {
        this.$store.dispatch("aluno/RELOAD_ALL");
        this.lockSelect = false;
      });
    },
    abrirModal() {
      this.$store.commit("aluno/setModalNovaTurma", true);
    },
    completarTutorial() {
      this.tour.complete();
      UserController.finalizaTutorial()
        .then(() => {
          console.log("Tutorial concluído");
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal(err.data);
        });
    }
  },
  computed: {
    getNotificacoes() {
      return this.$store.getters["aluno/getNotificacoes"];
    },
    getTurmaAtiva() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    renderTurmas() {
      return this.$store.getters["aluno/getTurmas"];
    },
    renderImagePerfil() {
      return (
        this.$store.getters["aluno/getUser"].fotoUrl ||
        require("@/assets/sem-foto-perfil.png")
      );
    },
    temNotificacao() {
      return this.$store.getters["aluno/getUser"].novasNotification;
    },
    userName() {
      return this.$store.getters["aluno/getUser"].nome;
    },
    userSobrenome() {
      return this.$store.getters["aluno/getUser"].sobreNome;
    }
  }
};
</script>

<style lang="scss" scoped>
.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}
.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "bar ui";
  padding: 4px 10px;
  .col1 {
    grid-area: bar;
  }
  .col2 {
    grid-area: ui;
    display: grid;
    grid-template-columns: auto auto auto auto 1fr;
    grid-gap: 10px;
    justify-content: center;
    vertical-align: center;
    align-content: center;
    align-items: center;
    align-self: center;
    .turmas {
      button {
        background-color: #37447e;
        color: white;
        border-radius: 5px;
        padding: 10px;
      }
    }
    .barDesign {
      padding-left: 1px;
      height: 50px;
      border-left: 2px solid rgba(150, 150, 150, 0.3);
    }
    img {
      object-fit: cover;
      //border-radius: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas: "ui";
    .col1 {
      display: none;
    }
  }
}
.header {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 5px 0px 4px 4px rgba(0, 0, 0, 0.2);
  //padding: 4px;
  //padding-top: 6px;
  align-content: center;
  align-items: center;
  .uiUser {
    align-content: center;
    align-items: center;
    align-self: center;
    .fotoPerfil {
      align-content: center;
      align-items: center;
      align-self: center;
      img {
        object-fit: cover;
        //border-radius: 100px;
      }
    }
    .userName {
      padding: 10px;
      color: $colorDarkGrey;
    }
    .notificacao {
      color: #c2cfe0;
    }
  }
}
</style>
