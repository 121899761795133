<template>
  <div>
    <v-menu offset-y v-if="permissaoDeAcessoAoMenu">
      <template v-slot:activator="{ on, attrs }">
        <div style="text-align:right;">
          <v-btn white icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list class="pa-0 ma-0 ">
        <v-list-item class="itemMenu ma-0 pa-0">
          <v-list-item-action class="ma-0 pa-0 ">
            <v-list class="pa-0 ma-0">
              <v-list-item
                @click="cancelarContrato()"
                v-if="getPermissaoUserAuth == 'ADMIN'"
              >
                Cancelar contrato
              </v-list-item>
              <v-list-item
                @click="$emit('abrirContribuicoes')"
                v-if="getPermissaoUserAuth == 'ADMIN'"
              >
                Contribuições
              </v-list-item>
              <v-list-item @click="reenviarEmail()">
                Reenviar convite
              </v-list-item>
              <v-list-item
                @click="setComissao()"
                v-if="
                  getParticipanteRole !== 'COMISSAO' &&
                    getParticipanteRole !== 'ADMIN' &&
                    getParticipanteConvite == 'AUTORIZADO'
                "
                >Adicionar comissário</v-list-item
              >
              <v-list-item
                @click="setAdmin()"
                v-if="
                  getParticipanteRole !== 'COMISSAO' &&
                    getParticipanteRole !== 'ADMIN' &&
                    getParticipanteConvite == 'AUTORIZADO'
                "
                >Adicionar administrador</v-list-item
              >
              <v-list-item
                @click="removeComissao()"
                v-if="getParticipanteRole == 'COMISSAO'"
                >Remover comissário</v-list-item
              >

              <v-list-item
                @click="desativarAluno()"
                v-if="getParticipanteConvite == 'AUTORIZADO'"
                >Desativar usuário</v-list-item
              >

              <v-list-item
                @click="excluirParticipante()"
                v-if="permissaoParaExcluirParticipante"
              >
                Excluir Participante
              </v-list-item>

              <v-list-item
                @click="aceitarAluno()"
                v-if="
                  getParticipanteConvite == 'NAO_AUTORIZADO' ||
                    getParticipanteConvite == 'PENDENTE'
                "
                >Ativar Participante</v-list-item
              >
            </v-list>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import serviceTurma from "@/middleware/turmaController";
export default {
  props: {
    participanteConvite: {
      type: String,
      required: true
    },
    participanteRole: {
      type: String,
      required: true
    },
    souEsseAluno: {
      type: Boolean,
      required: true
    },
    participanteId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    reenviarEmail() {
      serviceTurma
        .reenviarConvite(this.item.email)
        .then(resp => {
          this.$root.infoModal("Convite reenviado com sucesso");
          console.log(resp.data);
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    excluirParticipante() {
      this.$emit("confirmarExclusaoParticipante", this.participanteId);
    },
    atualizaParticipantesDaLista() {
      this.$store.dispatch("aluno/REQUEST_ALUNOS_DA_TURMA").catch(err => {
        this.$root.errorModal(err.data);
      });
    },
    aceitarAluno() {
      serviceTurma
        .aceitaAluno(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Aluno aceito com sucesso.");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    desativarAluno() {
      serviceTurma
        .desativaAluno(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Aluno bloqueado com sucesso.");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    setAdmin() {
      serviceTurma
        .nomearAlunoAdmin(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    removeComissao() {
      serviceTurma
        .removerAlunoComissao(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Alterado com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    setComissao() {
      serviceTurma
        .addAlunoComissao(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err);
        });
    },
    cancelarContrato() {
      serviceTurma
        .cancelarContrato(this.participanteId)
        .then(() => {
          this.$root.infoModal("Contrato cancelado com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    }
  },
  computed: {
    permissaoParaExcluirParticipante() {
      if (this.$store.getters["aluno/userIsAdmin"]) {
        return true;
      } else if (this.getPermissaoUserAuth == "ADMIN") {
        return true;
      } else {
        return false;
      }
    },
    permissaoDeAcessoAoMenu() {
      if (this.$store.getters["aluno/userIsAdmin"]) {
        return true;
      } else if (
        this.getParticipanteRole !== "ADMIN" &&
        this.getPermissaoUserAuth == "ADMIN" &&
        !this.souEsseAluno
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTurmaAtiva() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    getParticipanteRole() {
      return this.participanteRole;
    },
    getParticipanteConvite() {
      return this.participanteConvite;
    },
    getPermissaoUserAuth() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    }
  }
};
</script>

<style></style>
