<template>
  <div>
    <v-timeline dense>
      <v-timeline-item
        :color="getColorType(item.status)"
        small
        dense
        :key="index"
        v-for="(item, index) in lista"
        fill-dot
      >
        <div>
          <div
            :class="`headline mb-2`"
            :style="{ color: getColorType(item.status) }"
          >
            <div class="text-subtitle-1 font-weight-bold">
              {{ getNameType(item.status) }}
            </div>
          </div>
          <div class="pa-1 text-left">
            <div class="text-subtitle-2">
              <div class="py-1 text-body-2 font-weight-bold">
                Data de vencimento
              </div>
              <div class="text-body-2">
                {{ item.dataVencimento | getDataTime }}
              </div>
              <div class="py-1 text-body-2 font-weight-bold">
                Data de Pagamento
              </div>
              <div class="text-body-2">
                {{ item.dataPagamento | getDataTime }}
              </div>
              <div class="py-1 text-body-2 font-weight-bold">
                Descrição
              </div>
              <div class="text-body-2 mb-1">
                {{ item.descricao | existeRegistro }}
              </div>
              <div class="text-body-1 font-weight-bold py-2">
                {{ item.valor | currency }}
              </div>

              <div v-if="item" class="pt-1">
                <div v-if="item.status == 'EXPIRADO'">
                  <v-btn
                    color="secondaryDash"
                    depressed
                    dark
                    small
                    @click="$emit('selectPagamento', item)"
                  >
                    PAGAR
                  </v-btn>
                </div>
                <div v-if="item.status == 'NAO_PAGO'">
                  <div v-if="item.tipoPagamento == 'BOLETO'">
                    <v-btn
                      text
                      small
                      color="primary"
                      @click="$emit('abrirInfoBoleto', item)"
                      title="Abrir boleto"
                    >
                      <v-icon size="30"> mdi-barcode </v-icon>
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      color="secondaryDash"
                      depressed
                      dark
                      small
                      @click="$emit('selectPagamento', item)"
                    >
                      PAGAR
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import {
  colorsForType,
  statusDisplay
} from "@/constants/participante/statusDePagamento";
import moment from "moment";

export default {
  props: {
    lista: {
      required: true,
      type: Array
    }
  },
  methods: {
    getNameType(type) {
      return statusDisplay[type];
    },
    getColorType(type) {
      return colorsForType[type];
    }
  },
  filters: {
    getDataTime(tempo) {
      if (!tempo) return "Sem registro";
      return moment(tempo).format("DD/MM/YYYY");
    },
    existeRegistro(item) {
      if (!item) return "Sem registro";
      else return item;
    }
  }
};
</script>

<style></style>
