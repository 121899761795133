<template>
  <div class="pa-0 pa-sm-6">
    <img src="@/assets/bubbles.png" class="bubbles" />
    <v-card class="cardIndex mx-2 mx-sm-6 mt-6 lowShadow rounded-lg">
      <div class="grey lighten-5 grey--text pa-3">
        Código: EVT-{{ getTurma.id }}
      </div>
      <vConvite />
      <div v-if="getPacote.ativo" class="containPacote">
        <div class="tituloPacote">
          Pacote Ativo
        </div>
        <div class="descPacote">{{ getPacote.titulo }}</div>
      </div>
      <vModuleCasamento v-if="getTipoEvento == getEvento.CASAMENTO" />
      <vModuleFormatura v-if="getTipoEvento == getEvento.FORMATURA" />
      <vModuleCorporativo v-if="getTipoEvento == getEvento.CORPORATIVO" />
      <vModuleAssociacao v-if="getTipoEvento == getEvento.ASSOCIACAO" />
      <vModuleOutro v-if="getTipoEvento == getEvento.OUTRO" />
    </v-card>
    <img src="@/assets/bubbles.png" class="bubbles2" />
  </div>
</template>

<script>
import vModuleCasamento from "./modulos/casamento";
import vModuleFormatura from "./modulos/formatura";
import vModuleCorporativo from "./modulos/corporativo.vue";
import vModuleAssociacao from "./modulos/associacao.vue";
import vModuleOutro from "./modulos/outro.vue";
import vConvite from "./vConvite.vue";
import EVENTO from "@/constants/participante/eventos";

export default {
  components: {
    vModuleCasamento,
    vModuleCorporativo,
    vModuleFormatura,
    vModuleAssociacao,
    vModuleOutro,
    vConvite
  },
  methods: {},
  computed: {
    getTurma() {
      return this.$store.getters["aluno/getTurma"];
    },
    getEvento() {
      return EVENTO;
    },
    getNotPermissaoEdit() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] !== "ADMIN";
    },
    getTipoEvento() {
      return this.$store.getters["aluno/getTurma"].tipoEvento || "S/REGISTRO";
    },
    getPacote() {
      return this.$store.getters["aluno/getPacote"];
    }
  }
};
</script>

<style lang="scss" scoped>
.containPacote {
  padding: 30px 10px;
  background-color: #fbfbff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23eaeaea' fill-opacity='0.62' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}
.tituloPacote {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  color: slate;
}
.descPacote {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: slateblue;
}
.bubbles {
  position: absolute;
  left: 0px;
  margin-top: -10px;
}
.bubbles2 {
  position: absolute;
  margin-left: 30px;
  margin-top: -80px;
  right: 0px;
  z-index: 1;
}
.cardIndex {
  z-index: 2;
}
</style>
