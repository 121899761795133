<template>
  <div class="tagUserPermissao">
    <v-chip small :color="renderColor">
      <!-- style="color: white" -->
      <span class="font-weight-bold white--text">{{ statusTraduzido }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    permissao: {
      default: "",
      type: String,
      required: true
    },
    convite: {
      default: "",
      type: String,
      required: true
    }
  },
  computed: {
    renderColor() {
      if (this.convite == "NAO_AUTORIZADO") return "grey darken-2";
      else return "#7840b0";
    },
    statusTraduzido() {
      let permissao = this.permissao;
      let convite = this.convite;
      if (convite) {
        if (convite == "NAO_AUTORIZADO") return "SEM AUTORIZAÇÃO";
        if (convite == "CONTRATO_CANCELADO") return "Contrato Cancelado";
      }
      if (permissao == "ADMIN") return "Administrador";
      if (permissao == "PARTICIPANTE") return "Participante";
      if (permissao == "COMISSAO") return "Comissão";
      return permissao;
    }
  },
  filters: {
    /*realName(name) {
      if (this.convite) {
        if (this.convite == "NAO_AUTORIZADO") return "SEM AUTORIZAÇÃO";
      }
      if (name == "ADMIN") return "Administrador";
      if (name == "PARTICIPANTE") return "Participante";
      if (name == "COMISSAO") return "Comissão";
      return name;
    }*/
  }
};
</script>
