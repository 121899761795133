<template>
  <v-card class="text-center" :class="[isCustom ? 'elevation-0' : 'pa-4']">
    <div>
      <div class="text-right" v-if="!isCustom">
        <v-btn text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="font-weight-bold text-h6">
        Pagamento via Pix
      </div>
      <div class="pa-0">
        <img :src="getBase64" />
      </div>
      <div class="text-subtitle-2">VALOR DO PIX</div>
      <div class="primary--text text-h6">
        {{ divida.valor | currency }}
      </div>
      <div class="pa-3">
        <div class="text-subtitle-2">DESCRIÇÃO</div>
        <div class="primary--text text-body-1">
          {{ divida.descricao }}
        </div>
      </div>
      <div>
        <div class="text-subtitle-2">Pix Copia e Cola</div>
        <div class="primary--text text-body-2 text-center">
          <v-text-field
            readonly
            :value="divida.qrcodeEmv"
            id="codigopix"
            hide-details
            class="text-body-2 d-hidden mx-5 my-2 mt-n2"
          ></v-text-field>
          <v-btn @click="copiarPIX()" class="mt-3" color="primary" depressed>
            COPIAR
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// props.divida
// condicional para quando tiver lista de bilhetes
import ServiceContribuicao from "@/middleware/contribuicoes";
//import ServiceDeposito from "@/middleware/depositos";
export default {
  props: {
    tipo: {
      type: String,
      required: true
    },
    divida: {
      required: true,
      type: Object
    },
    isCustom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      tempoDeEspera: 8000
    };
  },
  mounted() {
    this.verificarPIXPago();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    verificarPIXPago() {
      if (this.tipo === "DEPOSITO") {
        // TODO: verificar se o deposito foi pago (falta implementar)
      } else {
        ServiceContribuicao.getStatusContribuicao(this.divida.contribuicaoId)
          .then(resp => {
            if (resp.data.status === "PAGO") {
              this.$emit("pagamentoEfetuado", resp.data);
            } else {
              this.timer = setTimeout(() => {
                this.verificarPIXPago();
              }, this.tempoDeEspera);
            }
          })
          .catch(err => {
            console.error(err);
            this.$root.errorModal(err.data);
            this.$emit("close");
          });
      }
    },
    copiarPIX() {
      let copyTextarea = document.querySelector("#codigopix");
      copyTextarea.focus();
      copyTextarea.select();
      try {
        navigator.clipboard
          .writeText(copyTextarea.value)
          .then(() => {
            this.$root.infoModal("Copiado com sucesso!");
          })
          .catch(err => {
            this.$root.errorModal("Não foi possível copiar");
            console.error(err);
          });
      } catch (err) {
        this.$root.errorModal("Não foi possível copiar");
        console.error(err);
      }
    }
  },
  computed: {
    getBase64() {
      return "data:image/png;base64, " + this.divida.qrcodeBase64;
    }
  }
};
</script>
