<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="771px"
    transition="dialog-transition"
  >
    <v-card>
      <div class="text-right pa-2">
        <v-avatar class="cursorPointer" @click="close">
          <v-icon size="30" color="black">mdi-close</v-icon>
        </v-avatar>
      </div>
      <div class="mt-n8">
        <div class="primaryDash--text text-center text-h6 py-1 px-3">
          Parabéns, evento criado com sucesso !
        </div>
      </div>
      <div class="text-center grey--text text-body-2 pt-2 py-6 px-10">
        Você tem 30 dias para testar como funciona o seu evento sem qualquer
        cobrança, também poderá selecionar um plano de acordo com a sua
        necessidade a qualquer momento.
      </div>
      <v-divider></v-divider>
      <div class="pa-4 px-10">
        <div class="primaryDash--text text-h6">
          Você pode convidar os integrantes através desse link:
        </div>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="8" class="text-center">
            <v-text-field id="copiarEsseURL" :value="getURL_APP" readonly />
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <v-btn
              class="deep-purple lighten-5 secondaryDash--text mb-5 mb-sm-auto"
              depressed
              large
              @click="copiarURL()"
              >Copiar link</v-btn
            >
          </v-col>
        </v-row>
        <div class="pb-2">
          <div class="d-inline-block font-weight-bold">Restrito</div>
          Somente as pessoas adicionadas podem abrir com este link.
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { URL_APP } from "@/constants/base";
export default {
  props: {
    code: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      resultado: "",
      dialog: true
    };
  },
  mounted() {
    this.resultado = this.code;
    this.$root.ATUALIZA_DADOS();
  },
  methods: {
    copiarURL() {
      //copiarEsseURL
      let copyTextarea = document.querySelector("#copiarEsseURL");
      copyTextarea.focus();
      copyTextarea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          alert("Copiado com sucesso!");
        } else {
          alert("Não foi possível copiar");
        }
      } catch (err) {
        alert("Não foi possível copiar");
        console.log(err);
      }
    },
    close() {
      this.$emit("close");
    }
  },
  computed: {
    getURL_APP() {
      return URL_APP + "/site/cadastro/" + this.resultado;
    }
  }
};
</script>

<style></style>
