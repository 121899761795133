import axios from "axios";
import store from "@/store/index.js";

export default {
  setPacoteTurma(pacoteID) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(`/v1/turma/define-pacote/${turmaID}/${pacoteID}`);
  },
  getPacotes() {
    return axios.get("/v1/pacote");
  }
};
