<template>
  <div>
    <div
      class="d-block d-sm-none mt-2 pa-4 px-3 white elevation-1 rounded-lg lowShadow"
      :class="{
        autorizado: item.conviteStatus == 'AUTORIZADO',
        naoAutorizado: item.conviteStatus == 'NAO_AUTORIZADO',
        pendente: item.conviteStatus == 'PENDENTE'
      }"
    >
      <div class="text-right relative">
        <vMenuDots
          @abrirContribuicoes="abrirContribuicoes()"
          class="d-inline-block mt-n2"
          :participanteConvite="item.conviteStatus"
          :participanteRole="item.permissaoTurma"
          :souEsseAluno="SouEsseAluno"
          :participanteId="item.usuarioId"
          :item="item"
          @confirmarExclusaoParticipante="openConfirmExclusao()"
        ></vMenuDots>
        <div
          class="text-caption absolute top-0 mt-0 ml-2 grey--text text--darken-1"
        >
          USR-{{ item.usuarioId }}
        </div>
      </div>
      <div class="pa-2 text-center">
        <vAvatar :size="avatarSize" :imagem="item.fotoUrl" />
      </div>
      <div class="text-center ">
        <div class="txtFormando text-caption text-break">
          {{ item.nomeFormando }}
        </div>
        <div class="dadosFinanceiros">
          Dados financeiros
        </div>
        <div class="text-body-2 text-uppercase text-break">
          {{ item.nome }}
        </div>
        <div class="text-body-2 text-break">{{ item.telefone }}</div>
        <div class="text-body-2 text-break">{{ item.email }}</div>
        <div
          class="py-1 grey--text text--darken-1 font-weight-thin text-center"
          v-if="getPermissaoUserAuth"
        >
          <div v-if="PermViewSituacaoCadastro(item.situacaoCadastro)">
            <div>{{ item.situacaoCadastro | filtraSituacaoCadastral }}</div>
          </div>
          <div v-else class="py-3"></div>
        </div>
        <div class="pa-2">
          <vTagAluno :permissao="GET_PERMISSAO_ALUNO" :convite="GET_CONVITE" />
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block">
      <div class="desktopItem lowShadow">
        <div class="user-avatar">
          <vAvatar :size="avatarSize" :imagem="item.fotoUrl" />
        </div>
        <div style="text-align: left; justify-content: left;">
          <div class="dadosFinanceiros">
            Dados financeiros
          </div>
          <div style="font-size: 14px; color: rgb(60, 60, 60)">
            <div>{{ item.nome }} {{ item.sobreNome }}</div>
            <div>
              {{ formatContact(item.telefone) }}
            </div>
            <div>
              {{ item.email }}
            </div>
          </div>
          <div class="text-caption top-0 mt-1 ml-0 grey--text text--darken-1">
            USR-{{ item.usuarioId }}
          </div>
        </div>
        <div class="text-right">
          <vTagAluno :permissao="GET_PERMISSAO_ALUNO" :convite="GET_CONVITE" />
          <div
            class="py-1 grey--text text--darken-1 font-weight-light text-right"
            v-if="getPermissaoUserAuth"
          >
            <div v-if="PermViewSituacaoCadastro(item.situacaoCadastro)">
              <div>{{ item.situacaoCadastro | filtraSituacaoCadastral }}</div>
            </div>
          </div>
        </div>
        <div class="containMenu">
          <vMenuDots
            @abrirContribuicoes="abrirContribuicoes()"
            :participanteConvite="item.conviteStatus"
            :participanteRole="item.permissaoTurma"
            :souEsseAluno="SouEsseAluno"
            :participanteId="item.usuarioId"
            :item="item"
            @confirmarExclusaoParticipante="openConfirmExclusao()"
          ></vMenuDots>
        </div>
      </div>
    </div>
    <vConfirmModal
      @close="closeConfirm()"
      @confirm="onConfirmOption()"
      v-if="modalConfirmExclude"
    />
  </div>
</template>

<script>
import turmaController from "@/middleware/turmaController";
import vTagAluno from "@/components/TagParticipante.vue";
import vAvatar from "@/components/global/avatar";
import vMenuDots from "./menuDots.vue";
import situacaoCadastral from "@/constants/participante/situacaoCadastral";
import { formataContato } from "@/tools";
import vConfirmModal from "@/components/ConfirmModal.vue";

export default {
  components: {
    vTagAluno,
    vAvatar,
    vMenuDots,
    vConfirmModal
  },
  props: ["item", "permissao"],
  data() {
    return {
      avatarSize: 65,
      modalContribuicoes: false,
      modalConfirmExclude: false
    };
  },
  methods: {
    openConfirmExclusao() {
      this.modalConfirmExclude = true;
    },
    onConfirmOption() {
      this.modalConfirmExclude = false;
      this.excluirParticipante();
    },
    closeConfirm() {
      this.modalConfirmExclude = false;
    },
    excluirParticipante() {
      const participanteID = this.item.usuarioId;
      this.$store
        .dispatch("aluno/REMOVE_PARTICIPANTE", participanteID)
        .then(resp => {
          this.$root.sucessoModal(resp.data);
          this.atualizaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    atualizaLista() {
      this.$store.dispatch("aluno/REQUEST_ALUNOS_DA_TURMA").catch(err => {
        this.$root.errorModal(err.data);
      });
    },
    abrirContribuicoes() {
      this.$emit("abrirContribuicao");
    },
    formatContact(val) {
      return formataContato(val);
    },
    PermViewSituacaoCadastro(status) {
      return situacaoCadastral.INCOMPLETO === status;
    },
    updateDados() {
      this.$emit("callUpdate");
    },
    aceitarAluno() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .aceitaAluno(turma, aluno)
        .then(() => {
          this.$root.infoModal("Aluno aceito com sucesso.");
          this.updateDados();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err);
        });
    },
    desativarAluno() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .desativaAluno(turma, aluno)
        .then(() => {
          this.$root.infoModal("Aluno bloqueado com sucesso.");
          this.updateDados();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err);
        });
    },
    setAdmin() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .nomearAlunoAdmin(turma, aluno)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.updateDados();
        })
        .catch(err => {
          this.$root.errorModal(err);
          console.log(err);
        });
    },
    removeComissao() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .removerAlunoComissao(turma, aluno)
        .then(() => {
          this.$root.infoModal("Alterado com sucesso");
          this.updateDados();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err);
        });
    },
    setComissao() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .addAlunoComissao(turma, aluno)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.updateDados();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err);
        });
    }
  },
  filters: {
    filtraSituacaoCadastral(status) {
      switch (status) {
        case situacaoCadastral.INCOMPLETO:
          return "Aguardando cadastro";
        case situacaoCadastral.COMPLETO:
          return "Cadastrado";
        case situacaoCadastral.BLOQUEADO:
          return "Bloqueado";
        default:
          return "indefinido";
      }
    },
    realName(name) {
      if (name == "ADMIN") return "Administrador";
      if (name == "PARTICIPANTE") return "Participante";
      if (name == "COMISSAO") return "Comissão";
      return name;
    }
  },
  computed: {
    getPermissaoUserAuth() {
      if (this.$store.getters["aluno/userIsAdmin"]) {
        return true;
      } else if (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN"
      ) {
        return true;
      } else {
        return false;
      }
    },
    SouEsseAluno() {
      return this.$store.getters["aluno/getUser"].id == this.item.usuarioId;
    },
    GET_CONVITE() {
      return this.item.conviteStatus;
    },
    GET_PERMISSAO_ALUNO() {
      return this.item.permissaoTurma;
    },
    GET_PERMISSAO_USER() {
      return this.permissao;
    }
  }
};
</script>

<style lang="scss" scoped>
.containMenu {
  align-items: start;
  height: 100%;
}
.desktopItem {
  display: grid;
  //flex-direction: row;
  grid-template-columns: auto 1fr auto auto;
  gap: 5px;
  align-items: center;
  //justify-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 8px;
  .user-avatar {
    padding: 20px;
    width: auto;
  }
}
.itemlist {
  background-color: white;
  box-shadow: 0px 2px 2px 1px rgba(50, 50, 50, 0.1);
  border-radius: 6px;
  margin-bottom: 7px;
  margin-top: 7px;
}
.dadosFinanceiros {
  color: #2f3a4a;
  font-family: Roboto !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.txtFormando {
  color: #2f3a4a;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
</style>
