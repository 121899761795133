import axios from "axios";

export default {
  buscar(payload) {
    return axios.post("/v1/curso/search", { texto: payload });
  },
  includeCurso(payload) {
    //id, nome
    return axios.post("/v1/curso", payload);
  }
};
