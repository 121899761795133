<template>
  <div class="text-left">
    <div class="d-block d-sm-inline-block pa-3 pa-sm-5 ">
      <v-row class="justify-center align-center vertical-center">
        <v-col cols="12" sm="auto">
          <div class="text-center text-sm-left">
            <avatar size="60" :imagem="user.fotoUrl" />
          </div>
        </v-col>
        <v-col>
          <div class="pl-4 text-center text-sm-left">
            <div class="text-subtitle-1 font-weight-bold">
              <div class="letter-spacing-1">
                {{ user.nome }} {{ user.sobreNome }}
              </div>
            </div>
            <div class="text-subtitle-2  font-weight-light">
              <div
                class="d-inline-block grey--text text--lighten-1 letter-spacing-1"
              >
                Lista de Contribuições
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import avatar from "@/components/global/avatar";
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  filters: {
    existeValor(val) {
      if (val) return val;
      else return "Sem registro";
    }
  },
  components: {
    avatar
  }
};
</script>

<style lang="scss" scoped></style>
