<template>
  <div class="cardCota px-0 userContain lowShadow">
    <div class="px-1 relative">
      <div class="header">
        <span class="text-caption grey--text text--darken-1">
          USR-{{ item.usuarioId }}
        </span>
        <vMenuDots
          class="d-inline-block"
          :participanteConvite="item.conviteStatus"
          :participanteRole="item.permissaoTurma"
          :souEsseAluno="isCurrentUser"
          :participanteId="item.usuarioId"
          :item="item"
          @abrirContribuicoes="abrirContribuicoes()"
          @confirmarExclusaoParticipante="openConfirmExclusao()"
        />
      </div>
      <vAvatar size="80px" :imagem="item.fotoUrl" />
      <div
        class="beforeFloat"
        :class="{
          autorizado: item.conviteStatus == 'AUTORIZADO',
          naoAutorizado: item.conviteStatus == 'NAO_AUTORIZADO',
          pendente: item.conviteStatus == 'PENDENTE'
        }"
      ></div>
    </div>

    <div class="containDescricao">
      <div class="txtFormando text-caption text-break">
        {{ item.nomeFormando }}
      </div>

      <div class="dadosFinanceiros">
        Dados financeiros
      </div>

      <div class="nomecompleto text-break">
        {{ item.nome }} {{ item.sobreNome }}
      </div>

      <div class="telefone text-break">
        {{ item.telefone ? item.telefone : "Sem telefone" }}
      </div>

      <div class="email text-break">
        {{ item.email }}
      </div>
      <div
        class="text-center"
        v-if="
          getPermissaoUserAuth == 'ADMIN' || $store.getters['aluno/userIsAdmin']
        "
      >
        <div v-if="PermViewSituacaoCadastro(item.situacaoCadastro)">
          <v-chip class="text-caption black--text py-2">
            {{ item.situacaoCadastro | filtraSituacaoCadastral }}
          </v-chip>
        </div>
      </div>
    </div>

    <div class="py-5 text-center">
      <vTagAluno :permissao="GET_PERMISSAO_ALUNO" :convite="GET_CONVITE" />
    </div>
    <vConfirmModal
      @close="closeConfirm()"
      @confirm="onConfirmOption()"
      v-if="modalConfirmExclude"
    />
  </div>
</template>

<script>
import turmaController from "@/middleware/turmaController";
import vTagAluno from "@/components/TagParticipante.vue";
import vAvatar from "@/components/global/avatar";
import situacaoCadastral from "@/constants/participante/situacaoCadastral";
import vMenuDots from "./menuDots.vue";
import vConfirmModal from "@/components/ConfirmModal.vue";

export default {
  components: {
    vTagAluno,
    vAvatar,
    vMenuDots,
    vConfirmModal
  },
  props: ["item"],
  data() {
    return {
      modalConfirmExclude: false
    };
  },
  filters: {
    existeValor(valor) {
      if (valor) return valor;
      else return "S/ REGISTRO";
    },
    filtraSituacaoCadastral(status) {
      switch (status) {
        case situacaoCadastral.INCOMPLETO:
          return "Aguardando cadastro";
        case situacaoCadastral.COMPLETO:
          return "Cadastrado";
        case situacaoCadastral.BLOQUEADO:
          return "Bloqueado";
        default:
          return "indefinido";
      }
    }
  },
  methods: {
    openConfirmExclusao() {
      this.modalConfirmExclude = true;
    },
    onConfirmOption() {
      this.modalConfirmExclude = false;
      this.excluirParticipante();
    },
    closeConfirm() {
      this.modalConfirmExclude = false;
    },
    atualizaLista() {
      this.$store.dispatch("aluno/REQUEST_ALUNOS_DA_TURMA").catch(err => {
        this.$root.errorModal(err.data);
      });
    },
    excluirParticipante() {
      const participanteID = this.item.usuarioId;
      this.$store
        .dispatch("aluno/REMOVE_PARTICIPANTE", participanteID)
        .then(resp => {
          this.$root.sucessoModal(resp.data);
          this.atualizaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    abrirContribuicoes() {
      this.$emit("abrirContribuicao");
    },
    PermViewSituacaoCadastro(status) {
      return situacaoCadastral.INCOMPLETO === status;
    },
    updateDados() {
      this.$emit("callUpdate");
    },
    aceitarAluno() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      0, 3203, 36111;

      turmaController.aceitaAluno(turma, aluno).then(() => {
        this.$root.infoModal("Aluno aceito com sucesso.");
        this.updateDados();
      });
    },
    desativarAluno() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController.desativaAluno(turma, aluno).then(() => {
        this.$root.infoModal("Aluno bloqueado com sucesso.");
        this.updateDados();
      });
    },
    setAdmin() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .nomearAlunoAdmin(turma, aluno)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.updateDados();
        })
        .catch(err => {
          window.console.error(err);
        });
    },
    removeComissao() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController.removerAlunoComissao(turma, aluno).then(() => {
        this.$root.infoModal("Alterado com sucesso");
        this.updateDados();
      });
    },
    setComissao() {
      let turma = this.item.turmaId;
      let aluno = this.item.usuarioId;
      turmaController
        .addAlunoComissao(turma, aluno)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.updateDados();
        })
        .catch(err => {
          window.console.error(err);
        });
    }
  },
  computed: {
    getPermissaoUserAuth() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    },
    viewPortWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      } else {
        return "250";
      }
    },
    isCurrentUser() {
      return this.$store.getters["aluno/getUser"].id == this.item.usuarioId;
    },
    GET_CONVITE() {
      return this.item.conviteStatus;
    },
    GET_PERMISSAO_ALUNO() {
      return this.item.permissaoTurma;
    }
  }
};
</script>

<style lang="scss" scoped>
.colorText {
  color: #778192;
}
.cardCota {
  display: inline-grid;
  margin: 10px;
  background-color: white;
  width: 250px;
  min-height: 260px;
  border-radius: 20px;
  text-align: center;
  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}
.header {
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dadosFinanceiros {
  color: #2f3a4a;
  font-family: Roboto !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.txtFormando {
  padding: 10px;
  color: #2f3a4a;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.containDescricao {
  padding: 10px;
  min-height: 80px;
  .nomecompleto {
    color: #2f3a4a;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .telefone {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    padding-top: 5px;
    color: #778192;
  }
  .email {
    padding-top: 5px;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    color: #778192;
  }
}

.beforeFloat {
  background-color: #d53f8c;
  padding: 14px;
  display: inline-block;
  border-radius: 40px;
  position: absolute;
  margin-top: 50px;
  margin-left: -15px;
  &.autorizado {
    background-color: #9ae6b4;
  }
  &.naoAutorizado {
    background-color: #8b8b8bf3;
  }
}
</style>
