<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.xs"
    persistent
  >
    <div class="white p-5">
      <div class="headerDialog">
        <div class="titulo">
          {{ concurso.nome }}
        </div>
        <div class="descricao" v-html="concurso.descricao"></div>
        <div class=""></div>
      </div>
      <div v-show="initialLoading" style="text-align: center; padding: 20px">
        <v-progress-circular
          indeterminate
          color="primary"
          size="40"
        ></v-progress-circular>
        <div
          style="text-align: center; padding-top: 10px; padding-bottom: 10px"
        >
          Carregando...
        </div>
        <v-btn v-if="step == 1" depressed @click="closeModal()">Sair</v-btn>
      </div>
      <div v-show="!initialLoading">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Bilhetes
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              Dados
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              Pagamento
            </v-stepper-step>
            <v-stepper-step step="4"> Compartilhar </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="formQuantidade" v-model="validaEtapaDeQuantidade">
                <v-card>
                  <div>
                    <table style="width: 100%" class="tableStyle">
                      <tr>
                        <td class="tituloTabela">
                          Valor da contribuição
                        </td>
                        <td class="tituloTabela">
                          N. de cupons
                        </td>
                        <td class="tituloTabela">
                          Valor unitário
                        </td>
                      </tr>
                      <tr v-for="(item, index) in arrayTable" :key="index">
                        <td class="valorTabela">
                          <!-- {{ item.valorContribuicao | currency }} -->
                          <div
                            v-if="item.numCupom === 20"
                            class="containGoodOption"
                          >
                            <div class="goodOption">Melhor opção</div>
                          </div>
                          <div class="containSelectTable">
                            <a
                              class="selectTableItem"
                              @click="selecionaBilhete(item.numCupom)"
                              :class="{ ativo: getRuleNCupons(item) }"
                            >
                              {{ item.valorContribuicao | currency }}
                            </a>
                          </div>
                          <div
                            v-if="item.numCupom === 20"
                            class="ghostspace"
                          ></div>
                        </td>
                        <td class="valorTabela">{{ item.numCupom }}</td>
                        <td class="valorTabela">
                          {{ item.valorUnitario | currency }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="py-2">
                    <div class="previewPriceContain">
                      <div class="previewPrice">
                        {{ computedValuePrice | currency }}
                      </div>
                    </div>
                  </div>
                  <div class="menuBottom centralizaTudo">
                    <div>
                      <v-btn @click="closeModal()">Cancelar</v-btn>
                    </div>
                    <div>
                      <v-btn
                        :disabled="!validaEtapaDeQuantidade"
                        color="secondary"
                        :loading="loading"
                        @click="validaEtapa2()"
                        >Adquirir</v-btn
                      >
                    </div>
                  </div>
                </v-card>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="formDados" v-model="validFormDados">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        name="nome"
                        label="Nome completo"
                        v-model="include.nome"
                        :rules="validaNome"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        name="cpf"
                        label="CPF"
                        v-mask="'###.###.###-##'"
                        v-model="include.cpf"
                        :rules="validCPF"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        name="telefone"
                        label="Contato (celular / telefone)"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        v-model="include.telefone"
                        :rules="validaTelefone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        name="Email"
                        label="Email (Opcional)"
                        v-model="include.email"
                        :rules="validEmail"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="menuBottom centralizaTudo">
                    <div>
                      <v-btn @click="returnStep()">Voltar</v-btn>
                    </div>
                    <div>
                      <v-btn
                        :disabled="!validFormDados"
                        color="secondary"
                        @click="validaEtapaDados()"
                        >Próximo</v-btn
                      >
                    </div>
                  </div>
                </v-card>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <!-- SEQUENCIAR PROCESSO DE COMPRA-->

              <v-card>
                <div v-if="etapaPix === 'LOADING'" class="text-center pa-5">
                  <v-icon size="40" class="animate-spin">mdi-loading</v-icon>
                </div>
                <div v-if="etapaPix === 'QRCODE'">
                  <div class="containPixContent">
                    <div class="qrcode">
                      <img v-if="step == 3" :src="getImagemBase64" />
                      <div>
                        <v-btn small @click="copyQRCODE()" color="primary">
                          PIX Copia e Cola
                        </v-btn>
                      </div>
                    </div>
                    <div class="details">
                      <div class="tituloQRCODE">
                        Pagamento via PIX
                      </div>
                      <div class="valorPix">
                        {{ this.pixResponse.valor | currency }}
                      </div>
                      <div class="statusPagamento">
                        Aguardando Pagamento
                      </div>
                      <div class="timer">
                        <span class="labelText"
                          >Tempo até expirar: {{ getTime }}</span
                        >
                      </div>
                      <!-- <div>
                      <button
                        :disabled="lockFakePagar"
                        @click="pagarFake()"
                        class="pagarPixFake"
                      >
                        PAGAR
                      </button>
                    </div> -->
                    </div>
                  </div>
                </div>

                <div class="menuBottom justify-start">
                  <div>
                    <v-btn @click="closeModal()">Cancelar</v-btn>
                  </div>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card>
                <div class="shared">
                  <div class="design">
                    <img
                      :src="
                        require('../../../../../assets/dash/compartilhar.svg')
                      "
                    />
                  </div>
                  <div class="justify-center sharedContent">
                    <div class="tituloShared">
                      Compra realizada com sucesso!
                    </div>
                    <div class="textoShared">
                      Agora você pode compartilhar diretamente com o comprador
                      dos bilhetes via whatsapp
                    </div>
                    <div>
                      <button
                        class="btnCompartilhar"
                        @click="clickCompartilhar()"
                      >
                        Compartilhar
                      </button>
                    </div>
                  </div>
                </div>
                <div class="containBilhetes">
                  <div class="bilhetesTitulo">Bilhetes do comprador</div>
                  <div class="listaBilhetes">
                    <div
                      v-for="(bilhete, index) in listaBilhetes"
                      :key="index"
                      class="itemBilhete"
                    >
                      {{ bilhete.numero }}
                    </div>
                  </div>
                </div>
                <div class="menuBottom">
                  <div style="text-align:left;">
                    <v-btn @click="closeModal()">Fechar</v-btn>
                  </div>
                  <div></div>
                </div>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ServiceBilhetes from "@/middleware/bilhetes";
//import ServiceFormaturaCustoZero from "@/middleware/FormaturaCustoZero";

export default {
  data() {
    return {
      etapaPix: "LOADING",
      timerWithPix: null,
      countWithPix: 0,
      expired: false,
      purchaseMinutes: null,
      purchaseSeconds: null,
      displayTime: null,
      timePaymentVerify: null,
      listaBilhetes: [],
      step: 1, // 2-quantidade 3-pix 4-compartilhar
      arrayTable: [],
      dialog: true,
      validFormDados: false,
      validaEtapaDeQuantidade: false,
      valorUnitario: null,
      quantidadeBilhetes: 1,
      loading: false,
      initialLoading: true,
      pixResponse: {},
      concurso: {
        descricao: "",
        imagem: "",
        id: null,
        nome: "",
        valorBilhete: null
      },
      validQtdBilhete: [v => (!!v && v > 0) || "A quantidade mínima 1"],
      include: {
        nome: null,
        cpf: "",
        telefone: null,
        email: null
      },
      validaNome: [
        v => !!v || "Nome é necessario",
        v => (v && v.length > 5) || "Digite o nome completo"
      ],
      validaTelefone: [v => !!v || "Telefone é necessario"],
      validCPF: [
        v => !!v || "CPF é necessário",
        v => (v && this.validLength(v, 14)) || "CPF inválido",
        v => this.$root.validateCPF(v) || "CPF inválido"
      ],
      validEmail: [
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email inválido"
      ],
      regressiva: null,
      lockFakePagar: false
    };
  },
  mounted() {
    this.buscarInformacoesDoConcurso();
  },
  beforeDestroy() {
    clearTimeout(this.timePaymentVerify);
    clearTimeout(this.timerWithPix);
  },
  computed: {
    getTime() {
      return this.displayTime;
    },
    computedValuePrice() {
      let num = Number(this.quantidadeBilhetes);
      return this.calcTotalPrice(num);
    },
    getImagemBase64() {
      return "data:image/png;base64, " + this.pixResponse.qrcodeBase64;
    }
  },
  watch: {
    quantidadeBilhetes(newVal) {
      let processingNumber = String(newVal).replace(/\D/g, "");
      if (processingNumber < 0 || null) {
        this.$nextTick(() => {
          this.quantidadeBilhetes = 0;
        });
      }
      if (processingNumber > 9999) {
        this.$nextTick(() => {
          this.quantidadeBilhetes = String(processingNumber).substring(0, 4);
        });
      }
    }
  },
  methods: {
    getRuleNCupons(item) {
      if (item.numCupom == this.quantidadeBilhetes) {
        return true;
      } else {
        return false;
      }
    },
    selecionaBilhete(numero) {
      this.quantidadeBilhetes = numero;
    },
    pagarFake() {
      const vendaID = this.pixResponse.vendaId;
      this.lockFakePagar = true;
      ServiceBilhetes.pagarPix(vendaID)
        .then(() => {
          this.$root.sucessoModal("Pagamento fake - confirmado");
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal("Erro no pagamento Fake");
          this.lockFakePagar = false;
        });
    },
    copyQRCODE() {
      const qrcode = this.pixResponse.qrcodeEmv;
      let self = this;
      navigator.clipboard.writeText(qrcode).then(
        function() {
          self.$root.infoModal(
            "Pix copiado, agora só colar no seu banco de preferência"
          );
        },
        function(err) {
          console.error("Erro ao copiar", err);
          self.$root.errorModal(
            "Ocorreu um erro ao tentar copiar o código pix, o QRCODE continua válido"
          );
        }
      );
    },
    createTime() {
      this.purchaseMinutes = 4;
      this.purchaseSeconds = 0;
      this.clock();
    },
    calcTime() {
      if (this.purchaseMinutes > 0) {
        if (this.purchaseSeconds === 0) {
          this.purchaseSeconds = 59;
          this.purchaseMinutes = this.purchaseMinutes - 1;
        } else {
          this.purchaseSeconds = this.purchaseSeconds - 1;
        }
      } else {
        if (this.purchaseSeconds === 0) {
          this.expired = true;
        } else {
          this.purchaseSeconds = this.purchaseSeconds - 1;
        }
      }
      const treatmentMinutes =
        this.purchaseMinutes > 0 ? "0" + this.purchaseMinutes : "00";
      const treatmentSeconds =
        this.purchaseSeconds < 10
          ? "0" + this.purchaseSeconds
          : this.purchaseSeconds;
      this.displayTime = `${treatmentMinutes}:${treatmentSeconds}`;
    },
    clock() {
      setTimeout(() => {
        if (!this.expired) {
          this.calcTime();
          if (this.step == 3) {
            this.clock();
          }
        }
      }, 1000);
    },
    clickCompartilhar() {
      let listNumbers = [];
      this.listaBilhetes.forEach(el => {
        listNumbers.push(el.numero);
      });
      const bilhetes = listNumbers.toString();
      const telefoneComprador = Number(
        this.include.telefone.replace(/\D/g, "").replace("-", "")
      );
      let texto = `Olá ${this.include.nome}, você está concorrendo a um *${this.concurso.nome}* \n Esses são seus bilhetes: \n *${bilhetes}*`;

      texto = window.encodeURIComponent(texto);

      window.open(
        "https://api.whatsapp.com/send?phone=" +
          "55" +
          telefoneComprador +
          "&text=" +
          texto,
        "_blank"
      );
    },
    calcPriceUnitario(qtd) {
      const valorBilhete = this.concurso.valorBilhete;
      if (qtd == 1) {
        return valorBilhete;
      } else if (qtd >= 2 && qtd <= 4) {
        return valorBilhete - 1;
      } else if (qtd >= 5 && qtd <= 9) {
        return valorBilhete - 3;
      } else if (qtd >= 10 && qtd <= 14) {
        return valorBilhete - 4;
      } else if (qtd >= 15 && qtd <= 19) {
        return valorBilhete - 5;
      } else if (qtd >= 20) {
        return valorBilhete - 6;
      } else {
        console.error("caso 0 error....");
        return 0;
      }
    },
    calcTotalPrice(qtd) {
      let valorUnitario = this.calcPriceUnitario(qtd);
      return valorUnitario * qtd;
    },
    buscarInformacoesDoConcurso() {
      const self = this;
      this.initialLoading = true;
      ServiceBilhetes.getConcurso()
        .then(resp => {
          self.concurso = {
            descricao: resp.data.descricao,
            nome: resp.data.nome,
            imagem: resp.data.imagem,
            id: resp.data.id,
            valorBilhete: resp.data.valorBilhete
          };
          const numCupons = [1, 2, 5, 10, 15, 20, 50, 100];
          let genArray = [];

          numCupons.forEach(num => {
            genArray.push({
              numCupom: num,
              valorUnitario: this.calcPriceUnitario(num),
              valorContribuicao: this.calcTotalPrice(num)
            });
          });
          this.arrayTable = genArray.reverse();
          this.initialLoading = false;
        })
        .catch(err => {
          this.$root.errorModal(
            err.data ||
              "Ocorreu um erro no carregamento do bilhete, tente novamente mais tarde."
          );
          console.error(err);
          this.closeModal();
        });
    },
    validLength(value, wordCount) {
      if (value && wordCount) {
        return value.length === wordCount;
      } else {
        return false;
      }
    },
    getBilhetes() {
      const { vendaId } = this.pixResponse;
      ServiceBilhetes.getBilhetesPorVendaId(vendaId)
        .then(resp => {
          this.listaBilhetes = resp.data;
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal(err.data?.message);
        });
    },
    listenPurchase() {
      const { vendaId } = this.pixResponse;
      this.timePaymentVerify = setTimeout(() => {
        ServiceBilhetes.verificarCompra(vendaId)
          .then(resp => {
            const { status } = resp.data;
            if (status === "PAGO") {
              clearTimeout(this.timePaymentVerify);
              this.nextStep();
              this.getBilhetes();
            } else if (this.expired === true) {
              return;
            } else {
              this.listenPurchase();
            }
          })
          .catch(err => {
            console.error(err);
            this.$root.errorModal(err.data?.message);
            this.closeModal();
          });
      }, 5000);
    },
    withPix(vendaId) {
      this.etapaPix = "LOADING";
      this.countWithPix += 1;
      if (this.countWithPix === 4) {
        this.$root.errorModal(
          "Não foi possivel gerar o Pix, tente novamente mais tarde"
        );
        this.closeModal();
        return false;
      }
      this.timerWithPix = setTimeout(() => {
        ServiceBilhetes.verificaFilaPix(vendaId)
          .then(resp => {
            const resposta = resp.data;
            if (resposta.qrcodeBase64 && resposta.pixId) {
              this.pixResponse = resposta;
              this.etapaPix = "QRCODE";
              this.listenPurchase();
              clearTimeout(this.timerWithPix);
            } else {
              this.withPix(vendaId);
            }
          })
          .catch(err => {
            console.error(err);
            this.$root.errorModal(err?.data?.message);
            this.closeModal();
          });
      }, 5000);
    },
    iniciarCompraPix() {
      const userCPF = this.$store.getters["aluno/getUser"].cpf;
      this.loading = true;
      const payload = {
        concursoId: this.concurso.id,
        cpfComprador: this.include.cpf,
        cpfVendedor: userCPF,
        emailComprador: this.include.email ? this.include.email : null,
        id: null,
        nomeComprador: this.include.nome,
        qtdBilhete: this.quantidadeBilhetes,
        status: null,
        telefoneComprador: this.include.telefone,
        valorTotal: this.computedValuePrice
      };

      ServiceBilhetes.gerarPix(payload)
        .then(resp => {
          const resposta = resp.data;
          this.nextStep();
          this.withPix(resposta.vendaId);
        })
        .catch(e => {
          console.error(e);
          this.loading = false;
          this.$root.errorModal(
            e.data || "Não foi possivel gerar o pix, tente novamente mais tarde"
          );
        });
    },
    validaEtapa2() {
      this.$refs.formQuantidade.validate();
      if (this.validaEtapaDeQuantidade) {
        this.nextStep();
      }
    },
    validaEtapaDados() {
      this.$refs.formDados.validate();
      if (this.validFormDados) {
        const CPFComprador = this.include.cpf;
        if (this.$root.validateCPF(CPFComprador)) {
          this.iniciarCompraPix();
        } else {
          this.$root.errorModal("CPF inválido;");
        }
      }
    },
    nextStep() {
      if (this.step === 4) {
        return;
      }
      this.step = this.step + 1;
    },
    returnStep() {
      this.step = this.step - 1;
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
$sm: 599px;
.containSelectTable {
  display: inline-block;
  //background-color: rgba(50, 250, 150, 0.5);
  @media screen and (max-width: $sm) {
    display: block;
    align-items: center;
    //background-color: rgba(50, 150, 200, 0.5);
  }
}
.selectTableItem {
  width: 120px;
  color: rgb(51, 65, 85);
  font-weight: 300;
  margin: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: rgb(241, 245, 249);
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  &.ativo {
    color: rgb(147 51 234);
    text-decoration-thickness: 0px;
    background-color: rgb(243 232 255);
  }
  @media screen and (max-width: $sm) {
    margin-left: auto;
    margin-right: auto;
  }
}
.ghostspace {
  width: 130px;
  display: inline-block;
  @media screen and (max-width: 700px) {
    display: none;
  }
}
.containGoodOption {
  display: inline-block;
  @media screen and (max-width: $sm) {
    display: block;
  }
}
.goodOption {
  color: #15803d;
  display: inline-block;
  padding: 4px 12px;
  background-color: #dcfce7;
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
  @media screen and (min-width: 640px) {
    margin-left: 12px;
  }
}
.pagarPixFake {
  color: white;
  font-weight: 300;
  padding: 0.5rem 1rem;
  background-color: rgb(4, 120, 87);
  border-radius: 0.375rem;
}
.shared {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  .design {
    text-align: center;
    img {
      width: 200px;
    }
  }
  .sharedContent {
    .tituloShared {
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 5px;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }
    }
    .textoShared {
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .btnCompartilhar {
      background-color: rgb(0, 90, 35);
      color: white;
      font-weight: 300;
      letter-spacing: 1px;
      border-radius: 5px;
      padding: 10px 20px;
      &:hover {
        background-color: rgb(0, 105, 45);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
  }
}
.containBilhetes {
  padding: 4px;
  border-radius: 4px;
  .bilhetesTitulo {
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
}
.tituloTabela {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 4px 0px;
  color: rgb(50, 50, 50);
}
.valorTabela {
  font-weight: 400;
  font-size: 15px;
  padding: 4px 0px;
  letter-spacing: 1px;
  color: rgb(80, 80, 80);
}
.containPixContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  .qrcode {
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  .details {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 22px;
    .tituloQRCODE {
      font-weight: 300;
      font-size: 18px;
      color: black;
    }
    .statusPagamento {
      color: rgba(50, 50, 50, 0.7);
      border-radius: 6px;
      display: inline-block;
      font-size: 13px;
      margin: 10px auto;
      padding: 10px 30px;
      background-color: rgba(230, 230, 230, 1);
    }
    .timer {
      font-size: 13px;
      font-weight: 400;
      .labelText {
        color: rgb(50, 50, 50);
      }
    }
    @media screen and (max-width: 600px) {
      margin-top: -20px;
    }
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
  }
}
.listaBilhetes {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  & > div.itemBilhete {
    padding: 8px;
    margin: 4px;
    background-color: rgba(245, 245, 245, 1);
    font-weight: 400;
    width: 80px;
    text-align: center;
  }
}

.valorPix {
  font-size: 35px;
  // color: $colorLightPurble;
}
.headerDialog {
  padding: 10px;
  font-size: 14px;
  color: rgba(50, 50, 50, 1);
  background-color: rgba(50, 50, 150, 0.05);
  padding: 20px;
  & > .titulo {
    font-weight: 300;
    font-size: 20px;
  }
  & > .descricao {
    font-size: 14px;
    font-weight: 300;
    & > p {
      margin-bottom: 0px !important;
    }
  }
}
.menuBottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}
.previewPriceContain {
  text-align: right;
  @media (max-width: 599px) {
    text-align: center;
    margin-bottom: 10px;
  }
}
.previewPrice {
  text-align: right;
  font-weight: 300;
  display: inline-block;
  align-self: right;
  justify-self: right;
  font-size: 24px;
  padding: 5px 30px;
  background-color: rgba(0, 130, 50, 0.1);
  border-radius: 5px;
  color: rgb(0, 130, 50);
  @media (max-width: 599px) {
    text-align: center;
  }
}
.tableStyle {
  table-layout: auto;
  border: 1px solid green;
  margin-bottom: 5px;
  border-collapse: collapse;
  text-align: center;
  & > tr {
    & > td {
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0px;
    }
  }
}
</style>
