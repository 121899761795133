<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card class="mx-auto pa-5">
          <div
            class="text-h6"
            style="display: grid; grid-template-columns:1fr auto;"
          >
            Novo participante
            <v-btn icon @click="fechar()">
              <v-icon medium> mdi-close </v-icon>
            </v-btn>
          </div>

          <div class="pa-2">
            <div>
              <v-text-field
                name="nome"
                label="Nome completo"
                v-model="include.nome"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                name="email"
                label="Email"
                v-model="include.email"
              ></v-text-field>
            </div>
          </div>
          <div class="pb-5">
            <center>
              <v-btn
                :loading="loading"
                color="primary"
                large
                depressed
                @click="addUser()"
                >Adicionar</v-btn
              >
            </center>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import turmaControlller from "@/middleware/turmaController";
export default {
  props: ["turmaAtiva"],
  data() {
    return {
      dialog: true,
      resp: "",
      loading: false,
      include: {
        email: "",
        nome: "",
        meta: 0
      }
    };
  },
  methods: {
    fechar() {
      this.$emit("handleClose");
    },
    validaEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    addUser() {
      if (!this.validaEmail(this.include.email)) {
        this.$root.errorModal("Email inválido!");
        return;
      }
      this.loading = true;
      const turma = this.turmaAtiva;
      const postDADOS = this.include;
      turmaControlller
        .addAlunoTurma(turma, postDADOS)
        .then(resp => {
          this.$root.sucessoModal(resp.data);
          this.$emit("handleUpdate");
          this.$emit("handleClose");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.titulo {
  font-size: 30px;
  color: #37447e;
  padding: 10px 0px;
}
.fechar {
  display: inline-block;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
</style>
