<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      :max-width="sizeWidth"
      transition="dialog-transition"
    >
      <v-card :class="{ 'pa-4': !isCustomStyleCard }">
        <div class="text-right" v-if="!selecionado">
          <v-btn icon color="primary" @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="pb-5" v-if="etapa == 1">
          <!-- text-center grey--text text--darken-3 font-weight-semibold text-body-1 pb-7 -->
          <div class="tituloMeioPagamento">Selecione o meio de pagamento</div>
          <v-row class="text-center px-3">
            <v-col cols="12" sm="4">
              <v-btn
                @click="selecionaTipo('boleto')"
                color="black"
                text
                large
                block
              >
                <v-icon color="black"> mdi-barcode </v-icon>
                <span class="ml-2"> Boleto </span>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                @click="selecionaTipo('pix')"
                color="black"
                text
                large
                block
                class="d-flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="iconPix"
                >
                  <path
                    d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"
                  />
                </svg>
                <div class="btnPixText">Pix</div>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                @click="selecionaTipo('saldo')"
                color="black"
                text
                large
                block
                :disabled="!hasCreditRange"
              >
                <span>
                  <v-icon color="black">mdi-wallet-bifold-outline</v-icon>
                  Em conta
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="etapa == 2">
          <vPagarComSaldo
            :price="payloadValuePrice"
            :divida="divida"
            @cancel="cancelaOperacaoPagar()"
            @pago="pagamentoConfirmado()"
            v-if="selecionado == 'saldo'"
            :tipo="tipo"
          />
          <vBoletoPagamento
            @close="closeModal()"
            @update="$emit('update')"
            :divida="divida"
            v-if="selecionado == 'boleto'"
            :tipo="tipo"
          />
          <vGerarPix
            @updateStateCustomStyle="setStateIsCustomStyleCard"
            @updateStateSizeModal="setStateSizeModal"
            @update="$emit('update')"
            @close="closeModal()"
            :divida="divida"
            v-if="selecionado == 'pix'"
            :tipo="tipo"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vPagarComSaldo from "./PagarComSaldo.vue";
import vBoletoPagamento from "./gerarBoleto";
import vGerarPix from "./gerarPix";

export default {
  components: {
    vBoletoPagamento,
    vGerarPix,
    vPagarComSaldo
  },
  props: {
    divida: {
      type: Object,
      required: true
    },
    tipo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      payloadValuePrice: this.divida.valor,
      dialog: true,
      selecionado: "",
      etapa: 1,
      sizeWidth: "600px",
      isCustomStyleCard: false
    };
  },
  mounted() {
    this.payloadValuePrice = this.divida.valor;
  },
  computed: {
    hasBalance() {
      return this.$store.getters["aluno/getSaldoBilhetes"];
    },
    hasCreditRange() {
      const balance = this.$store.getters["aluno/getSaldoBilhetes"];
      const price = this.divida.valor;
      if (balance >= price) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    setStateSizeModal(name) {
      if (name === "large") {
        this.sizeWidth = "900px";
      } else {
        this.sizeWidth = "600px";
      }
    },
    /**
     *
     * @param payload {boolean}
     */
    setStateIsCustomStyleCard(payload) {
      this.isCustomStyleCard = payload;
    },
    closeModal() {
      this.$emit("update");
      this.$emit("close");
    },
    pagamentoConfirmado() {
      this.$emit("update");
      this.$emit("close");
    },
    cancelaOperacaoPagar() {
      this.selecionado = null;
      this.etapa = 1;
    },
    selecionaTipo(tipo) {
      this.selecionado = tipo;
      this.etapa = 2;
    }
  }
};
</script>

<style lang="scss" scoped>
.iconPix {
  width: 28px;
}
.btnPixText {
  margin-left: 8px;
}
.tituloMeioPagamento {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  padding-bottom: 30px;
  color: rgba(50, 50, 50, 1);
}
</style>
