export const defined = {
  NULO: null,
  NAO_PAGO: "NAO_PAGO",
  PAGO: "PAGO",
  CANCELADO: "CANCELADO",
  EXPIRADO: "EXPIRADO"
};

export const statusDisplay = {
  PAGO: "Pago",
  NAO_PAGO: "Não pago",
  CANCELADO: "Cancelado",
  EXPIRADO: "EXPIRADO",
  NULO: "Todos"
};

export const SelectArray = [
  { name: statusDisplay.NULO, code: defined.NULO },
  { name: statusDisplay.NAO_PAGO, code: defined.NAO_PAGO },
  { name: statusDisplay.PAGO, code: defined.PAGO },
  { name: statusDisplay.CANCELADO, code: defined.CANCELADO },
  { name: statusDisplay.EXPIRADO, code: defined.EXPIRADO }
];

export const colorsForType = {
  PAGO: "#16a085",
  CANCELADO: "#F03738",
  NAO_PAGO: "#f39c12",
  EXPIRADO: "#fd79a8",
  NULO: "#ffffff"
};

//TODO: precisa testar
export const mixinComputed = {
  methods: {
    getDisplayStatusPayment(code) {
      return statusDisplay[code];
    },
    getColorStatusPayment(code) {
      return colorsForType[code];
    }
  }
};

export default defined;
