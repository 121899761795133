import axios from "axios";

export default {
  SolicitarOrcamento(payload) {
    return axios.post("/v1/landingpage/solicitar-orcamento", {
      email: payload.email,
      nome: payload.nome,
      telefone: payload.telefone
    });
  },
  unsubscribe(email) {
    return axios.post("/v1/landingpage/unsubscribe", {
      email: email
    });
  },
  subscribe(email) {
    return axios.post("/v1/landingpage/subscribe", {
      email: email
    });
  },
  uploadAvatarNoAuth(file) {
    return axios.post(`/v1/usuario/upload-foto-sem-usuario`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  cadastro_simples(_email, _nome, _telefone, _codigo) {
    return axios.post("/v1/landingpage/cad-simples", {
      email: _email,
      nome: _nome,
      telefone: _telefone,
      codigoConvite: _codigo
    });
  },
  cad_plano(payload) {
    return axios.post("/v1/landingpage/cad-plano", payload);
  },
  cad_simples(payload) {
    //simplificado mesmo de cadastro_simples
    return axios.post("/v1/landingpage/cad-simples", {
      email: payload.email,
      nome: payload.nome,
      telefone: payload.telefone,
      codigo: payload.codigo
    });
  },
  calcularValor(payload) {
    return axios.post("/v1/landingpage/calcular", payload);
  }
};
