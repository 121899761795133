<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="700px"
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <div class="d-grid grid-col-1-auto">
        <div class="primaryDash--text font-weight-bold text-h5 pa-2">
          Editando contribuição
        </div>
        <v-btn text @click="$emit('close')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="pa-3">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Data de vencimento"
              v-model="include.dataVencimento"
              v-mask="'##/##/####'"
              placeholder="dd/mm/yyyy"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              prefix="R$"
              id="valorParaPagar"
              label="Valor do Serviço ou Produto"
              v-model="includeValor"
              v-currency="{
                locale: 'pt-Pt',
                currency: null,
                valueAsInteger: false,
                distractionFree: false,
                precision: 2,
                autoDecimalMode: true,
                valueRange: { min: 0 },
                allowNegative: false
              }"
            ></v-text-field>
            <!-- 
            <v-text-field
              v-model.lazy="includeValor"
              v-money="$root.money"
              :value="recebido"
              prefix="R$"
              id="valorParaPagar"
              label="Valor do Serviço ou Produto"
            />-->
          </v-col>
        </v-row>
        <div class="py-0">
          <v-text-field
            v-model="include.descricao"
            label="Descrição"
          ></v-text-field>
        </div>
        <div class="text-right">
          <v-btn color="primary" depressed @click="cadastrarContribuicao()"
            >Salvar</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import serviceContribuicoes from "@/middleware/contribuicoes";

export default {
  props: {
    userUID: {
      type: Number,
      required: true
    },
    contribuicao: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      recebido: 0,
      includeValor: 0,
      dialog: true,
      dataVencimentoPayload: "",
      include: {
        valor: 0,
        dataVencimento: "",
        descricao: ""
      }
    };
  },
  beforeMount() {
    this.recebido = this.include.valor;
    this.includeValor = this.contribuicao.valor;
  },
  mounted() {
    this.include = { ...this.contribuicao };
    this.include.usuarioId = this.userUID;
    this.includeValor = this.contribuicao.valor.toFixed(2);

    if (this.includeValor.indexOf(".") !== -1) {
      this.includeValor = this.includeValor.replaceAll(".", ",");
    }
    this.include.dataVencimento = moment(
      this.contribuicao.dataVencimento
    ).format("DD/MM/YYYY");
  },
  methods: {
    verificaData() {
      let self = this;
      let dataHoje = moment();
      let dataVencimento = moment(self.include.dataVencimento, "DD/MM/YYYY");
      if (self.$root.validaData(self.include.dataVencimento, "DD/MM/YYYY")) {
        if (!dataHoje.isBefore(dataVencimento)) {
          this.$root.errorModal("Você deve colocar uma data futura");
          return false;
        } else {
          this.dataVencimentoPayload = moment(
            self.include.dataVencimento,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          return true;
        }
      } else {
        this.$root.errorModal("Data inválida");
        return false;
      }
    },
    tratamentoDeValor() {
      let ValorInclude = this.includeValor;
      if (ValorInclude.indexOf(".") !== -1) {
        ValorInclude = ValorInclude.replaceAll(".", "");
      }
      if (ValorInclude.indexOf(",") !== -1) {
        ValorInclude = ValorInclude.replaceAll(",", ".");
      }
      ValorInclude = Number(ValorInclude);
      if (ValorInclude < 0 || !ValorInclude) {
        this.$root.errorModal("Valor não pode ser menor ou igual a zero.");
        return false;
      }
      return Number(ValorInclude);
    },
    cadastrarContribuicao() {
      if (!this.verificaData()) return false;
      if (!this.include.descricao || this.include.descricao.length < 3) {
        this.$root.errorModal("Descrição está muito pequena");
        return false;
      }
      if (!this.tratamentoDeValor()) {
        return false;
      }
      this.include.valor = this.tratamentoDeValor();

      var payload = { ...this.include };
      payload.dataVencimento = this.dataVencimentoPayload;

      serviceContribuicoes
        .updateCotaIndividual(payload)
        .then(() => {
          this.$root.infoModal("Cota gerada com sucesso");
          this.$emit("update");
          this.$emit("close");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    }
  }
};
</script>
