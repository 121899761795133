<template>
  <div>
    <div class="primaryDash white--text">
      <v-row justify="center" class="pa-2 ma-0 elevation-1">
        <v-col cols="12" sm="9">
          <div class="text-center text-sm-left">
            <span class="font-weight-bold">{{
              item.status.toUpperCase() | statusNominal
            }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="text-center text-sm-right">
          <v-btn color="white" class="elevation-0" @click="close()">
            <v-icon>mdi-close</v-icon>
            <div class="d-inline-block d-sm-none">
              FECHAR
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="scopeChamado grey lighten-5 pa-3">
      <center>
        <v-icon size="44" color="black" class="mt-n0"
          >mdi-format-quote-close-outline</v-icon
        >
      </center>
      <div
        class="grey--text text--darken-3 text-center font-weight-bold text-subtitle-1 pa-3"
      >
        {{ item.titulo }}
      </div>
      <div
        class="grey--text text--darken-3 px-4 text-center text-subtitle-2 font-weight-light"
      >
        {{ item.descricao }}
      </div>
      <div class="options text-center text-sm-right pa-2">
        <v-btn
          plain
          color="red"
          @click="cancelarAtendimento()"
          v-if="item.status == 'EM_ANDAMENTO' || item.status == 'PENDENTE'"
        >
          CANCELAR ATENDIMENTO
        </v-btn>
      </div>
    </div>

    <div class="bodyChat white">
      <div class="pa-2 py-2 text-center">
        <div class="font-weight-black pa-2">
          Mensagens
        </div>
        <div class="d-inline-block grey lighten-3 rounded designMensagem"></div>
      </div>

      <div class="lock" v-show="!loading">
        <v-card
          class="item pa-2 pt-2 ma-4 white elevation-0"
          :key="index"
          v-for="(msg, index) in mensagens"
        >
          <v-row justify="center">
            <v-col cols="3" sm="2" class="text-center">
              <!-- 
                <v-icon>mdi-account-outline</v-icon>
                <v-icon>mdi-sofa-single-outline</v-icon>
               -->
              <v-avatar
                size="55"
                color="grey lighten-3"
                v-if="msg.origem == 'ATENDENTE'"
              >
                <v-img contain src="@/assets/logo.png"></v-img>
              </v-avatar>
              <v-avatar
                size="55"
                color="grey lighten-3"
                v-if="msg.origem == 'ALUNO'"
              >
                <v-img v-if="getUser.fotoUrl" :src="getUser.fotoUrl"></v-img>
                <v-img
                  v-if="!getUser.fotoUrl"
                  src="@/assets/sem-foto-perfil.png"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="9" sm="10">
              <div class="pb-3">
                <span class="d-inline-block text-left font-weight-bold">
                  {{ msg.nome }}
                </span>
                <span
                  class="d-inline-block font-weight-light grey--text text-body-2 ml-3 text-left"
                  >{{ msg.data | dataFormate }}</span
                >
              </div>
              <div class="text-left font-weight-light text-body-2">
                {{ msg.comentario }}
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div
          class="pa-3 px-5"
          v-if="item.status == 'PENDENTE' || item.status == 'EM_ANDAMENTO'"
        >
          <v-row justify="center">
            <v-col cols="12" sm="9">
              <v-text-field
                label="Mensagem"
                v-model="include.mensagem"
                ref="inputMensagem"
                hide-details
                maxlength="120"
                counter
                outlined
                class="elevation-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="text-center">
              <v-btn
                @click="enviarMensagem()"
                large
                color="primaryDash"
                dark
                depressed
                block
                :loading="loadingBtn"
                >ENVIAR</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="text-center py-10" v-show="loading">
        <v-progress-circular
          :value="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import serviceAtendimento from "@/middleware/central-atendimento";

export default {
  props: ["item"],
  data() {
    return {
      loading: false,
      mensagens: [],
      loadingBtn: false,
      include: {
        mensagem: ""
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    enviarMensagem() {
      this.loadingBtn = true;
      if (this.include.mensagem && this.include.mensagem.length > 1) {
        let mensagem = {
          atendimentoId: this.item.id,
          comentario: this.include.mensagem
        };
        serviceAtendimento
          .novaMensagem(mensagem)
          .then(() => {
            this.buscarMensagens();
          })
          .catch(err => {
            this.$root.errorModal(err);
          })
          .finally(() => {
            this.include.mensagem = "";
            this.loadingBtn = false;
          });
      } else {
        this.loadingBtn = false;
        this.$root.errorModal("Desculpe não pode ser enviado mensagem vazia");
      }
    },
    cancelarAtendimento() {
      let atendimentoID = this.item.id;
      serviceAtendimento.cancelarAtendimento(atendimentoID).then(resp => {
        this.$root.sucessoModal(resp.data);
        this.updateLista();
        this.close();
      });
    },
    updateLista() {
      this.$emit("atualizarLista");
    },
    focusInput() {
      if (
        this.item.status == "PENDENTE" ||
        this.item.status == "EM_ANDAMENTO"
      ) {
        this.$refs.inputMensagem.$refs.input.focus();
      }
    },
    buscarMensagens() {
      this.loading = true;
      let atendimentoID = Number(this.item.id);
      serviceAtendimento
        .atendimentoPorID(atendimentoID)
        .then(resp => {
          this.mensagens = resp.data.mensagens;
          this.loading = false;
          this.$nextTick(() => {
            this.focusInput();
          });
        })
        .catch(err => {
          this.$root.errorModal(err);
          this.loading = false;
        });
    }
  },
  filters: {
    statusNominal(val) {
      if (val == "EM_ANDAMENTO") {
        return "Em andamento";
      } else if (val == "CONCLUIDO") {
        return "Concluído";
      } else if (val == "CANCELADO") {
        return "Cancelado";
      }
    },
    dataFormate(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "undefined";
      }
    }
  },
  computed: {
    getUser() {
      return this.$store.getters["aluno/getUser"];
    }
  },
  beforeMount() {
    this.buscarMensagens();
  }
};
</script>

<style lang="scss" scoped>
.designMensagem {
  width: 90%;
  max-width: 200px;
  padding: 2px;
}
</style>
