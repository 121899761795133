<template>
  <div class="chipDefault" :class="getStatusClass">
    {{ getNameStatus }}
  </div>
</template>

<script>
import {
  codeBilheteStatus,
  nameBilheteStatus
} from "@/constants/participante/statusDePagamentoBilhetes";
export default {
  props: {
    status: {
      type: String
    }
  },
  computed: {
    getNameStatus() {
      if (this.status === codeBilheteStatus.NAO_PAGO) {
        return nameBilheteStatus.NAO_PAGO;
      } else if (this.status === codeBilheteStatus.PAGO) {
        return nameBilheteStatus.PAGO;
      } else if (this.status === codeBilheteStatus.PAGO_GERANDO_BILHETES) {
        return nameBilheteStatus.PAGO_GERANDO_BILHETES;
      } else {
        return this.status;
      }
    },
    getStatusClass() {
      if (this.status === codeBilheteStatus.NAO_PAGO) {
        return "nao-pago";
      } else if (this.status === codeBilheteStatus.PAGO) {
        return "pago";
      } else if (this.status === codeBilheteStatus.PAGO_GERANDO_BILHETES) {
        return "gerando-bilhetes";
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.chipDefault {
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  &.pago {
    background-color: rgba(0, 255, 0, 0.1);
    color: rgb(0, 70, 0, 1);
  }
  &.nao-pago {
    background-color: rgba(255, 0, 0, 0.1);
    color: rgb(70, 0, 0, 1);
  }
  &.gerando-bilhetes {
    background-color: rgba(238, 173, 45, 0.3);
    color: rgb(138, 73, 5, 1);
  }
}
</style>
