<template>
  <div class="pa-5">
    <div>
      <v-text-field
        label="Nome do noivo *"
        name="noivo"
        v-model="include.nomeNoivo"
        disabled
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        label="Nome da noiva *"
        name="noiva"
        v-model="include.nomeNoiva"
        disabled
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        label="Data do casamento *"
        placeholder="DD/MM/AAAA"
        v-mask="'##/##/####'"
        v-model="include.dataFormatura"
        disabled
      />
    </div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          :items="listaEstados"
          label="Estado *"
          v-model="include.estadoId"
          item-text="nome"
          item-value="id"
          @change="onChangeEstado()"
          disabled
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          :items="listaCidades"
          label="Cidade * "
          v-model="include.cidadeId"
          item-text="descricao"
          item-value="id"
          :loading="loadingCidades"
          disabled
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import serviceLocal from "@/middleware/localidadeController";
import serviceTurma from "@/middleware/turmaController";
import moment from "moment";
import EVENTOS from "@/constants/participante/eventos";

export default {
  mounted() {
    this.include = this.$store.getters["aluno/getTurma"];
    this.include.dataFormatura = moment(
      this.include.dataFormatura,
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");
    this.buscaCidade(this.include.estadoId);
    this.prioridadeMounted = false;
  },
  beforeMount() {
    this.buscaEstado();
  },
  data() {
    return {
      loadingStep: false,
      loadingCidades: false,
      loading: false,
      listaEstados: [],
      listaCidades: [],
      formatDate: "DD/MM/YYYY",
      prioridadeMounted: true,
      include: {
        nomeNoivo: "",
        nomeNoiva: "",
        cidadeId: "",
        estadoId: "",
        dataFormatura: "",
        tipoEvento: EVENTOS.CASAMENTO
      }
    };
  },
  computed: {
    getUserRoleEdit() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] !== "ADMIN";
    }
  },
  methods: {
    verificadoresDeDados() {
      if (!this.include.nomeNoivo || this.include.nomeNoivo.length < 3) {
        this.$root.errorModal(
          "Nome do noivo não está muito pequeno, coloque o nome completo"
        );
        return false;
      }
      if (!this.include.nomeNoiva || this.include.nomeNoiva.length < 3) {
        this.$root.errorModal(
          "Nome do noiva não está muito pequeno, coloque o nome completo"
        );
        return false;
      }
      if (!this.include.estadoId || typeof this.include.estadoId !== "number") {
        this.$root.errorModal("É necessario selecionar o estado atual");
        return false;
      }
      if (!this.include.cidadeId || typeof this.include.cidadeId !== "number") {
        this.$root.errorModal("É necessario selecionar a cidade atual");
        return false;
      }
      return true;
    },
    validateData() {
      var dataValue = this.include.dataFormatura;
      if (!dataValue || dataValue.length != 10) {
        this.$root.errorModal(
          "O formato da data está inválido. modelo DD/MM/AAAA"
        );
        return false;
      }
      let dt = moment(dataValue, this.formatDate);
      if (!dt.isValid()) {
        this.$root.errorModal("A data do casamento está inválida");
        return false;
      }
      if (!moment().isBefore(dt)) {
        this.$root.errorModal("A data deve ser maior que o dia atual");
        return false;
      }
      return true;
    },
    tratamentoDeDados() {
      let dataPayload = moment(this.include.dataFormatura, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      return {
        id: this.include.id,
        nomeNoivo: this.include.nomeNoivo,
        nomeNoiva: this.include.nomeNoiva,
        cidadeId: this.include.cidadeId,
        estadoId: this.include.estadoId,
        nome: this.gerarTagName(),
        dataFormatura: dataPayload,
        tipoEvento: this.include.tipoEvento
      };
    },
    gerarTagName() {
      let completo = this.include.nomeNoivo + " " + this.include.nomeNoiva;
      let noivoNumber = this.include.nomeNoivo.length * 13;
      let noivaNumber = this.include.nomeNoiva.length * 17;
      let padraoNumber = 1000;
      let tagNumber = noivoNumber + noivaNumber + padraoNumber;
      let arr = completo.split(" ");
      let coderesult = "";

      if (arr.length >= 3) {
        arr.forEach(el => {
          coderesult += el[0].toUpperCase();
        });
      } else {
        arr.forEach(el => {
          coderesult += el[0].toUpperCase() + el[1].toUpperCase();
        });
      }
      return coderesult + "-" + tagNumber;
    },
    cadastrarEvento() {
      if (!this.verificadoresDeDados()) return false;
      if (!this.validateData()) return false;
      let POST_DATA = this.tratamentoDeDados();
      this.loading = true;
      serviceTurma
        .alterarEvento(POST_DATA)
        .then(() => {
          this.loading = false;
          this.$root.infoModal("Dados alterados com sucesso!");
          this.$store.dispatch("aluno/REQUEST_TURMA");
          this.$store.dispatch("aluno/REQUEST_USER");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    buscaEstado() {
      serviceLocal.getEstado().then(resp => {
        this.listaEstados = resp.data;
      });
      if (!this.prioridadeMounted) {
        this.include.estadoId = null;
      }
    },
    buscaCidade(estadoId) {
      serviceLocal
        .getCidadeForEstado(estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loadingCidades = false;
        });
    },
    onChangeEstado() {
      this.loadingCidades = true;
      this.buscaCidade(this.include.estadoId);
      if (!this.prioridadeMounted) {
        this.include.cidadeId = null;
      }
    }
  }
};
</script>
