<template>
  <v-dialog
    v-model="dialog"
    min-width="300"
    width="400"
    persistent
    style="z-index: 999999;"
  >
    <v-card flat class="bg-white lowShadow">
      <div class="contentCard">
        <div class="titulo">{{ GET_MESSAGE }}</div>
        <div class="options">
          <div style="flex: 1">
            <v-btn text @click="cancel()">Cancelar</v-btn>
          </div>
          <div style="flex: 1">
            <v-btn text color="red" @click="confirm()">Excluir</v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["mensagem"],
  data() {
    return {
      dialog: true
    };
  },
  computed: {
    GET_MESSAGE() {
      return this.mensagem
        ? this.mensagem
        : "Tem certeza que deseja efetuar essa ação ?";
    }
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style lang="scss" scoped>
.contentCard {
  padding: 12px;
}
.titulo {
  padding-top: 8px;
  padding-bottom: 16px;
  text-align: center;
  font-size: 15px;
}
.options {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  text-align: center;
}
</style>
