const codeID = {
  FORMATURA: "FORMATURA",
  ASSOCIACAO: "ASSOCIACAO",
  CASAMENTO: "CASAMENTO",
  CORPORATIVO: "CORPORATIVO",
  OUTRO: "OUTRO"
};

export const getNames = {
  FORMATURA: "Formatura",
  ASSOCIACAO: "Associação",
  CASAMENTO: "Casamento",
  CORPORATIVO: "Corporativo",
  OUTRO: "Outro"
};

export const getArray = [
  { name: getNames.ASSOCIACAO, code: codeID.ASSOCIACAO },
  { name: getNames.CASAMENTO, code: codeID.CASAMENTO },
  { name: getNames.CORPORATIVO, code: codeID.CORPORATIVO },
  { name: getNames.FORMATURA, code: codeID.FORMATURA },
  { name: getNames.OUTRO, code: codeID.OUTRO }
];

export default codeID;
