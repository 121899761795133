import axios from "axios";

export default {
  buscar(payload) {
    return axios.post("/v1/instituicao/search", { texto: payload });
  },
  includeInst(payload) {
    //id, nome, sigla
    return axios.post("/v1/instituicao", payload);
  }
};
