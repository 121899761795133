<template>
  <div>
    <div class="text-center text-sm-left primaryDash--text ">
      <vTitulo class="d-inline-block">
        Fornecedores
      </vTitulo>
    </div>
    <div
      class="text-subtitle-1 pa-2 mx-0 mx-sm-5 my-3 text-sm-left text-center "
    >
      <v-btn
        @click="pagina = 'novo-fornecedor'"
        :block="$vuetify.breakpoint.xs"
        class="white--text elevation-0 ma-1 d-block d-sm-inline-block"
        :class="{ 'primaryDash--text': pagina !== 'novo-fornecedor' }"
        :color="pagina == 'novo-fornecedor' ? 'primaryDash' : 'grey lighten-3'"
        v-if="['COMISSAO', 'ADMIN'].includes(getPermissaoTurma) || userIsAdmin"
        >NOVO FORNECEDOR</v-btn
      >

      <v-btn
        @click="pagina = 'fornecedores'"
        :block="$vuetify.breakpoint.xs"
        class="white--text elevation-0 ma-1 d-block d-sm-inline-block"
        :class="{ 'primaryDash--text': pagina !== 'fornecedores' }"
        :color="pagina == 'fornecedores' ? 'primaryDash' : 'grey lighten-3'"
        >LISTA FORNECEDORES</v-btn
      >
    </div>

    <div
      v-if="pagina == 'fornecedores'"
      class="px-3 d-block d-sm-flex flex-wrap justify-left align-content-start"
    >
      <v-card
        :width="$vuetify.breakpoint.xs == true ? 'auto' : '350'"
        class="ma-1 lowShadow rounded-lg cardCustom"
        :key="index"
        v-for="(item, index) in getFornecedores"
      >
        <div class="relative">
          <v-avatar
            size="35"
            color="primary"
            class=" elevation-3 top-0 right-0 mt-n5 absolute click"
            @click="abrirEditor(item)"
            v-if="['COMISSAO', 'ADMIN'].includes(getPermissaoTurma)"
          >
            <v-icon size="25" color="white">
              mdi-pencil-outline
            </v-icon>
          </v-avatar>
        </div>
        <div class="tituloCard" :title="item.razaoSocial">
          {{ item.nome }}
        </div>
        <div class="containInfo">
          <div class="py-1">
            <div class="font-weight-light text-overline">Telefone</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ item.telefone ? item.telefone : "S/ REGISTRO" }}
            </div>
          </div>

          <div class="py-1">
            <div class="font-weight-light text-overline">Email</div>
            <div
              class="grey--text font-weight-normal text--darken-3 text-body-2"
            >
              {{ item.email ? item.email : "S/ EMAIL" }}
            </div>
          </div>
          <div class="py-1">
            <div class="font-weight-light text-overline">Titular da Conta</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ item.titularConta }}
            </div>
          </div>
          <div class="py-1">
            <div class="font-weight-light text-overline">Descrição</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ item.descricao }}
            </div>
          </div>
          <div class="text-center py-1">
            <v-btn
              @click="abrirDetalhado(item)"
              depressed
              color="primaryDash"
              dark
              class="font-weight-light text-overline pa-4"
            >
              ver detalhes
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <vNovoFornecedor
      @atualizar="atualizaLista"
      @chama_rota="trocaRota"
      v-if="pagina == 'novo-fornecedor'"
    />
    <editorFornecedor
      :fornecedor="itemPayload"
      @atualizar="atualizaLista"
      @chama_rota="trocaRota"
      v-if="pagina == 'editar-fornecedor'"
    ></editorFornecedor>
    <vDetalhes
      @close="closeDetalhado"
      v-if="modalFornecedor"
      :fornecedor="itemPayload"
    />
  </div>
</template>

<script>
import vNovoFornecedor from "./novoFornecedor";
import serviceFornecedores from "@/middleware/fornecedores.js";
import editorFornecedor from "./editarFornecedor";
import vDetalhes from "./detalhesFornecedor.vue";

export default {
  components: {
    vNovoFornecedor,
    editorFornecedor,
    vDetalhes
  },
  data() {
    return {
      loadingDelete: false,
      itemPayload: {},
      pagina: "fornecedores",
      modalFornecedor: false,
      listaDeFornecedores: []
    };
  },
  /*beforeMount() {
    if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] == "PARTICIPANTE") {
      this.$router.replace("/aluno/dashboard/perfil");
    }
  },*/
  mounted() {
    this.atualizaLista();
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters["aluno/userIsAdmin"];
    },
    getPermissaoTurma() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    },
    getFornecedores() {
      return this.$store.getters["aluno/getFornecedores"];
    }
  },
  methods: {
    closeDetalhado() {
      this.modalFornecedor = false;
      this.itemPayload = {};
    },
    abrirDetalhado(item) {
      this.itemPayload = item;
      this.modalFornecedor = true;
    },
    abrirEditor(item) {
      this.itemPayload = item;
      this.pagina = "editar-fornecedor";
    },
    removerFornecedor(id) {
      this.loadingDelete = true;
      serviceFornecedores
        .removeFornecedor(id)
        .then(resp => {
          this.loadingDelete = false;
          this.atualizaLista();
          this.$root.errorModal(resp.data);
        })
        .catch(err => {
          this.loadingDelete = false;
          console.error(err);
        });
    },
    trocaRota(rota) {
      this.pagina = rota;
    },
    atualizaLista() {
      this.$store.dispatch("aluno/REQUEST_FORNECEDORES").catch(() => {
        this.$root.errorModal(
          "Erro ao atualizar os fornecedores, atualize a pagina."
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cardCustom {
  border: 1px solid rgba(200, 200, 200, 0.6);
}
.tituloCard {
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  color: rgb(45, 45, 45);
  text-transform: uppercase;
  padding: 16px;
  padding-bottom: 0px;
}
.containInfo {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
}
</style>
