<template>
  <div class="containErrorList" v-if="listErrors.length > 0">
    <div class="tituloError">
      Erro<span v-if="listErrors.length > 1">s</span> encontrado<span
        v-if="listErrors.length > 1"
        >s</span
      >
    </div>
    <div>
      <div class="itemList" v-for="(erro, index) in listErrors" :key="index">
        <span class="mr-2">
          <v-icon size="16" color="red">mdi-alert-circle</v-icon>
        </span>
        <span>
          {{ erro }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vListErrosForm",
  props: {
    listErrors: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
.containErrorList {
  background-color: rgba(244, 67, 54, 0.1);
  padding: 6px 10px;
  border-radius: 5px;
}
.tituloError {
  color: rgb(70, 70, 70);
  font-size: 13.5px;
  font-family: "Poppins";
  font-weight: 500;
  padding: 2px;
}
.itemList {
  color: rgb(244, 67, 54);
  font-size: 13.5px;
  font-family: "Poppins";
  font-weight: 400;
  justify-items: center;
  align-items: flex-start;
  display: flex;
  margin: 5px 0px;
}
</style>
