<template>
  <div>
    <div class="text-h6 font-weight-medium py-4 primaryDash--text text-center">
      Selecione seu plano
    </div>
    <div v-if="pacotes.length > 0">
      <div class="d-flex flex-wrap justify-center align-top pa-2">
        <!-- d-inline-block -->
        <v-card
          class="
            d-flex
            flex-column
            flex-wrap
            itemCardPacote
            ma-2
            primaryDash--text
            text-center
          "
          :class="{ selecionado: pac.id == pacoteSelecionado }"
          v-for="(pac, index) in pacotes"
          :key="index"
        >
          <div
            class="itemHeader"
            :style="{
              backgroundColor: pac.color
            }"
          >
            {{ pac.titulo }}
          </div>
          <div class="itemBody">
            <div class="itemDescricao">
              {{ pac.descricao }}
            </div>
            <div
              class="itemCarencia"
              v-if="pac.diasCarencia && pac.diasCarencia > 0"
            >
              <div class="itemCarenciaLabel">
                Assine agora e ganhe os primeiros {{ pac.diasCarencia }} dias
                grátis!
              </div>
            </div>
            <div class="itemContentTipoPag ">
              <div class="itemValor">
                {{ pac.valor | currency }}
              </div>
              <div
                class="itemTipoPagamento"
                v-if="pac.tipoCobranca === 'MENSAL'"
              >
                por aluno mês
              </div>
              <div
                class="itemTipoPagamento"
                v-if="pac.tipoCobranca === 'POR_TRANSACAO'"
              >
                por cada pagamento
              </div>
            </div>
          </div>
          <div class="btnSelectArea">
            <v-btn
              @click="selecionaPacote(pac.id)"
              :color="pac.color"
              class="white--text"
              depressed
              >Selecionar</v-btn
            >
          </div>
        </v-card>
      </div>
      <!-- <div id="acessoria">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              Saiba mais sobre a Assessoria técnica on-line
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>
                  Assessoria na contratação e elaboração de contratos com
                  fornecedores;
                </li>
                <li>
                  Assessoria de um profissional para gerenciamento da turma na
                  plataforma;
                </li>
                <li>WhatsApp direto com o profissional que atende a turma;</li>
                <li>
                  Reunião on-line de orientações alinhamento com a comissão de
                  formatura;
                </li>
                <li>
                  Realização do cronograma para a turma (com auxílio da comissão
                  de formatura), com datas dos eventos bem como para as
                  contratações dos serviços;
                </li>
                <li>
                  Análise mensal e parecer sobre o andamento das arrecadações da
                  turma;
                </li>
                <li>
                  Reunião bimestral on-line com a comissão para alinhamento e
                  definição de prioridades;
                </li>
                <li>
                  Aconselhamento sobre os serviços e fornecedores a escolher e
                  contratar;
                </li>
                <li>Acompanhamento de prazos;</li>
                <li>
                  Assessoria para buscar alternativas extras de arrecadar
                  dinheiro para a formatura
                </li>
                <li>
                  Faz lançamento e pagamento de fornecedores (à pedido da
                  comissão de formatura, que deve passar as informações)
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div> -->
      <div id="cadastrar" class="text-center mt-5">
        <v-btn
          color="primaryDash"
          :class="{ 'white--text': pacoteSelecionado }"
          :disabled="!pacoteSelecionado"
          @click="assinarPacote()"
          >Assinar</v-btn
        >
      </div>
    </div>
    <div v-else class="text-center py-4 px-2 grey--text text--darken-2">
      <div class="text-center pa-2">Carregando pacotes...</div>
      <div>
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import servicePacotes from "@/middleware/pacotes";
//import serviceTypes from "@/middleware/tiposController";

export default {
  props: {
    isModalMode: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      pacotes: [],
      pacotesItensServer: [],
      pacoteSelecionado: null
    };
  },
  mounted() {
    this.getPacotes();
    //this.getTypePacotes();
  },
  methods: {
    selecionaPacote(id) {
      this.pacoteSelecionado = id;
    },
    assinarPacote() {
      let id = this.pacoteSelecionado;
      servicePacotes
        .setPacoteTurma(id)
        .then(resp => {
          this.$root.infoModal(resp.data);
          if (this.isModalMode) {
            this.$emit("close");
          }
          this.$store.dispatch("aluno/RELOAD_ALL");
        })
        .catch(err => {
          console.log(err.data);
          this.$root.errorModal(err.data);
        });
    },
    getPacotes() {
      servicePacotes.getPacotes().then(resp => {
        let pacs = resp.data;
        this.pacotes = pacs;
      });
    }
    /*getTypePacotes() {
      serviceTypes.getItensPacote().then(resp => {
        this.pacotesItensServer = resp.data;
      });
    }*/
  }
};
</script>

<style lang="scss" scoped>
.itemCarenciaLabel {
  font-size: 13px;
  color: black;
  font-weight: 600;
  padding: 0px 15px;
}
.itemCarencia {
  padding: 20px 0px;
}
.itemBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.btnSelectArea {
  //pa-2 text-center flex-0
  padding: 20px 0px;
  text-align: center;
  flex: 0;
}
.itemHeader {
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  flex: 0;
  color: white;
  font-weight: 300;
  padding: 10px 5px;
}
.itemValor {
  font-size: 18px;
  font-weight: 500;
}
.itemTipoPagamento {
  color: rgba(130, 130, 150, 1);
}
.itemDescricao {
  flex: 1;
  padding: 8px;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 4px;
  font-size: 14px;
  text-align: center;
  color: rgba(70, 70, 100, 1);
  font-weight: 300;
}
.itemContentTipoPag {
  color: rgb(30, 30, 30);
  flex: 0;
  font-size: 12px;
}
.itemCardPacote {
  min-width: 250px;
  max-width: 250px;
  min-height: 150px;
  border-radius: 6px;
  //border: 1px solid rgba(200, 200, 200, 1);
  transition: 0.1s all;
  box-sizing: content-box;
  background-color: white;
  box-shadow: 0px 1px 4px #64748b !important;
  //box-shadow: none !important;
  &.selecionado {
    outline: 4px solid rgba(0, 80, 177, 0.5) !important;
  }
}
</style>
