<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <div class="pa-5">
          <h2 class="pa-3">Confirmação de finalização do plano</h2>
          <div class="descricao pa-3">
            Após finalizar o plano, não poderá mais ser alterado.<br /><br />
            A cota a ser distribuida para cada aluno é
            <strong>{{ dados.cota | currency }}</strong>
          </div>
          <div class="actions pa-3">
            <v-row>
              <v-col class="text-center">
                <v-btn color="primary" outlined @click="fecharTurma()">
                  não confirmar
                </v-btn>
              </v-col>
              <v-col class="text-center">
                <v-btn color="primary" @click="finalizaTurma()">
                  confirmar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import serviceTurma from "@/middleware/turmaController";
export default {
  props: ["dados"],
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    fecharTurma() {
      this.$emit("cancelar");
    },
    finalizaTurma() {
      this.$emit("confirmar");
    }
  }
};
</script>

<style></style>
