<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pa-5">
      <div>
        <div class="containBtnClose">
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="tituloDoCard">
          Solicitação de Pagamento
        </div>
        <div class="text-center ">
          <div class="containBtns">
            <div class="customBtn" @click="selecionaTipo('fornecedor')">
              <div class="containIcon">
                <v-icon size="35" color="#171717" style="margin: 0 auto;"
                  >mdi-semantic-web</v-icon
                >
              </div>
              <div class="containText">Fornecedor</div>
            </div>
            <div class="customBtn" @click="selecionaTipo('evento')">
              <div class="containIcon">
                <v-icon size="35" color="#171717" style="margin: 0 auto;"
                  >mdi-swap-horizontal-bold</v-icon
                >
              </div>
              <div class="containText">
                Transferência entre turmas/evento
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    selecionaTipo(tipo) {
      this.$emit("selecionaTipo", tipo);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.containBtnClose {
  text-align: right;
  margin-bottom: -15px;
}
.containBtns {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
  .customBtn {
    height: 100%;
    width: 200px;
    padding: 10px;
    background-color: white;
    border: 2px solid #7275ff;
    display: inline-grid;
    grid-template-rows: 50px 1fr;
    border-radius: 8px;
    cursor: pointer;
    .containIcon {
      display: inline-flex;
      flex: 1;
      justify-items: center;
      align-items: center;
      text-align: center;
      color: #7275ff;
    }
    .containText {
      text-align: center;
      font-size: 15px;
      align-items: center;
      display: grid;
      color: #7275ff;
      font-weight: 500;
    }
    &:hover {
      background-color: #7274ff34;
    }
  }
  @media screen and (max-width: 599px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    .customBtn {
      width: 100%;
    }
  }
}

.tituloDoCard {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  padding-bottom: 20px;
  padding-top: 3px;
  text-rendering: geometricPrecision;
  font-family: "Poppins";
}
</style>
