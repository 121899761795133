<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <vInfoPixContent :tipo="tipo" :divida="divida" @close="$emit('close')" />
  </v-dialog>
</template>

<script>
import infoPixContent from "./infoPixContent.vue";
export default {
  components: {
    vInfoPixContent: infoPixContent
  },
  props: {
    tipo: {
      type: String,
      required: true
    },
    divida: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      dialog: true
    };
  }
};
</script>

<style></style>
