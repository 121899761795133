<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <div>
          <div id="headerUI">
            <vModalHeader
              titulo="Solicitação de pagamento para fornecedor"
              closeBtn
              @close="close()"
            />
          </div>
          <v-card
            v-if="getFornecedores.length == 0"
            outlined
            class="pa-3 red lighten-5"
          >
            <div class="red--text text--darken-1  text-body-2">
              Você não tem fornecedor registrado, registre o seu fornecedor na
              aba fornecedores para concluir essa etapa.
              <div class="text-center pa-2">
                <v-btn
                  color="primaryDash white--text"
                  @click="$router.push('/aluno/dashboard/fornecedores')"
                  >Registrar fornecedor</v-btn
                >
              </div>
            </div>
          </v-card>

          <div class="form" v-else>
            <v-select
              :items="getFornecedores"
              v-model="include.fornecedorId"
              item-text="nome"
              item-value="id"
              label="Fornecedor a pagar"
            />

            <div>
              <v-textarea
                outlined
                label="Descreva o motivo de pagamento"
                v-model="include.descricao"
                rows="3"
              />
            </div>
            <div>
              <v-text-field
                type="date"
                label="Data para o Pagamento"
                v-model="include.dataPagamento"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model.lazy="includeValor"
                v-money="$root.money"
                prefix="R$"
                id="valorParaPagar"
                label="Valor do Serviço ou Produto"
              />
            </div>
            <vListErrosForm :listErrors="handlerErros" />
            <div class="text-left text-body-2 py-2 grey--text text--darken-1">
              O pagamento só será concluído se na data do pagamento tiver saldo
              em conta.
            </div>
            <div class="text-center">
              <v-btn
                @click="enviarSolicitacao()"
                color="primaryDash"
                class="white--text"
                :loading="loading"
              >
                solicitar
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceFornecedores from "@/middleware/fornecedores";
import serviceSolicitacoes from "@/middleware/solicitacoesDePagamento";
import moment from "moment";
import tiposDeSolicitacao from "@/constants/tiposDeSolicitacao";
import vListErrosForm from "@/components/ListErrosForm.vue";

export default {
  components: {
    vListErrosForm
  },
  data() {
    return {
      selectedFornecedor: "",
      loading: false,
      includeValor: 0,
      include: {
        tipo: tiposDeSolicitacao.FORNECEDOR,
        dataPagamento: null,
        descricao: null,
        fornecedorId: null,
        id: null,
        turmaId: null,
        turmaIdDestino: null,
        valor: 0
      },
      listaServicos: [],
      listaDeFornecedores: [],
      dialog: true,
      handlerErros: []
    };
  },
  filters: {
    temRegistro(val) {
      if (val) {
        return val;
      } else {
        return "S/ REGISTRO";
      }
    }
  },
  computed: {
    getFornecedores() {
      return this.$store.getters["aluno/getFornecedores"];
    }
  },
  mounted() {
    let turmaID = this.$store.getters["aluno/getTurma"].id;
    this.include.turmaId = turmaID;
    let diaDoPagamento = moment()
      .add(3, "days")
      .format("YYYY-MM-DD");
    const sunSat = this.isSatAndSun(diaDoPagamento);
    if (sunSat) {
      let nextDay = null;
      if (moment(diaDoPagamento).format("ddd") === "Sat") {
        nextDay = moment(diaDoPagamento).add(2, "days");
      }
      if (moment(diaDoPagamento).format("ddd") === "Sun") {
        nextDay = moment(diaDoPagamento).add(1, "days");
      }
      this.include.dataPagamento = moment(nextDay).format("YYYY-MM-DD");
    } else {
      this.include.dataPagamento = moment(diaDoPagamento).format("YYYY-MM-DD");
    }
  },
  methods: {
    isSatAndSun(dataMoment) {
      if (dataMoment) {
        const dataFormated = moment(dataMoment).format("ddd");
        if (dataFormated === "Sun" || dataFormated === "Sat") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isAfter2Days(dataMoment) {
      const next2Days = moment().add(2, "days");
      return dataMoment.isAfter(next2Days);
    },
    validateDate() {
      // # YYYY-MM-DD
      // * Verificar se é maior que 2 dias apos a data atual
      // * Verificar se e' um sabado ou domingo
      const inputData = moment(this.include.dataPagamento);
      const dataValid = moment(this.include.dataPagamento).isValid();
      const isSatAndSunDay = this.isSatAndSun(inputData);
      if (!this.include.dataPagamento || !dataValid) {
        this.handlerErros.push("Data de Pagamento inválida");
        return;
      } else if (!this.isAfter2Days(inputData)) {
        this.handlerErros.push(
          "A data de pagamento deve ser 2 dias após o dia de hoje"
        );
        return;
      } else if (isSatAndSunDay) {
        //é sabado ou domingo
        this.handlerErros.push(
          "A data de pagamento não pode ser direcionada para Sábado ou Domingo."
        );
        return;
      } else {
        return true;
      }
    },
    tratamentoValor() {
      let ValorInclude = this.includeValor;
      if (ValorInclude.indexOf(".") !== -1) {
        ValorInclude = ValorInclude.replaceAll(".", "");
      }
      if (ValorInclude.indexOf(",") !== -1) {
        ValorInclude = ValorInclude.replaceAll(",", ".");
      }
      this.include.valor = Number(ValorInclude);
    },
    filtraForms() {
      this.handlerErros = [];
      if (!this.include.turmaId) {
        this.handlerErros.push("Turma inválida");
      }
      if (!this.include.descricao || this.include.descricao.length < 5) {
        this.handlerErros.push(
          "É necessario ter uma descrição para o pagamento"
        );
      }
      if (!this.include.turmaId) {
        this.handlerErros.push("Turma inválida");
      }
      if (!this.include.valor || this.include.valor < 0.01) {
        this.handlerErros.push("Valor não pode ser zero ou menor.");
      }
    },
    enviarSolicitacao() {
      this.loading = true;
      this.tratamentoValor();
      this.filtraForms();
      this.validateDate();
      if (this.handlerErros.length > 0) {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        return false;
      }

      let dadosPost = { ...this.include };
      serviceSolicitacoes
        .solicitarNovoPagamento(dadosPost)
        .then(() => {
          this.$root.infoModal("Nova solicitação enviada com sucesso!");
          this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
          this.loading = false;
          this.close();
        })
        .catch(err => {
          this.loading = false;
          this.$root.errorModal(err.data);
          console.log(err.data);
        });
    },
    close() {
      this.$emit("close");
    },
    getListaFornecedores() {
      let idTurma = this.$store.getters["aluno/getTurma"].id;
      serviceFornecedores.getFornecedorForTurma(idTurma).then(resp => {
        this.listaDeFornecedores = resp.data;
      });
    }
  }
};
</script>
