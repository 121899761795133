<template>
  <div>
    <div class="px-0 mt- px-sm-4" v-if="roomAtendimento == false">
      <vTitulo class="text-center text-sm-left">
        Central de Atendimento
      </vTitulo>
      <div class="text-center text-sm-right">
        <v-btn
          color="primaryDash"
          depressed
          class="white--text"
          @click="openNewCall()"
        >
          Novo Atendimento
        </v-btn>
      </div>
      <vNovoAtendimento
        @close="modalNovoAtendimento = false"
        v-if="modalNovoAtendimento"
      />
      <vAtendimentoUrgente
        v-if="modalAtendimentoUrgente"
        :configuracoes="configuracoes"
        @atualizarLista="requestAtendimentos"
        @close="modalAtendimentoUrgente = false"
      />
      <vListaAtendimentos
        @abrirITEM="abrirRoomItem"
        :listaDeSolicitacoes="listaDeSolicitacoes"
      />
    </div>
    <vRoomItem
      @close="roomAtendimento = false"
      @atualizarLista="requestAtendimentos"
      :item="itemSelected"
      v-if="roomAtendimento"
    />
  </div>
</template>

<script>
import vListaAtendimentos from "./listaDeAtendimentos";
import vNovoAtendimento from "./novoAtendimento";
import vRoomItem from "./roomAtendimento";
import vAtendimentoUrgente from "./atendimentoUrgente";

import serviceConfiguracoes from "@/middleware/configuracoes";
import serviceAtendimento from "@/middleware/central-atendimento";

export default {
  components: {
    vNovoAtendimento,
    vListaAtendimentos,
    vRoomItem,
    vAtendimentoUrgente
  },
  data() {
    return {
      listaDeSolicitacoes: [],
      modalNovoAtendimento: false,
      itemSelected: {},
      roomAtendimento: false,
      modalAtendimentoUrgente: false,
      configuracoes: {
        telefone: "",
        mensagem: "",
        atendimentoUrgente: false
      }
    };
  },
  beforeMount() {
    this.requestAtendimentos();
    this.requestConfiguracoes();
  },
  methods: {
    requestAtendimentos() {
      serviceAtendimento.meusAtendimentos().then(resp => {
        this.listaDeSolicitacoes = resp.data;
      });
    },
    requestConfiguracoes() {
      serviceConfiguracoes
        .getConfiguracoes()
        .then(resp => {
          this.configuracoes.telefone = resp.data.telefone;
          this.configuracoes.mensagem = resp.data.mensagem;
          this.configuracoes.atendimentoUrgente = resp.data.atendimentoUrgente;

          if (this.configuracoes.atendimentoUrgente) {
            this.abrirModalAtendimento();
          }
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    abrirRoomItem(item) {
      this.roomAtendimento = true;
      this.itemSelected = item;
    },
    openNewCall() {
      this.modalNovoAtendimento = true;
    },
    abrirModalAtendimento() {
      this.modalAtendimentoUrgente = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.bubbleTitle {
  position: absolute;
  width: 80px;
  opacity: 0.5;
}
</style>
