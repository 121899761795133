<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <div class="cardModal">
      <div class="miniPerfil">
        <div>
          <div class="descritivo">Comprador</div>
          <div class="nome">{{ venda.nomeComprador }}</div>
        </div>
        <div>
          <div class="descritivo">Valor dos Bilhetes</div>
          <div class="totalPago">{{ venda.valor | currency }}</div>
        </div>
      </div>
      <div v-if="loadingBilhetes" class="centralizaTudo">
        <v-progress-circular indeterminate size="55"></v-progress-circular>
      </div>

      <div v-else>
        <div class="titulo">
          Bilhetes
        </div>
        <v-list-tickets :scroll="true" :list="listaBilhetes" />
        <!--
        <div class="listaBilhetes">
          <div
            v-for="(bilhete, index) in listaBilhetes"
            :key="index"
            class="itemBilhete"
          >
            {{ bilhete.numero }}
          </div>
        </div>
        -->
      </div>
      <div class="menuBottom">
        <div class="closeButtonContain">
          <v-btn depressed @click="close()">FECHAR</v-btn>
        </div>
        <div class="copyButtonContain">
          <v-btn depressed color="primary" @click="copiarTodosOsBilhetes()"
            >Copiar bilhetes</v-btn
          >
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ServiceBilhetes from "@/middleware/bilhetes";
import { URL_CUSTO_ZERO } from "@/constants/base";
import listaTickets from "@/components/dash/listaTickets.vue";
export default {
  props: ["venda"],
  components: {
    "v-list-tickets": listaTickets
  },
  data() {
    return {
      dialog: true,
      listaBilhetes: [],
      loadingBilhetes: true
    };
  },
  mounted() {
    this.carregarBilhetes();
  },
  methods: {
    copiarTodosOsBilhetes() {
      let newList = [];
      this.listaBilhetes.forEach(element => {
        newList.push(element.numero);
      });
      const urlParam = URL_CUSTO_ZERO; // !tratar no futuro quando houver pagina de acesso
      const listTratamento = newList.toString().replace(/[,]/g, ", ");
      const text =
        `Olá, esses são seus números da sorte: \n` +
        listTratamento +
        `\nAcompanhe no site: ${urlParam}`;
      navigator.clipboard.writeText(text).then(
        () => {
          this.$root.sucessoModal(
            "Bilhetes copiados para área de transferencia"
          );
        },
        () => {
          this.$root.errorModal(
            "Erro, copiar não funcionou corretamente, copie manualmente"
          );
        }
      );
    },
    close() {
      this.$emit("close");
    },
    carregarBilhetes() {
      ServiceBilhetes.getBilhetesPorVendaId(this.venda.id)
        .then(resp => {
          this.listaBilhetes = resp.data;
          this.loadingBilhetes = false;
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal(err?.data?.message);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.closeButtonContain {
  text-align: left;
}
.copyButtonContain {
  text-align: right;
}
.menuBottom {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }
}
.cardModal {
  padding: 20px;
  background-color: white;
  .titulo {
    font-size: 20px;
    font-weight: 300;
    border-bottom: 1px solid rgba(50, 50, 50, 0.1);
  }
  /*.listaBilhetes {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
  }
  .itemBilhete {
    padding: 8px;
    margin: 4px;
    background-color: rgba(245, 245, 245, 1);
    font-weight: 400;
    width: 80px;
    text-align: center;
  }*/
  .miniPerfil {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
    }
    .descritivo {
      font-size: 14px;
      color: rgb(110, 110, 110);
      font-weight: 300;
    }
    .nome {
      font-size: 16px;
      color: rgb(50, 50, 50);
      font-weight: 400;
    }
    .totalPago {
      font-size: 22px;
      color: rgb(50, 50, 50);
      font-weight: 400;
    }
  }
}
</style>
