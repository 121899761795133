<template>
  <div class="contain grey lighten-5">
    <div>
      <div class="containImagem">
        <img class="ImagemSelect" src="@/assets/dash/init-select-turma.svg" />
      </div>
      <div class="font-weight-bold text-h6 py-8 pa-4 text-center">
        Selecione a turma que você deseja entrar
      </div>
      <div class="pa-4 d-flex containList">
        <v-select
          :items="getTurmas"
          v-model="turmaSelecionada"
          item-text="nome"
          item-value="id"
          @change="processaTurmaSelecionada"
          label="Selecione a turma"
        >
          <template v-slot:item="{ item }">
            <div class="customItemSelect">
              <div class="itemName">{{ item.nome }}</div>
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.customItemSelect {
  justify-content: center;
  align-items: center;
}
.itemName {
  font-size: 14px;
  text-align: left;
}
.containPermission {
  background-color: rgba(70, 80, 170, 1);
  border-radius: 4px;
  color: white;
  padding: 5px;
  font-size: 12px;
  text-align: right;
}
.containImagem {
  margin: 0 auto;
  padding-top: 50px;

  text-align: center;
  .ImagemSelect {
    max-width: 200px;
    margin: 0 auto;
  }
}
.containList {
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.bloco {
  width: 150px;
  min-height: 150px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(50, 50, 50, 0.5);
  margin: 6px;
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
}
.contain {
  scroll-behavior: auto;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      lockSelect: false,
      turmaSelecionada: null
    };
  },
  methods: {
    processaTurmaSelecionada() {
      if (!this.lockSelect) {
        this.lockSelect = true;
        let turmaID = this.turmaSelecionada;
        this.$store.dispatch("aluno/SET_TURMA_ATIVA", turmaID).then(() => {
          this.$store.dispatch("aluno/RELOAD_ALL");
          this.selectConcluido();
        });
      }
    },
    selectConcluido() {
      this.$emit("onSelectEvent");
    }
  },
  computed: {
    getTurmas() {
      return this.$store.getters["aluno/getTurmas"];
    }
  }
};
</script>
