export function formatDatePtBr(date) {
  if (isValidDate(date)) {
    let dia = date
      .getUTCDate()
      .toString()
      .padStart(2, "0");
    let mes = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    let ano = date.getUTCFullYear();
    return dia + "/" + mes + "/" + ano;
  }
  return date;
}

function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  );
}

export function formatDateEn(date) {
  let ano = date.getUTCFullYear();
  let mes = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  let dia = date
    .getUTCDate()
    .toString()
    .padStart(2, "0");
  return ano + "-" + mes + "-" + dia;
}

export function convertDateToMonthStr(date) {
  let meses = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ];
  return meses[date.getMonth()];
}

export function convertStringToDatePTBR(str) {
  if (str) {
    if (str.indexOf("/") > -1) {
      return str;
    }
    return formatDatePtBr(new Date(str));
  }
  return str;
}

export function convertDate(str) {
  if (str) {
    if (str.indexOf("/") > -1) {
      let split = str.split("/");
      let date = new Date();
      date.setFullYear(split[2], split[1] - 1, split[0]);
      return date;
    } else if (str.indexOf("-") > -1) {
      let split = str.split("-");
      let date = new Date();
      date.setFullYear(split[0], split[1] - 1, split[2]);
      return date;
    }
    return new Date(str);
  }
  return str;
}

export function toDay() {
  let current = new Date();
  current.setHours(0);
  current.setMinutes(0);
  current.setSeconds(0);
  return current;
}
