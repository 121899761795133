import axios from "axios";
export default {
  getCep(cep) {
    return axios.get(`/v1/cep/${cep}`);
  },
  getEstado() {
    return axios.get("/v1/estado");
  },
  getCidadeForEstado(estadoID) {
    return axios.get(`/v1/cidade/uf/${estadoID}`);
  }
};
