export function searchOnArray(phrases, list) {
  const hasPhrases = Array.isArray(phrases) && phrases.length;
  const hasList = Array.isArray(list) && list.length;

  if (!hasPhrases || !hasList) {
    return [];
  }

  const terms = phrases
    .filter(term => term.length >= 2)
    .map(term => normalize(term));

  const normalizedList = list
    .filter(term => term.length >= 2)
    .map(term => normalize(term));

  if (!terms.length || !normalizedList.length) {
    return [];
  }

  let listReturn = [];
  for (let index = 0; index < normalizedList.length; index++) {
    if (terms.some(term => normalizedList[index].includes(term))) {
      listReturn.push(list[index]);
    }
  }
  return listReturn;
}

export function normalize(str) {
  if (!str) return "";
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}
