import axios from "axios";
import store from "@/store/index";

export default {
  /**
   * Feito para atender o pagamento via pix e tratativa para oferta de bilhetes
   * @param {number} contribuicaoId
   *
   */
  getStatusContribuicao(contribuicaoId) {
    return axios.get(`/v1/contribuicao/status-contribuicao/${contribuicaoId}`);
  },
  pagarContribuicaoComSaldo(contribuicaoId) {
    return axios.post(`/v1/contribuicao/${contribuicaoId}/pagar-com-saldo`);
  },
  getStatusPix(contribuicaoId) {
    return axios.get(`/v1/contribuicao/with-pix/${contribuicaoId}`);
  },
  /**
   * GERAR PIX
   * @param {Object} payload
   * @param {number} payload.contribuicaoId - id da contribuicao
   * @param {number} payload.id - id da promocao (opcional)
   * @returns {Promise}
   */
  gerarPix(payload) {
    if (payload.id == null) {
      return axios.post(
        `/v1/contribuicao/${payload.contribuicaoId}/gerar-pix-oferta-bilhete`,
        { id: null }
      );
    } else {
      return axios.post(
        `/v1/contribuicao/${payload.contribuicaoId}/gerar-pix-oferta-bilhete`,
        {
          id: payload.id
        }
      );
    }
  },
  gerarBoletoDaContribuicao(contribuicaoId) {
    return axios.post(`/v1/contribuicao/${contribuicaoId}/gerar-boleto`);
  },
  getPaginationCotaIndividual(participanteID, page, pageSize, filtros) {
    return axios.post(`/v1/contribuicao/pagination/${participanteID}`, {
      descending: true,
      direction: "ASC",
      filtros: {
        status: filtros.status,
        turmaId: store.getters["aluno/getTurmaAtiva"]
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  },
  updateCotaIndividual(payload) {
    var requestBody = {
      contribuicaoId: payload.contribuicaoId,
      dataVencimento: payload.dataVencimento,
      peMulta: payload.peMulta,
      turmaId: store.getters["aluno/getTurmaAtiva"],
      usuarioId: payload.usuarioId,
      valor: payload.valor,
      valorJuros: payload.valorJuros,
      descricao: payload.descricao
    };
    return axios.post(`/v1/contribuicao/update-cota-individual`, requestBody);
  },
  gerarCotaParaTodos(payload) {
    var requestBody = {
      contribuicaoId: null,
      peMulta: null,
      turmaId: store.getters["aluno/getTurmaAtiva"],
      valorJuros: null,
      valor: payload.valor,
      dataVencimento: payload.dataVencimento,
      descricao: payload.descricao
    };
    return axios.post("/v1/contribuicao/gera-cota-todos", requestBody);
  },
  gerarCotaParaTodosPeriodo(payload) {
    var requestBody = {
      peMulta: null,
      valorJuros: null,
      valor: payload.valor,
      turmaId: store.getters["aluno/getTurmaAtiva"],
      diaDoVencimento: payload.diaDoVencimento,
      mesAnoPrimeiraParcela: payload.mesAnoPrimeiraParcela,
      mesAnoUltimaParcela: payload.mesAnoUltimaParcela,
      descricao: payload.descricao,
      usuarios: payload.usuarios
    };
    return axios.post("/v1/contribuicao/gera-cota-todos-periodo", requestBody);
  },
  gerarCotaIndividualPeriodo(payload) {
    const requestBody = {
      peMulta: null,
      valorJuros: null,
      valor: payload.valor,
      turmaId: store.getters["aluno/getTurmaAtiva"],
      usuarioId: payload.usuarioId,
      diaDoVencimento: payload.diaDoVencimento,
      mesAnoPrimeiraParcela: payload.mesAnoPrimeiraParcela,
      mesAnoUltimaParcela: payload.mesAnoUltimaParcela,
      descricao: payload.descricao
    };
    return axios.post("/v1/contribuicao/gera-cota-todos-periodo", requestBody);
  },
  // deprecated
  gerarCotaIndividual(payload) {
    var requestBody = {
      contribuicaoId: null,
      peMulta: null,
      turmaId: store.getters["aluno/getTurmaAtiva"],
      valorJuros: null,
      usuarioId: payload.usuarioId,
      valor: payload.valor,
      dataVencimento: payload.dataVencimento,
      descricao: payload.descricao
    };
    return axios.post("/v1/contribuicao/gera-cota-individual", requestBody);
  },
  getChartContribuicao() {
    let turmaId = store.getters["aluno/getTurmaAtiva"];
    return axios.post(`/v1/contribuicao/chart/contribuicao/${turmaId}`);
  },
  getChartMetas(ano) {
    let turmaId = store.getters["aluno/getTurmaAtiva"];
    return axios.post(
      `/v1/contribuicao/chart/meta-por-contribuicao/${turmaId}`,
      {
        ano: ano
      }
    );
  },
  getResumoContribuicao() {
    let turmaId = store.getters["aluno/getTurmaAtiva"];
    return axios.post(`/v1/contribuicao/resumo/${turmaId}`);
  },
  gerarContribuicao(payload) {
    var requestBody = {
      contribuicaoId: null,
      peMulta: null,
      valorJuros: null,
      turmaId: store.getters["aluno/getTurmaAtiva"],
      usuarioId: store.getters["aluno/getUser"].id,
      dataVencimento: payload.dataVencimento,
      descricao: payload.descricao,
      valor: payload.valor
    };
    return axios.post("/v1/contribuicao", requestBody);
  },
  excluirContribuicao(contribuicaoId) {
    return axios.delete(`/v1/contribuicao/${contribuicaoId}`);
  },
  paginacaoContribuicoes(pageNumber, pageSize, config) {
    let turmaId = store.getters["aluno/getTurmaAtiva"];
    return axios.post("/v1/contribuicao/pagination", {
      descending: true,
      filtros: {
        id: 0, // ignore
        status: config.status, // null = TODOS, PAGOS,
        turmaId: turmaId
      },
      pageNo: pageNumber,
      pageSize: pageSize,
      sortBy: "string"
    });
  },
  infoNovaContribuicao() {
    let turmaId = store.getters["aluno/getTurmaAtiva"];
    return axios.get(`/v1/contribuicao/info-nova-contribuicao/${turmaId}`);
  }
};
