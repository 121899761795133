<template>
  <div>
    <vPacotes
      @close="fechaModal"
      :impeditivo="impeditivo"
      v-if="modal && !getTurma.pacoteId"
    />
    <div
      class="grey lighten-5 pa-4 text-body-2 text-center"
      v-if="!getTurma.pacoteId && getUser.permissaoTurmaAtiva == 'ADMIN'"
    >
      <a
        @click="abrirModal()"
        class="secondaryDash--text text--darken-2 text-decoration"
        >Falta {{ getTurma.diasRestanteTeste }} dias para seu plano expirar,
        clique para selecionar um plano</a
      >
    </div>
    <router-view />
  </div>
</template>

<script>
import vPacotes from "@/views/aluno/dash/global/modalPacote.vue";

export default {
  components: {
    vPacotes
  },
  data() {
    return {
      modal: false,
      impeditivo: false
    };
  },
  mounted() {
    if (
      this.getTurma.diasRestanteTeste == 0 &&
      !this.getTurma.pacoteId &&
      this.getUser.permissaoTurmaAtiva == "ADMIN"
    ) {
      this.modal = true;
      this.impeditivo = true;
    }
    // if (
    //   this.$store.getters["aluno/getUser"].aceiteFCZ &&
    //   this.$store.getters["global/getModuleFCZ"] &&
    //   this.$store.getters["aluno/getTurmaConfig"].enableFCZ
    // ) {
    //   console.log("TROCOU A ROTA PARA FCZ!!");
    //   this.$router.push("/aluno/dashboard/programa-custo-zero");
    //   //teste
    // }
  },
  methods: {
    abrirModal() {
      this.modal = true;
    },
    fechaModal() {
      this.modal = false;
      this.impeditivo = false;
    }
  },
  computed: {
    getUser() {
      return this.$store.getters["aluno/getUser"];
    },
    getTurma() {
      return this.$store.getters["aluno/getTurma"];
    }
  }
};
</script>

<style></style>
