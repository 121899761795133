import axios from "axios";
import store from "@/store/index";

export default {
  extratoMovimentacao(payload) {
    var turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(
      "/v1/relatorio-turma/extrato-movimentacao",
      {
        turmaId: turmaID,
        tipoRel: payload.tipoRel,
        dataInicial: payload.dataInicial,
        dataFinal: payload.dataFinal
      },
      {
        responseType: "blob"
      }
    );
  },
  inadimplentes(payload) {
    var turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(
      "/v1/relatorio-turma/inadimplentes",
      {
        turmaId: turmaID,
        tipoRel: payload.tipoRel,
        dataInicial: payload.dataInicial,
        dataFinal: payload.dataFinal
      },
      {
        responseType: "blob"
      }
    );
  },
  listaUsuariosDaTurma(payload) {
    var turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post(
      "/v1/relatorio-turma/usuarios-turma",
      {
        turmaId: turmaID,
        tipoRel: payload.tipoRel,
        dataInicial: payload.dataInicial,
        dataFinal: payload.dataFinal
      },
      {
        responseType: "blob"
      }
    );
  }
};
