<template>
  <div class="listaBilhetes">
    <div
      v-for="(item, index) in list"
      :key="index"
      :title="'Comprado em ' + formatarData(item.dataCompra)"
      class="itemBilhete"
    >
      <div class="numBilhete">Nº {{ item.numero }}</div>
      <div class="containImagem"></div>
      <div class="containDate">
        <div class="dataCompra ">
          {{ formatarData(item.dataCompra) }}
        </div>
        <div class="horarioCompra">
          {{ formatarHorario(item.dataCompra) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    list: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  methods: {
    formatarHorario(data) {
      return moment(data).format("hh:mm");
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
$sm: 600px;
.dataCompra {
  color: rgb(30, 41, 59);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.horarioCompra {
  color: rgb(71, 85, 105);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.hasTickets {
  padding: 12px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(51, 65, 85);
}
.noTickets {
  color: rgb(71, 85, 105);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 20px;
}
.listaBilhetes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px;
  @media screen and (max-width: $sm) {
    justify-content: center;
    padding: 0px;
  }
  .itemBilhete {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid rgb(226, 232, 240);
    margin: 0.25rem;
    padding: 0.5rem;
    border-radius: 6px;
    width: 150px;
    height: 150px;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $sm) {
      width: 100%;
    }
    .numBilhete {
      flex: none;
      font-weight: 400;
      font-size: 0.875rem /* 14px */;
      line-height: 1.25rem /* 20px */;
      color: rgb(30, 41, 59);
    }
    .containImagem {
      background-image: url("../../assets/dash/fcz/bilhetes.svg");
      background-position: center center;
      background-size: cover;
      width: 180px;
      flex: 1;
      @media screen and (max-width: 300px) {
        width: 100%;
      }
    }
    .containDate {
      flex: none;
    }
  }
}
</style>
