<template>
  <div>
    <v-bottom-sheet v-model="sheet" inset>
      <v-sheet class="text-center cardModal rounded-t-xl pt-4">
        <center>
          <hr
            style="display: inline-block;width: 80px; padding: 2px; border: 2px solid rgba(220,220,220,1); background-color: rgba(220,220,220,1); border-radius: 5px"
          />
        </center>
        <div class="titulo text-center pa-5 pt-8">
          AppDaTurma
        </div>
        <div class="texto text-center px-3">
          Tenha acesso a qualquer momento adicionando o ícone do AppDaTurma na
          área de trabalho do seu celular.
        </div>
        <div class="buttons">
          <v-btn
            color="#7275FF"
            class="pa-5 ma-5 rounded-pill"
            style="color: white!important;"
            @click="installer()"
          >
            Adicionar ícone
          </v-btn>
          <v-btn
            color="#ffff"
            elevation="0"
            class="pa-5 ma-5 rounded-pill"
            style="color: #7275FF!important;"
            @click="fecharmodal()"
          >
            Cancelar
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  props: ["installPrompt"],
  data() {
    return {
      sheet: true
    };
  },
  methods: {
    fecharmodal() {
      this.$emit("fecharModalPWA");
    },
    installer() {
      this.installBtn = "none";
      this.displayModalPWA = false;
      this.installPrompt.prompt();
      this.installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          console.log("User accept");
        } else {
          console.log("User denied");
        }
        this.installPrompt = null;
        this.fecharmodal();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.modalThis {
  padding: 0px !important;
  margin: 0px !important;
  max-height: auto;
  .v-dialog {
    margin: 0px !important;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    height: 100%;
    margin: 0px !important;
  }
}
.cardModal {
  .titulo {
    font-weight: 900;
    font-size: 28px;
    color: rgba(10, 10, 10, 1);
  }
  .texto {
    font-size: 16px;
    font-weight: normal;
    color: #666666;
  }
  .buttons {
    display: grid;
    grid-template-columns: 1fr;
  }
}
</style>
