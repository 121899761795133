import axios from "axios";

export default {
  getItensPacote() {
    return axios.get("/v1/tipos/itens-pacote");
  },
  getStatusContribuicoes() {
    return axios.get("/v1/tipos/status-contribuicao");
  },
  getItensCompleto() {
    return axios.get("/v1/servico");
  },
  getTiposCalculo() {
    return axios.get("/v1/tipos/tipo-calculo");
  },
  getItensCalculadora() {
    return axios.get("/v1/tipos/itens-calculadora");
  }
};
