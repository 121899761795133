<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <div>
          <v-row class="pa-4 ma-0 grey lighten-4 align-center">
            <v-col cols="9" class="ma-0 pa-0">
              <div class="font-weight-bold ">
                Detalhes do fornecedor
              </div>
            </v-col>
            <v-col cols="3" class="ma-0 pa-0 text-right">
              <v-btn icon depressed @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div class="pa-5">
          <div class="pb-1">
            <div class="font-weight-light text-overline">Fornecedor</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.nome }}
            </div>
          </div>
          <div class="pb-1">
            <div class="font-weight-light text-overline">Razão Social</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.razaoSocial }}
            </div>
          </div>
          <div class="pb-1">
            <div class="font-weight-light text-overline">Email</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.email }}
            </div>
          </div>
          <div class="pb-1">
            <div class="font-weight-light text-overline">Telefone</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.telefone }}
            </div>
          </div>
          <div class="pb-1">
            <div class="font-weight-light text-overline">CPF ou CNPJ</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.cpfCnpj }}
            </div>
          </div>
          <div class="pb-1">
            <div class="font-weight-light text-overline">Titular da Conta</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.titularConta }}
            </div>
          </div>
          <div class="pb-1" v-if="dados.chavePix">
            <div class="font-weight-light text-overline">Chave PIX</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.chavePix }}
            </div>
          </div>
          <div class="pb-1" v-if="dados.banco">
            <div class="font-weight-light text-overline">Banco</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.banco }}
            </div>
          </div>
          <div class="pb-1" v-if="dados.agencia || dados.agenciaDigito">
            <div class="font-weight-light text-overline">Agência - Digito</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.agencia }}
              <span v-if="dados.agencia && dados.agenciaDigito">-</span>
              {{ dados.agenciaDigito }}
            </div>
          </div>

          <div class="pb-1" v-if="dados.conta || dados.contaDigito">
            <div class="font-weight-light text-overline">Conta - Digito</div>
            <div class="grey--text font-weight-normal text--darken-3">
              {{ dados.conta }}
              <span v-if="dados.conta && dados.contaDigito">-</span>
              {{ dados.contaDigito }}
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    fornecedor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  computed: {
    dados() {
      return this.fornecedor;
    }
  }
};
</script>

<style></style>
