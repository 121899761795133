<template>
  <div>
    <v-card class="mx-2 mx-sm-5 mb-0 mb-sm-5 pa-3 pa-sm-5 lowShadow">
      <div class="form">
        <div
          class="text-center text-sm-left primaryDash--text text-h6 text-sm-h5 py-2"
        >
          Registro
        </div>
        <div>
          <v-text-field
            name="empresa"
            label="Nome"
            v-model="include.nome"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            name="razao"
            label="Razão Social"
            v-model="include.razaoSocial"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            name="titular"
            label="Titular da Conta"
            v-model="include.titularConta"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            name="titular"
            label="CPF ou CNPJ"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="include.cpfCnpj"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            name="telefone"
            label="Telefone para contato"
            v-model="include.telefone"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            name="email"
            label="Email"
            v-model="include.email"
          ></v-text-field>
        </div>
        <v-card class="grey lighten-4 pa-5 py-6 my-2" flat>
          <div class="font-weight-bold">
            Dados Sensíveis / Bancário
          </div>
          <div>
            <v-select
              :items="listaDeBancos"
              v-model="bankSelected"
              item-value="id"
              item-text="descricao"
              return-object
              label="Selecione o Banco"
            ></v-select>
          </div>
          <div v-if="bankSelected.descricao == 'OUTRO BANCO'">
            <v-text-field
              name="codigoBanco"
              label="Código do Banco"
              v-model="include.codigoBanco"
            ></v-text-field>
          </div>
          <div v-if="bankSelected.descricao == 'OUTRO BANCO'">
            <v-text-field
              name="banco"
              label="Nome do Banco"
              v-model="include.banco"
            ></v-text-field>
          </div>
          <div class="ag text-subtitle-2">
            AGÊNCIA
          </div>
          <v-row class="pb-1">
            <v-col cols="12" sm="8">
              <v-text-field
                name="agenciaNumber"
                label="Número"
                type="number"
                v-model="include.agencia"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                name="digitoAg"
                label="Dígito"
                type="number"
                v-model="include.agenciaDigito"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="text-subtitle-2 mt-3">
            CONTA
          </div>
          <v-row>
            <v-col cols="12" sm="8">
              <v-text-field
                name="Número"
                label="Número"
                type="number"
                v-model="include.conta"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                name="digitoAg"
                label="Dígito"
                type="number"
                v-model="include.contaDigito"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="mt-4">
            <v-text-field
              name="chavePix"
              label="Chave PIX"
              v-model="include.chavePix"
              hide-details
            ></v-text-field>
          </div>
        </v-card>
      </div>
      <div class="text-center ">
        <v-textarea
          v-model="include.descricao"
          label="Descreva os serviços prestados pelo fornecedor"
          class="my-2"
          hide-details
          rows="4"
        >
        </v-textarea>
      </div>
      <v-card
        v-if="handleErros.length > 0"
        class="red lighten-5 pa-4 elevation-0 red--text text--darken-3"
      >
        <div class="px-2 d-flex">
          <v-icon color="red">mdi-alert-circle-outline</v-icon>
          <div class="d-inline-block px-2 red--text font-weight-bold">
            Erros encontrados
          </div>
        </div>
        <ul>
          <li
            class="item pa-1 "
            v-for="(erro, index) in handleErros"
            :key="index"
          >
            {{ erro }}
          </li>
        </ul>
      </v-card>
      <div class="text-center pa-2">
        <v-btn
          :loading="loading"
          color="primaryDash"
          class="white--text"
          @click="cadastrar()"
          >Cadastrar</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import serviceBanco from "@/middleware/bancos";
import serviceFornecedor from "@/middleware/fornecedores";
export default {
  data() {
    return {
      handleErros: [],
      listaDeBancos: [],
      dialog: true,
      toggleCPF: false,
      bankSelected: "",
      loading: false,
      include: {
        agencia: "", //ok
        agenciaDigito: "", //ok
        banco: "", //ok
        codigoBanco: "", //   ok,
        conta: "", //ok
        contaDigito: "", //ok
        titularConta: "",
        cpfCnpj: "",
        chavePix: "",
        descricao: "",
        id: null,
        nome: "",
        razaoSocial: "",
        telefone: "",
        turmaId: 0
      }
    };
  },
  watch: {
    toggleCPF: function() {
      this.clearCPFCNPJ();
    },
    bankSelected: function(val) {
      if (val.descricao == "OUTRO BANCO") {
        this.include.banco = "";
      } else {
        this.include.banco = val.descricao;
      }
      this.include.codigoBanco = val.id;
    }
  },
  methods: {
    getBancos() {
      serviceBanco
        .getBancos()
        .then(resp => {
          this.listaDeBancos = resp.data;
        })
        .finally(() => {
          this.listaDeBancos.push({ descricao: "OUTRO BANCO", id: 0 });
        })
        .catch(err => {
          console.error(err.data);
        });
    },
    clearCPFCNPJ() {
      this.$nextTick(() => {
        this.include.cpfCnpj = "";
      });
    },
    verificarValor(val, minval, msgError) {
      if (val) {
        if (val.length < minval) {
          this.handleErros.push(msgError);
        }
      } else {
        this.handleErros.push(msgError);
      }
    },
    verificaTudo() {
      let dados = { ...this.include };
      this.handleErros = [];
      this.verificarValor(dados.nome, 5, "Nome inválido");
      this.verificarValor(dados.titularConta, 5, "Titular inválido");

      if (dados.cpfCnpj.length == 18 || dados.cpfCnpj.length == 14) {
        if (dados.cpfCnpj.length == 18) {
          let result = this.$root.validaCNPJ(dados.cpfCnpj);
          if (!result) {
            this.handleErros.push("CNPJ Inválido");
          }
        }
        if (dados.cpfCnpj.length == 14) {
          let result = this.$root.validateCPF(dados.cpfCnpj);
          if (!result) {
            this.handleErros.push("CPF Inválido");
          }
        }
      }
      if (dados.cpfCnpj.length !== 18 && dados.cpfCnpj.length !== 14) {
        this.handleErros.push("CPF ou CNPJ inválido!");
      }
    },
    cadastrar() {
      this.loading = true;
      this.verificaTudo();
      if (this.handleErros.length > 0) {
        this.loading = false;
        return false;
      }
      serviceFornecedor
        .novoFornecedor(this.include)
        .then(() => {
          this.$emit("atualizar");
          this.loading = false;
          this.$root.infoModal("Enviado com sucesso!");
          this.$emit("chama_rota", "fornecedores");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
          console.error(err);
        });
    },
    removerFornecedor(idFornecedor) {
      serviceFornecedor
        .removeFornecedor(idFornecedor)
        .then(resp => {
          this.$root.infoModal(resp.data);
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal(err.data);
        });
    }
  },
  mounted() {
    this.getBancos();
    this.include.turmaId = this.$store.getters["aluno/getTurma"].id;
  }
};
</script>
