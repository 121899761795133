<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
      persistent
      transition="dialog-transition"
    >
      <v-card class="pa-3">
        <div>
          <v-row class="pa-2">
            <v-col cols="8" class="text-left primaryDash--text text-h6">
              Atendimento Urgente
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn @click="close()" depressed color="grey lighten-4">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <span
                class="grey--text text--darken-3 text-subtitle-1 mb-3"
                v-html="configuracoes.mensagem"
              >
              </span>
            </v-col>
            <v-col cols="12" class="text-center py-1 pb-3">
              <v-btn color="success" @click="abrirWhatsApp()">
                WhatsApp
                <v-icon class="ml-2" dark>
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["configuracoes"],
  data() {
    return {
      dialog: true
    };
  },
  beforeMount() {},
  methods: {
    abrirWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=55" + this.configuracoes.telefone
      );
      this.close();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
