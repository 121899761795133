<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
      persistent
      transition="dialog-transition"
    >
      <v-card class="pa-3">
        <div>
          <v-row class="pa-2">
            <v-col cols="8" class="text-left primaryDash--text text-h6">
              Novo Atendimento
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn @click="close()" depressed color="grey lighten-4">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="px-2">
            <v-col cols="12">
              <v-select
                :items="listaSetores"
                v-model="solicitacao.setor"
                label="Setor / Área"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="titulo"
                label="Resumo do problema "
                id="id"
                v-model="solicitacao.titulo"
                maxlength="50"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                dense
                no-resize
                label="Descreva o problema"
                placeholder="Quanto mais detalhes melhor"
                v-model="solicitacao.descricao"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" class="text-center py-1 pb-3">
              <v-btn color="primary" block @click="criarNovoAtendimento()">
                REGISTRAR
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceAtendimento from "@/middleware/central-atendimento";
export default {
  data() {
    return {
      dialog: true,
      listaStatus: ["Pendente", "Cancelada", "Em Andamento", "Concluído"],
      listaSetores: ["Financeiro", "Ouvidoria", "Suporte", "Técnico"],
      solicitacao: {
        titulo: "",
        setor: "Ouvidoria",
        descricao: ""
      }
    };
  },
  beforeMount() {
    this.solicitacao.titulo = "";
    this.solicitacao.setor = "Ouvidoria";
    this.solicitacao.descricao = "";
  },
  methods: {
    filtrarDados() {
      if (!this.solicitacao.titulo || this.solicitacao.titulo.length < 3) {
        this.$root.errorModal("Titulo está muito pequeno");
        return false;
      }
      if (
        !this.solicitacao.descricao ||
        this.solicitacao.descricao.length < 10
      ) {
        this.$root.errorModal(
          "Descrição muito pequena, deve conter no mínimo 10 caracteres"
        );
        return false;
      }
      return true;
    },
    criarNovoAtendimento() {
      let rest = this.filtrarDados();
      if (!rest) return false;
      let novoAtendimento = this.solicitacao;
      serviceAtendimento
        .novoAtendimento(novoAtendimento)
        .then(() => {
          this.$emit("atualizarLista");
          this.$root.sucessoModal("Atendimento criado com sucesso!");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        })
        .finally(() => {
          this.$emit("close");
        });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
