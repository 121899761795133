<template>
  <div class="containPage">
    <div class="containBanner">
      <img
        class="banner"
        src="https://s3.amazonaws.com/br.com.appdaturma.bucket/appdaturma/banner-appdaturma.jpg"
      />
    </div>
    <div class="titulo">Promoção Formatura Custo Zero!</div>
    <div class="descricao">
      Caro, formando(a),<br />
      Você não pode perder essa oportunidade única! Apresentamos a Promoção
      Formatura Custo Zero, criada exclusivamente para os formandos do App da
      Turma. Agora, você pode pagar suas cotas da formatura sem gastar um
      centavo do seu próprio bolso. Participar é simples e fácil. Clique no
      botão abaixo e saiba mais. Adesão totalmente gratuita.
    </div>
    <div class="subtexto">
      Deseja participar do programa <b>Formatura Custo Zero</b>
    </div>
    <div class="termos">
      <v-row class="align-center textoPadrao" no-gutters>
        <v-checkbox v-model="checkTermos"></v-checkbox>
        Aceito os temos de uso
        <a style="padding-left: 10px" target="_blank" :href="routerLinkTermos"
          >Termos de uso</a
        >
      </v-row>
      <v-row class="align-center textoPadrao" no-gutters>
        Consulte o
        <a
          style="padding-left: 10px"
          target="_blank"
          :href="routerLinkRegulamento"
          >regulamento completo</a
        >
      </v-row>
    </div>
    <div class="options" v-if="hasButton">
      <v-row class="text-center">
        <v-col v-if="isModal" cols="6" class="text-center">
          <v-btn
            large
            depressed
            color="blue-grey lighten-5"
            class="btnCustomStyle"
            @click="fecharModal()"
            >Fechar</v-btn
          >
        </v-col>
        <v-col
          :cols="getColumnLayout"
          :class="{ 'text-center': isModal, 'text-right': !isModal }"
        >
          <v-btn
            large
            depressed
            class="btnCustomStyle"
            color="secondary"
            @click="confirmarParticipacao()"
            >Participar</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ContratosURL from "../constants/contratos";
export default {
  name: "custo-zero",
  props: {
    hasButton: Boolean,
    isModal: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      routerLinkTermos: ContratosURL.adesaoFCZ,
      routerLinkRegulamento: ContratosURL.regulamentoFCZ,
      checkTermos: false,
      dialog: true
    };
  },
  computed: {
    getColumnLayout() {
      if (this.isModal) {
        return 6;
      } else {
        return 12;
      }
    }
  },
  methods: {
    confirmarParticipacao() {
      if (this.checkTermos) {
        this.$emit("confirm");
      } else {
        this.$root.errorModal(
          "Para participar você deve aceitar os termos de uso"
        );
      }
    },
    fecharModal() {
      this.$emit("close");
    },
    naoExibirMais() {
      this.$emit("naoExibirMais");
    }
  }
};
</script>

<style lang="scss" scoped>
.btnCustomStyle {
  width: 50%;
}
.containPage {
  padding: 10px;

  .containBanner {
    text-align: center;
  }

  .textoPadrao {
    font-size: 14px;
  }

  .banner {
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
  }

  .titulo {
    font-weight: 500;
    font-size: 18px;
    //margin: 5px 0px;
    //padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    //border-left: 8px solid $colorLightPurble;
    color: rgb(50, 50, 50);
  }
  /* rgb(40, 49, 61) */
  .descricao {
    font-size: 14px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-family: "Poppins", sans-serif !important;
    line-height: 22px;
    color: rgb(107, 103, 107);
    padding: 10px 0px;
  }

  .subtexto {
    margin-top: 14px;
    font-size: 14px;
  }

  .options {
    margin-top: 20px;
  }
}
</style>
