<template>
  <div>
    <vTitulo>Depósitos</vTitulo>
    <div class="pa-4 white ma-2 mx-4 rounded-lg lowShadow">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="8">
          <div class="d-grid grid-col-1-auto align-center justify-center">
            <v-select
              :items="listaStatus"
              v-model="filtros.status"
              item-text="name"
              item-value="code"
              hide-details
              label="Filtrar por status"
            ></v-select>
            <div class="px-2">
              <v-btn
                class="d-sm-inline-block d-none mx-2"
                color="secondaryDash"
                depressed
                dark
                :loading="loading"
                @click="getPagination(1)"
                >BUSCAR</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="text-sm-right text-center">
          <v-btn
            class="d-sm-none d-inline-block mx-2 my-1"
            color="secondaryDash"
            depressed
            dark
            :loading="loading"
            @click="getPagination(1)"
            >BUSCAR</v-btn
          >
          <v-btn
            class="my-1"
            dark
            depressed
            color="secondaryDash"
            @click="modalNovoDeposito = true"
            >Novo depósito</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div class="pa-4 white ma-2 mx-4 rounded-lg lowShadow">
      <div class="d-block d-sm-none">
        <v-timeline dense>
          <v-timeline-item
            :key="index"
            v-for="(item, index) in listaDepositos"
            :color="getColor(item.status)"
            small
            dense
            fill-dot
          >
            <div>
              <div
                :class="`headline mb-2`"
                :style="{ color: getColor(item.status) }"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  {{ item.status | statusName }}
                </div>
              </div>
              <div class="pa-1 text-left">
                <div>
                  <div class="py-1 text-body-2 font-weight-bold">Nome</div>
                  <div class="text-body-2">
                    {{ item.nome }}
                  </div>
                  <div class="py-1 text-body-2 font-weight-bold">CPF/CNPJ</div>
                  <div class="text-body-2">
                    {{ item.cpfCnpj }}
                  </div>
                  <div class="py-1 text-body-2 font-weight-bold">Descrição</div>
                  <div class="text-body-2">
                    {{ item.descricao }}
                  </div>

                  <div class="py-1 text-body-2 font-weight-bold">
                    Data Venc.
                  </div>
                  <div class="text-body-2">
                    {{ item.dataVencimento | dataDisplay }}
                  </div>
                  <div class="py-1 text-body-2 font-weight-bold">
                    Data Pag.
                  </div>
                  <div class="text-body-2 mb-1">
                    {{ item.dataPagamento | dataDisplay }}
                  </div>
                  <div class="text-body-1 font-weight-bold py-2">
                    {{ item.valor | currency }}
                  </div>

                  <div v-if="item" class="pt-1">
                    <div v-if="!item.tipoPagamento">
                      <v-btn
                        color="secondaryDash"
                        depressed
                        dark
                        small
                        @click="openSelectMethodsPayment(item)"
                      >
                        PAGAR
                      </v-btn>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          (isDue(item.dataVencimento) &&
                            ['NAO_PAGO', 'EXPIRADO'].includes(item.status)) ||
                            'EXPIRADO' === item.status
                        "
                      >
                        <v-btn
                          color="purple lighten-1"
                          depressed
                          dark
                          small
                          @click="openSelectMethodsPayment(item)"
                        >
                          REGERAR
                        </v-btn>
                      </div>
                      <div v-else>
                        <div
                          v-if="
                            item.tipoPagamento === 'BOLETO' &&
                              item.status === 'NAO_PAGO'
                          "
                        >
                          <v-btn
                            text
                            small
                            color="primary"
                            @click="abrirInfoBoleto(item)"
                            title="Abrir boleto"
                          >
                            <v-icon size="30"> mdi-barcode </v-icon>
                          </v-btn>
                        </div>
                        <div
                          v-if="
                            item.tipoPagamento == 'PIX' &&
                              item.status == 'NAO_PAGO'
                          "
                        >
                          <v-btn small text @click="abrirInfoPix(item)">
                            <img
                              style="width: 20px"
                              src="@/assets/pix-logo.svg"
                            />
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
        <div v-if="totalPaginas < page + 1" class="pa-2">
          <v-btn
            :loading="loading"
            depressed
            dark
            color="primaryDash"
            @click="TimeLineRequest()"
            >Ver mais</v-btn
          >
        </div>
      </div>
      <div class="d-sm-block d-none">
        <v-data-table
          :headers="headers"
          :items="listaDepositos"
          item-key="id"
          :loading="loading"
          :page.sync="page"
          :items-per-page="itemsPorPagina"
          :options.sync="options"
          :server-items-length="totalDepositos"
          loading-text="Carregando..."
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100]
          }"
        >
          <template v-slot:body="{ items }">
            <tbody class="text-center">
              <tr v-for="item in items" :key="item.name">
                <td class="text-center">
                  {{ item.dataVencimento | dataDisplay }}
                </td>
                <td class="text-center">
                  {{ item.dataPagamento | dataDisplay }}
                </td>
                <td class="text-left">{{ item.descricao }}</td>
                <td class="text-center">{{ item.nome }}</td>
                <td class="text-center">{{ item.cpfCnpj }}</td>
                <td class="text-center">{{ item.valor | currency }}</td>
                <td class="text-center">
                  <v-chip outlined :color="getColor(item.status)">
                    {{ item.status | statusName }}
                  </v-chip>
                </td>
                <td class="text-center">
                  <div v-if="!item.tipoPagamento">
                    <v-btn
                      color="secondaryDash"
                      depressed
                      dark
                      small
                      @click="openSelectMethodsPayment(item)"
                    >
                      PAGAR
                    </v-btn>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        (isDue(item.dataVencimento) &&
                          ['NAO_PAGO', 'EXPIRADO'].includes(item.status)) ||
                          'EXPIRADO' === item.status
                      "
                    >
                      <v-btn
                        color="purple lighten-1"
                        depressed
                        dark
                        small
                        @click="openSelectMethodsPayment(item)"
                      >
                        REGERAR
                      </v-btn>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          item.tipoPagamento === 'BOLETO' &&
                            item.status === 'NAO_PAGO'
                        "
                      >
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="abrirInfoBoleto(item)"
                          title="Abrir boleto"
                        >
                          <v-icon size="30"> mdi-barcode </v-icon>
                        </v-btn>
                      </div>
                      <div
                        v-if="
                          item.tipoPagamento == 'PIX' &&
                            item.status == 'NAO_PAGO'
                        "
                      >
                        <v-btn small text @click="abrirInfoPix(item)">
                          <img
                            style="width: 20px"
                            src="@/assets/pix-logo.svg"
                          />
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </div>
    <vModalNovoDeposito
      @close="modalNovoDeposito = false"
      v-if="modalNovoDeposito"
      @update="getPagination()"
    />
    <vModalAnexos
      :item="payloadItem"
      @close="modalAnexos = false"
      v-if="modalAnexos"
    />
    <vGerarMetodoPagamento
      v-if="modalControlePagamento"
      :divida="payloadItem"
      :tipo="tipoDivida"
      @close="modalControlePagamento = false"
      @update="getPagination()"
    />

    <vModalBoletoInfo
      style="z-index: 9999"
      @close="modalViewBoleto = false"
      v-if="modalViewBoleto"
      :divida="payloadItem"
      :tipo="tipoDivida"
      @update="getPagination()"
    />
    <vModalPixRegerar
      @close="modalRegerarPix = false"
      v-if="modalRegerarPix"
      :divida="payloadItem"
      :tipo="tipoDivida"
      @update="getPagination()"
    />
    <vModalInfoPix
      style="z-index: 9999"
      @close="modalInfoPix = false"
      v-if="modalInfoPix"
      :divida="payloadItem"
      :tipo="tipoDivida"
      @update="getPagination()"
    />
  </div>
</template>

<script>
import {
  SelectArray,
  statusDisplay,
  colorsForType
} from "@/constants/participante/statusDePagamento";
import vModalNovoDeposito from "./ModalNovoDeposito.vue";
import vModalAnexos from "./ModalDetalhes.vue";
import moment from "moment";
import serviceDeposito from "@/middleware/depositos";
import vGerarMetodoPagamento from "../pagamentoParcelas/gerarMetodoPagamento/index.vue";
import vModalPixRegerar from "../pagamentoParcelas/gerarMetodoPagamento/regerarPix.vue";
import vModalBoletoInfo from "../pagamentoParcelas/gerarMetodoPagamento/infoBoleto.vue";
import vModalInfoPix from "../pagamentoParcelas/gerarMetodoPagamento/infoPix.vue";
import { TIPO_DIVIDA_DEPOSITO } from "@/constants";

export default {
  components: {
    vModalNovoDeposito,
    vModalAnexos,
    vGerarMetodoPagamento,
    vModalPixRegerar,
    vModalBoletoInfo,
    vModalInfoPix
  },
  data() {
    return {
      tipoDivida: TIPO_DIVIDA_DEPOSITO,
      loading: true,
      modalAnexos: false,
      modalNovoDeposito: false,
      modalRegerarPix: false,
      modalControlePagamento: false,
      modalCopyToggle: false,
      modalGerarContribuicao: false,
      modalViewBoleto: false,
      modalInfoPix: false,
      payloadItem: {},
      listaStatus: [],
      filtros: {
        turmaId: 0,
        status: null
      },
      page: 1,
      itemsPorPagina: 20,
      totalDepositos: 0,
      totalPaginas: 0,
      options: {},
      headers: [
        {
          text: "DT Vencimento",
          align: "center",
          value: "dataVencimento"
        },
        { text: "DT Pagamento", value: "dataPagamento", align: "center" },
        { text: "Descrição", value: "descricao", align: "left" },
        { text: "Nome", value: "nome", align: "center" },
        { text: "CPF/CNPJ", value: "cpfCnpj", align: "center" },
        { text: "Valor", value: "valor", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Opções", value: "", sortable: false, align: "center" }
      ],
      listaDepositos: []
    };
  },
  filters: {
    dataDisplay(data) {
      if (data) {
        return moment(data).format("DD/MM/YYYY");
      } else {
        return "S/REGISTRO";
      }
    },
    statusName(valor) {
      return statusDisplay[valor];
    },
    verificaRegistro(valor) {
      if (valor) return valor;
      else return "S/ REGISTRO";
    }
  },
  mounted() {
    this.listaStatus = SelectArray;
    this.filtros.turmaId = this.$store.getters["aluno/getTurmaAtiva"];
    this.getPagination();
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.itemsPorPagina = d.itemsPerPage;
        this.getPagination();
      },
      deep: true
    }
  },
  methods: {
    getStatusName(valor) {
      return statusDisplay[valor];
    },
    TimeLineRequest() {
      this.loading = true;
      this.itemsPorPagina += 20;
      this.getPagination();
    },
    getPagination(page) {
      this.loading = true;
      if (page) {
        this.page = page;
      }
      serviceDeposito
        .getPagination(this.page - 1, this.itemsPorPagina, this.filtros)
        .then(resp => {
          this.listaDepositos = resp.data.content;
          this.totalDepositos = resp.data.totalElements;
          this.totalPaginas = resp.data.totalPages;
          this.loading = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    updateLista() {
      this.getPagination();
    },
    abrirModalAnexos(item) {
      this.modalAnexos = true;
      this.payloadItem = item;
    },
    getColor(statusID) {
      return colorsForType[statusID];
    },
    abrirInfoPix(item) {
      this.modalInfoPix = true;
      this.payloadItem = item;
    },
    abrirInfoBoleto(item) {
      this.modalViewBoleto = true;
      this.payloadItem = item;
    },
    abrirRegerarPix(item) {
      this.modalRegerarPix = true;
      this.payloadItem = item;
    },
    openSelectMethodsPayment(item) {
      this.modalControlePagamento = true;
      this.payloadItem = item;
    },
    AbrirModalCopia(item) {
      this.selectCopyItem = item;
      this.modalCopyToggle = true;
    },
    isDue(dataVencimento) {
      var hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      hoje.setMinutes(0, 0, 0);
      hoje.setMilliseconds(0);
      return new Date(dataVencimento) < hoje;
    }
  }
};
</script>

<style></style>
