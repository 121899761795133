<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      overla
      transition="dialog-transition"
    >
      <v-card class="pa-4">
        <div class="text-right">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="pa-5 text-center d-flex justify-center align-center">
          <div class="d-inline-block mr-2">
            <v-icon size="35" color="black">
              mdi-barcode
            </v-icon>
          </div>
          <div class="d-inline-block font-weight-bold text-h6">Boleto</div>
        </div>
        <div class="text-center">
          <div class="pa-2">
            <div class="text-overline font-weight-normal text-uppercase">
              Data de Vencimento
            </div>
            <div class="text-body-2 grey--text text--darken-1">
              {{ divida.dataVencimento | getData }}
            </div>
          </div>
          <div class="pa-2">
            <div class="text-overline font-weight-normal text-uppercase">
              Valor
            </div>
            <div class="text-body-2 grey--text text--darken-1">
              {{ divida.valor | currency }}
            </div>
          </div>
          <div class="pa-2">
            <div class="text-overline font-weight-normal text-uppercase">
              Descrição
            </div>
            <div class="text-body-2 grey--text text--darken-1">
              {{ divida.descricao | existeDados }}
            </div>
          </div>
          <div class="pa-2">
            <div class="text-overline font-weight-normal text-uppercase">
              Código de Barras
            </div>
            <div class="text-body-2 grey--text text--darken-1">
              {{ divida.codigoBarras }}
            </div>
          </div>
          <div class="text-center py-4">
            <a
              :target="gerarURLBoleto(divida.boletoUrl)"
              :href="gerarURLBoleto(divida.boletoUrl)"
            >
              <v-btn color="primary" depressed>ABRIR BOLETO</v-btn>
            </a>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { URL_BASE } from "@/constants/base";
import moment from "moment";
export default {
  props: {
    divida: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  filters: {
    existeDados(val) {
      if (val) return val;
      else return "S/REGISTRO";
    },
    getData(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "S/REGISTRO";
      }
    }
  },
  methods: {
    gerarURLBoleto(url) {
      return URL_BASE + url;
    }
  }
};
</script>

<style></style>
