<template>
  <div class="desktopItem " :class="{ mobile: isMobile }">
    <div class="textAdd">
      Você pode adicionar novos participantes
    </div>
    <div class="grey--text text--darken-4 py-3 pa-4 text-break">
      <v-btn
        class="br-20 text-body-2 text-break text-wrap"
        depressed
        large
        color="primaryDash"
        dark
        @click="$emit('novoParticipante')"
        >ADICIONAR</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style lang="scss" scoped>
.desktopItem {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  align-items: center;
  background-color: rgba(55, 68, 126, 0.1);
  border-radius: 5px;
  padding: 6px;
  margin: 8px;
  border: 1px solid rgba(55, 68, 126, 0.1);
  .textAdd {
    padding-left: 20px;
    color: #37447e;
    font-weight: 400;
  }

  &.mobile {
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
    align-items: center;
    margin: 0px;
    padding: 6px;
    gap: 2px;
    .textAdd {
      padding: 10px 0px;
    }
  }
}
</style>
