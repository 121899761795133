<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="550px"
    transition="dialog-transition"
  >
    <v-card>
      <div class="pa-3">
        <div class="d-grid grid-col-1-auto pa-1 px-2">
          <div
            class="text-left font-weight-bold text-h6 primaryDash--text pa-1"
          >
            Novo depósito
          </div>
          <div class="1">
            <v-btn text @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="form pa-4">
          <div class="text-center">
            <div>
              <v-text-field
                outlined
                dense
                v-model="include.nome"
                label="Nome completo"
              ></v-text-field>
            </div>
          </div>
          <div class="text-center">
            <div>
              <v-text-field
                outlined
                dense
                v-model="include.cpfCnpj"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                label="CPF/CNPJ"
              ></v-text-field>
            </div>
          </div>
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-menu
                  v-model="menuData"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :nudge-top="60"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Data do vencimento"
                      :value="formatDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    lang="pt-br"
                    locale="pt-br"
                    v-model="include.dataVencimento"
                    @input="menuData = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="text-center">
                <v-text-field
                  outlined
                  v-model.lazy="includeValor"
                  v-money="$root.money"
                  prefix="R$"
                  id="valorParaPagar"
                  label="Valor"
                  dense
                />
              </div>
            </v-col>
          </v-row>
          <div class="text-center">
            <div>
              <v-text-field
                outlined
                v-model="include.descricao"
                label="Descrição do depósito (motivo e/ou arrecadação)"
                dense
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="text-right">
          <v-btn
            color="primaryDash"
            class="white--text"
            depressed
            :loading="loading"
            @click="criarDeposito()"
            >Criar depósito</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import serviceDeposito from "@/middleware/depositos";
import { validaCNPJ, validateCPF } from "@/tools";
import moment from "moment";
export default {
  data() {
    return {
      dialog: true,
      menuData: false,
      loading: false,
      validBoleto: [
        value => !!value || "Valor necessario",
        value =>
          value >= this.valorParcelaMinima ||
          "O valor não pode ser menor que o minimo"
      ],
      includeValor: 0,
      include: {
        cpfCnpj: "",
        nome: "",
        dataVencimento: "",
        descricao: "",
        turmaId: "",
        valor: 0
      }
    };
  },
  mounted() {
    this.include.turmaId = this.$store.getters["aluno/getTurmaAtiva"];
  },
  computed: {
    formatDate() {
      if (this.include.dataVencimento)
        return moment(this.include.dataVencimento, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      else return null;
    }
  },
  methods: {
    tratamentoValor() {
      let ValorInclude = this.includeValor;
      if (ValorInclude.indexOf(".") !== -1) {
        ValorInclude = ValorInclude.replaceAll(".", "");
      }
      if (ValorInclude.indexOf(",") !== -1) {
        ValorInclude = ValorInclude.replaceAll(",", ".");
      }
      this.include.valor = Number(ValorInclude);
      if (this.include.valor < 0.01) {
        this.$root.errorModal("Valor não pode ser menor que zero");
        this.loading = false;
        return false;
      }
      return true;
    },
    validarETratarData() {
      let dataVencimento = this.include.dataVencimento;
      let validate = this.$root.validaData(dataVencimento, "YYYY-MM-DD");
      if (!validate) {
        this.$root.errorModal("Data de vencimento está inválida");
        this.loading = false;
        return false;
      }
      let hoje = moment();
      let dataFinal = moment(dataVencimento, "YYYY-MM-DD");
      let comparativo = dataFinal.isSameOrAfter(hoje, "d");
      if (!comparativo) {
        this.$root.errorModal(
          "Data do vencimento não pode ser menor que o dia atual"
        );
        this.loading = false;
        return false;
      }
      return true;
    },
    tratamentoCPF_CNPJ() {
      console.log(this.include.cpfCnpj.length);
      if (this.include.cpfCnpj.length == 14) {
        let resp = validateCPF(this.include.cpfCnpj);
        if (!resp) {
          this.$root.errorModal("CPF inválido");
          return false;
        } else {
          return true;
        }
      } else if (this.include.cpfCnpj.length == 18) {
        let resp = validaCNPJ(this.include.cpfCnpj);
        if (!resp) {
          this.$root.errorModal("CNPJ inválido");
          return false;
        } else {
          return true;
        }
      } else {
        this.$root.errorModal("CPF/CNPJ inválido");
        return false;
      }
    },
    criarDeposito() {
      this.loading = true;
      if (!this.include.nome || this.include.nome.length < 3) {
        this.$root.errorModal("Nome muito pequeno, coloque o nome completo");
        return;
      }
      if (!this.tratamentoCPF_CNPJ()) return;
      if (!this.tratamentoValor()) return;
      if (!this.validarETratarData()) return;

      serviceDeposito
        .novoDeposito(this.include)
        .then(() => {
          this.$root.infoModal("Depósito gerado com sucesso");
          this.$emit("update");
          this.$emit("close");
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style></style>
