<template>
  <v-container fluid>
    <div class="text-h6 primaryDash--text py-3">
      Dashboard
    </div>

    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="8">
        <v-card class="visaoGeral rounded-lg ma-1 mr-1 mr-sm-2 lowShadow">
          <div class="headerDash">
            <div
              class="text-subtitle-2 primaryDash--text font-weight-bold text-left"
            >
              Visão Geral
            </div>
            <div></div>
            <div class="textColorDash--text ">
              <span class="text-body-2">
                Participantes ativos:
              </span>
              <span class="text-body-2 font-weight-bold">
                {{ visaoGeral.alunos }}
              </span>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="pa-5">
            <v-row justify="center">
              <v-col cols="12" sm="6" md="6" lg="6">
                <div class="d-grid grid-col-auto-1">
                  <div class="text-center">
                    <v-avatar color="rgba(115, 103, 240, 0.12)">
                      <v-icon color="#7367F0">mdi-trending-up</v-icon>
                    </v-avatar>
                  </div>
                  <div class="textColorDash--text  pl-2">
                    <div class="text-body-2 font-weight-bold">
                      {{ visaoGeral.arrecadado | currency }}
                    </div>
                    <div class="text-body-2">
                      Total contribuições
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <div class="d-grid grid-col-auto-1">
                  <div class="text-center">
                    <v-avatar color="rgba(115, 103, 240, 0.12)">
                      <v-icon color="#7367F0">mdi-piggy-bank-outline</v-icon>
                    </v-avatar>
                  </div>
                  <div class="textColorDash--text  pl-2">
                    <div class="text-body-2 font-weight-bold">
                      {{ visaoGeral.totalDeposito | currency }}
                    </div>
                    <div class="text-body-2">
                      Total depositado
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <div class="d-grid grid-col-auto-1">
                  <div class="text-center">
                    <v-avatar color="rgba(234, 84, 85, 0.12)">
                      <v-icon color="#EA5455">mdi-trending-down</v-icon>
                    </v-avatar>
                  </div>
                  <div class="textColorDash--text  pl-2">
                    <div class="text-body-2 font-weight-bold">
                      {{ visaoGeral.totalGasto | currency }}
                    </div>
                    <div class="text-body-2">
                      Total gasto
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <div class="d-grid grid-col-auto-1">
                  <div class="text-center">
                    <v-avatar color="rgba(40, 199, 111, 0.12)">
                      <v-icon color="#28C76F">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </div>
                  <div class="textColorDash--text pl-2">
                    <div class="text-body-2 font-weight-bold">
                      {{ visaoGeral.saldoAtual | currency }}
                    </div>
                    <div class="text-body-2">
                      Saldo atual
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card class="visaoGeral rounded-lg ma-1 mt-3 mr-1 mr-sm-2 lowShadow">
          <div class="text-subtitle-2 primaryDash--text font-weight-bold pa-3">
            <v-row>
              <v-col cols="12" sm="6">
                Meta de Contribuição
              </v-col>
              <v-col cols="12" sm="6">
                <div class="d-grid grid-col-auto-1 text-center justify-center">
                  <div class="pa-2">
                    Filtro:
                  </div>
                  <v-select
                    :items="listSelectYears"
                    v-model="valorDoFiltroDeContribuicao"
                    :value="valorInicialFiltroMeta"
                    solo
                    dense
                    hide-details
                    @change="carregaDashboard"
                    v-if="listSelectYears.length > 0"
                  >
                    <template v-slot:item="{ item }" style="z-index: 22">
                      <div
                        class="d-grid grid-col-auto-1 pa-1 text-center justify-center prioridade30"
                      >
                        <div class="pl-2">
                          <v-icon size="23" color="black"
                            >mdi-calendar-range</v-icon
                          >
                        </div>
                        <div class="text-body-2 font-weight-bold pt-1">
                          {{ item }}
                        </div>
                      </div>
                      <v-row justify="center" align="center">
                        <v-col cols="3"></v-col>
                        <v-col cols="9"></v-col>
                      </v-row>
                    </template>
                  </v-select>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <div class="graphic">
            <apexchart
              width="100%"
              height="200px"
              type="bar"
              :options="metaContribuicoes.options"
              :series="metaContribuicoes.series"
            ></apexchart>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="rounded-lg ma-1 mt-3 mt-sm-1 lowShadow">
          <div class="text-subtitle-2 primaryDash--text font-weight-bold pa-3">
            Data do Evento
          </div>
          <v-divider></v-divider>
          <div
            class="text-h5 font-weight-bold text-center py-5  secondaryDash--text"
            v-if="getDataFormatura"
          >
            {{ getDataFormatura | dataFormate }}
          </div>
          <div
            v-else
            class="py-5 text-subtitle-2 text-center secondaryDash--text"
          >
            Data não informada
          </div>
        </v-card>
        <!-- // ref ADT-503
        <v-card
          class="topcontribuintes rounded-lg ma-1 mt-3 lowShadow"
          
        >
          <div class="text-subtitle-2 primaryDash--text font-weight-bold pa-3">
            Top 10 Contribuintes
          </div>
          <v-divider></v-divider>
          <div class="pa-5">
            <div v-if="top10users.length > 0">
              <v-row
                justify="center"
                align="center"
                v-for="(aluno, index) in top10users"
                :key="index"
              >
                <v-col sm="4">
                  <v-badge
                    color="secondaryDash"
                    bordered
                    left
                    overlap
                    :content="index + 1 + 'º'"
                  >
                    <v-avatar>
                      <v-img
                        :src="
                          aluno.fotoUrl.length > 3
                            ? aluno.fotoUrl
                            : '@/assets/sem-foto-perfil.png'
                        "
                      />
                    </v-avatar>
                  </v-badge>
                </v-col>
                <v-col
                  cols="8"
                  class="text-body-2 text-truncate text-left text-sm-center"
                >
                  {{ aluno.nome }} {{ aluno.sobreNome }}
                </v-col>
              </v-row>
            </div>
            <div v-else class="text-body-1 grey--text text--darken-2">
              Sem contribuições
            </div>
          </div>
        </v-card>
        -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import serviceTurma from "@/middleware/turmaController";
import serviceRelatorios from "@/middleware/relatorios";
import moment from "moment";
//extratoMovimentacao
export default {
  data() {
    return {
      top10users: [],
      listSelectYears: [],
      valorInicialFiltroMeta: "",
      valorDoFiltroDeContribuicao: "",
      visaoGeral: {
        alunos: 0,
        arrecadado: 0,
        totalDeposito: 0,
        totalGasto: 0,
        saldoAtual: 0
      },
      metaContribuicoes: {
        options: {
          colors: ["#9B51E0", "#E2E8F0"],
          dataLabels: {
            style: {
              fontSize: "10px"
            }
          },
          chart: {
            stackType: "100%",
            stacked: true,
            id: "vuechart-example",
            type: "bar",
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              chart: {
                selection: {
                  enabled: true
                }
              }
            }
          },
          xaxis: {
            categories: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez"
            ]
          },
          yaxis: {}
        },
        series: [
          {
            name: "Contribuição",
            data: []
          },
          {
            name: "Meta Restante",
            data: []
          }
        ]
      }
    };
  },
  beforeMount() {
    if (!this.$store.getters["aluno/userIsAdmin"]) {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] != "ADMIN") {
        this.$router.push("/aluno/dashboard/pagamento-parcelas");
      }
    }

    this.$nextTick().then(() => {
      this.valorInicialFiltroMeta = Number(moment().format("YYYY"));
    });
  },
  computed: {
    // ref ADT-503
    /*
    getTypeEvent() {
      return this.$store.getters["aluno/getTurma"];
    },*/
    getDataFormatura() {
      return this.$store.getters["aluno/getTurma"].dataFormatura;
    }
  },
  methods: {
    abrirExtrato() {
      serviceRelatorios.extratoMovimentacao().then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split(";")[1].split("=")[1]
        );
        document.body.appendChild(link);
        link.click();
        this.$root.infoModal("Download do PDF concluído!");
      });
    },
    mountDataClean() {
      this.top10users = [];
      this.metaContribuicoes.series = [
        {
          name: "Contribuição",
          data: []
        },
        {
          name: "Meta Restante",
          data: []
        }
      ];
      this.mountFiltrosDeAno();
      this.dataFormatura = this.getDataFormatura;
      this.visaoGeral.alunos = 0;
      this.visaoGeral.arrecadado = 0;
      this.visaoGeral.totalDeposito = 0;
      this.visaoGeral.totalGasto = 0;
      this.visaoGeral.saldoAtual = 0;
    },
    mountFiltrosDeAno() {
      let arraySpread = [];
      let i = 1;
      for (i; i < 15; i++) {
        //Fith
        if (i == 1) {
          let pay1 = moment().format("yyyy");
          let out1 = Number(pay1);
          arraySpread.push(out1);
        }
        let payload = moment()
          .subtract(i, "y")
          .format("yyyy");
        let output = Number(payload);
        arraySpread.push(output);
      }
      this.listSelectYears = arraySpread;
      this.valorInicialFiltroMeta = Number(moment().format("yyyy"));
      this.valorDoFiltroDeContribuicao = Number(moment().format("yyyy"));
    },
    tratamentoDeDadosRecebidos(resp) {
      this.top10users = resp.data.alunos;
      let contrib = resp.data.meta.contribuicao;
      let metaRest = resp.data.meta.metaRestante;
      this.metaContribuicoes.series = [contrib, metaRest];
      this.dataFormatura = this.getDataFormatura;
      this.visaoGeral.alunos = resp.data.qtdAlunos;
      this.visaoGeral.arrecadado = resp.data.totalArrecadado;
      this.visaoGeral.totalGasto = resp.data.totalGasto;
      this.visaoGeral.totalDeposito = resp.data.totalDeposito;
      this.visaoGeral.saldoAtual = resp.data.saldoAtual;
    },
    carregaDashboard() {
      //this.loadingData = true;
      if (!this.$store.getters["aluno/userIsAdmin"]) {
        let turmaId = this.$store.getters["aluno/getTurmaAtiva"];
        if (!turmaId) return false;
      }

      serviceTurma
        .loadDashboard(this.valorDoFiltroDeContribuicao)
        .then(resp => {
          this.tratamentoDeDadosRecebidos(resp);
        })
        .catch(err => {
          this.$root.errorModal(err);
          console.log(err);
        });
    }
  },
  mounted() {
    this.mountDataClean();
    this.carregaDashboard();
  }
};
</script>

<style lang="scss" scoped>
.topContribuintes {
  max-height: 400px;
  overflow-y: auto;
}
.headerDash {
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
</style>
