<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="true"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <div id="headerModalEditEnd" class="text-center">
          <v-row justify="center" align="center">
            <v-col cols="9" class="text-left">
              <div
                class="text-subtitle-1 text-sm-h6 font-weight-bold dashPrimary--text"
              >
                Editando Endereço
              </div>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-avatar size="30" @click="close()">
                <v-icon color="silver">mdi-close</v-icon>
              </v-avatar>
            </v-col>
          </v-row>
        </div>
        <div class="pa-2">
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="CEPSearch"
                label="Buscar pelo CEP"
                id="searchCEP"
                v-mask="'#####-###'"
                :loading="loadingCEP"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                color="secondary"
                @click="searchCep()"
                :loading="loadingCEP"
                class="mt-n10 mt-sm-0"
              >
                BUSCAR CEP
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :items="listaEstados"
                label="Estado *"
                v-model="itemEstado"
                return-object
                item-text="nome"
                item-value="uf"
                @change="changeEstado()"
                :loading="loadingCEP"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="listaCidades"
                label="Cidade * "
                v-model="enderecoModificado.cidadeNome"
                item-text="descricao"
                item-value="descricao"
                :loading="loadingCidades || loadingCEP"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Bairro"
                id="id"
                :loading="loadingCEP"
                v-model="enderecoModificado.bairro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Logradouro"
                id="id"
                :loading="loadingCEP"
                v-model="enderecoModificado.logradouro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Número"
                id="id"
                v-model="enderecoModificado.numero"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Complemento"
                id="id"
                v-model="enderecoModificado.complemento"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="pa-0 text-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="12">
                <v-btn block color="primary" outlined @click="close()">
                  Descartar
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-btn
                  color="primary"
                  :loading="loadingCEP"
                  @click="save()"
                  block
                >
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceLocalidade from "@/middleware/localidadeController";
export default {
  props: ["endereco"],
  data() {
    return {
      enderecoModificado: {
        bairro: "",
        cep: "",
        cidadeIbge: "",
        cidadeNome: "",
        complemento: "",
        estado: "",
        logradouro: "",
        numero: ""
      },
      CEPSearch: "",
      dialog: true,
      loadingCidades: false,
      itemEstado: {},
      listaEstados: [],
      listaCidades: [],
      loadingCEP: false
    };
  },
  beforeMount() {
    this.buscaEstado();
  },
  mounted() {
    this.enderecoModificado = { ...this.endereco };
    this.CEPSearch = this.enderecoModificado.cep;
  },
  watch: {
    CEPSearch: function(cep) {
      this.incluirNovoCEP(cep);
    }
  },
  methods: {
    incluirNovoCEP(cep) {
      if (cep) {
        if (cep.length == 9) {
          let cepTratado = cep.replace("-", "");
          this.enderecoModificado.cep = cepTratado;
        } else {
          this.enderecoModificado.cep = cep;
        }
      } else {
        this.enderecoModificado.cep = cep;
      }
    },
    processarDadosDoCEP(dados) {
      this.enderecoModificado.bairro = dados.bairro;
      this.enderecoModificado.cidadeIbge = dados.ibge;
      this.enderecoModificado.estado = dados.uf;
      let objEstado = this.listaEstados.find(item => item.uf == dados.uf);
      this.itemEstado = objEstado;
      this.changeEstado();
      this.enderecoModificado.cidadeNome = dados.localidade;
      this.enderecoModificado.complemento = dados.complemento;
      this.enderecoModificado.logradouro = dados.logradouro;
    },
    searchCep() {
      this.loadingCEP = true;
      serviceLocalidade
        .getCep(this.enderecoModificado.cep)
        .then(resp => {
          this.processarDadosDoCEP(resp.data);
          this.loadingCEP = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data.message);
          this.loadingCEP = false;
        });
    },
    changeEstado() {
      this.loadingCidades = true;
      let ESTADO = this.itemEstado;
      this.enderecoModificado.estado = ESTADO.uf;
      this.buscaCidade(ESTADO.id);
    },
    buscaEstado() {
      serviceLocalidade.getEstado().then(resp => {
        this.listaEstados = resp.data;
        let resultado = this.listaEstados.find(item => {
          if (this.enderecoModificado.estado == item.uf) {
            this.enderecoModificado.estado = item;
            return true;
          }
        });
        this.itemEstado = resultado;
        this.changeEstado();
      });
    },
    buscaCidade(uf) {
      serviceLocalidade
        .getCidadeForEstado(uf)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loadingCidades = false;
        });
    },
    close() {
      this.$emit("close");
    },
    ValueValid(valor, minimoValor) {
      if (!valor) return false;
      else if (valor == null) return false;
      else if (valor == undefined) return false;
      else if (valor.length < minimoValor) return false;
      else {
        return true;
      }
    },
    filtrosDeEndereco() {
      let verifyBairro = this.ValueValid(this.enderecoModificado.bairro, 3);
      let verifyLogradouro = this.ValueValid(
        this.enderecoModificado.logradouro,
        3
      );
      let verifyCidade = this.ValueValid(this.enderecoModificado.cidadeNome, 2);
      let verifyUF = this.ValueValid(this.enderecoModificado.estado, 2);
      let verifyCEP = this.ValueValid(this.enderecoModificado.cep, 8);
      let verifyNumero = this.ValueValid(this.enderecoModificado.numero, 1);
      if (verifyBairro == false) {
        this.$root.errorModal("Bairro está incompleto");
        return false;
      }
      if (verifyLogradouro == false) {
        this.$root.errorModal("Logradouro está incompleto");
        return false;
      }
      if (verifyCidade == false) {
        this.$root.errorModal("Cidade está incompleto");
        return false;
      }
      if (verifyUF == false) {
        this.$root.errorModal("UF está incompleto");
        return false;
      }
      if (verifyCEP == false) {
        this.$root.errorModal("CEP está incompleto");
        return false;
      }
      if (verifyNumero == false) {
        this.$root.errorModal(
          "Numero deve conter algo, caso não tenha número coloque S/N"
        );
        return false;
      }
      return true;
    },
    save() {
      let verificacoes = this.filtrosDeEndereco();
      if (!verificacoes) return false;
      this.$emit("save", this.enderecoModificado);
    }
  }
};
</script>

<style></style>
