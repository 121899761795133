<template>
  <div class="pa-4 pa-sm-8" v-if="GET_PERMISSAO == 'ADMIN'">
    <div class="primaryDash--text text-h6 pa-2">
      Você pode convidar os integrantes através desse link:
    </div>
    <div class="text-center">
      <v-row class="mx-auto">
        <v-col cols="12" sm="8">
          <v-text-field
            readonly
            :value="getLink"
            color="grey"
            id="codigoTurmaCopy"
            hide-details
            class="text-body-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            class="my-3 btnTextColorCustom"
            color="rgba(114, 117, 255, 0.15)"
            depressed
            @click="copiarLink()"
            >Copiar link</v-btn
          >
        </v-col>
      </v-row>
      <div class="text-left px-4 text-body-1">
        <strong>Restrito</strong> Somente as pessoas adicionadas podem abrir com
        este link.
      </div>
    </div>
  </div>
</template>

<script>
import { URL_APP } from "@/constants/base";
export default {
  methods: {
    copiarLink() {
      let copyTextarea = document.querySelector("#codigoTurmaCopy");
      copyTextarea.focus();
      copyTextarea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$root.infoModal("Copiado com sucesso!");
        } else {
          this.$root.errorModal("Não foi possível copiar");
        }
      } catch (err) {
        this.$root.errorModal("Não foi possível copiar");
        console.log(err);
      }
    }
  },
  computed: {
    GET_PERMISSAO() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    },
    getLink() {
      let conviteCODE = this.$store.getters["aluno/getTurma"].codigoConvite;
      return `${URL_APP}/site/cadastro/${conviteCODE}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.btnTextColorCustom {
  color: #7275ff !important;
}
</style>
