var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{staticClass:"mx-auto pa-5"},[_c('v-row',{staticClass:"pa-1"},[_c('v-col',{staticClass:"title",attrs:{"cols":"9"}},[_vm._v(" Novo item ")]),_c('v-col',{staticClass:"close text-right",attrs:{"cols":"3"}},[_c('v-icon',{on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"containInputs pa-0"},[_c('div',{staticClass:"pa-3 text-center"},[_c('v-select',{attrs:{"items":_vm.storeItens,"item-text":"nome","return-object":"","label":"Selecione o item desejado *"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.include)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"pa-0 text-center"},[_c('v-checkbox',{staticClass:"text-center",attrs:{"label":"Calcular pela quantidade de formandos"},model:{value:(_vm.include.calcularPorFormando),callback:function ($$v) {_vm.$set(_vm.include, "calcularPorFormando", $$v)},expression:"include.calcularPorFormando"}})],1),(_vm.include.calcularPorFormando)?_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Quantidade de Alunos ")]),_c('div',{staticClass:"text-h4 primary--text"},[_vm._v(" "+_vm._s(_vm.quantidadeDeAlunos)+" ")])]):_vm._e(),_c('div',{staticClass:"pa-2 py-3"},[_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
                locale: 'pt-Pt',
                currency: null,
                valueAsInteger: false,
                distractionFree: false,
                precision: 2,
                autoDecimalMode: true,
                valueRange: { min: 0 },
                allowNegative: false
              }),expression:"{\n                locale: 'pt-Pt',\n                currency: null,\n                valueAsInteger: false,\n                distractionFree: false,\n                precision: 2,\n                autoDecimalMode: true,\n                valueRange: { min: 0 },\n                allowNegative: false\n              }"}],attrs:{"name":"ValorUnit","prefix":"R$","label":"Valor Unitário","outlined":"","hide-details":""},model:{value:(_vm.valorUnitario),callback:function ($$v) {_vm.valorUnitario=$$v},expression:"valorUnitario"}})],1),_c('div',{staticClass:"pa-2 py-3"},[_c('v-text-field',{attrs:{"outlined":"","label":"Quantidade do item selecionado","type":"number","hide-details":""},model:{value:(_vm.include.qtdItem),callback:function ($$v) {_vm.$set(_vm.include, "qtdItem", $$v)},expression:"include.qtdItem"}})],1),_c('div',{staticClass:"px-2 text-center py-3"},[_c('div',{staticClass:"font-weight-bold py-1"},[_vm._v(" Descrição ")]),_c('div',{staticClass:"text-body-2 font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("existeConteudo")(_vm.include.descricao))+" ")])]),_c('div',{staticClass:"pa-2"}),_c('div',{staticClass:"text-center py-5"},[_c('v-divider'),_c('div',{staticClass:"grey--text text--darken-1 text-h6 font-weight-light"},[_vm._v(" Valor total do item ")]),_c('div',{staticClass:"total text-h5 font-weight-bold",class:{
                'red--text': _vm.include.valor < 0.01,
                'primary--text': _vm.include.valor > 0.0
              }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.include.valor))+" ")]),_c('v-divider')],1),_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.include.valor < 0.01},on:{"click":function($event){return _vm.concluir()}}},[_vm._v("Adicionar item")])],1)]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }