<template>
  <v-container class="mt-5">
    <div class="item" :key="index" v-for="(item, index) in getLista">
      <v-card class="pa-2 px-3 ma-1 mt-2 elevation-0" outlined>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="8">
            <div
              class="pb-1 text-subtitle-2 grey--text text--darken-1 font-weight-light text-center text-sm-left"
            >
              {{ item.dataCriacao | dataFormate }}
              <div class="d-inline-block mr-5" v-if="item.novaMsg">
                <v-badge color="primary" class="ml-2" top dot></v-badge>
              </div>
            </div>
            <div class="text-center text-sm-left">
              <div
                class="text-subtitle-2 font-weight-bold d-block d-sm-inline-block"
              >
                {{ item.titulo }}
              </div>
              <div
                class="text-subtitle-2 grey--text text--light-1 mx-2 d-block d-sm-inline-block"
              >
                {{ item.setor }} - {{ item.status | statusNominal }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="text-center text-sm-right">
              <v-btn @click="abrirItem(item)" depressed color="dark"
                >Abrir</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: ["listaDeSolicitacoes"],
  methods: {
    abrirItem(item) {
      this.$emit("abrirITEM", item);
    }
  },
  filters: {
    statusNominal(val) {
      if (val == "EM_ANDAMENTO") {
        return "Em andamento";
      } else if (val == "CONCLUIDO") {
        return "Concluído";
      } else if (val == "CANCELADO") {
        return "Cancelado";
      } else if (val == "PENDENTE") {
        return "Pendente";
      } else {
        return val;
      }
    },
    dataFormate(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "undefined";
      }
    }
  },
  computed: {
    getLista() {
      return this.listaDeSolicitacoes;
    }
  }
};
</script>

<style></style>
