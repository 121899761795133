<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-4">
        <div>
          <div class="header d-grid grid-col-1-auto">
            <div class="pa-2 font-weight-bold">
              Anexos da Solicitação
            </div>
            <div>
              <v-btn text @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="pa-2">
            <v-row justify="center" class="text-center">
              <v-col cols="6" sm="6">
                <v-btn
                  text
                  :color="pagina == 'lista' ? 'grey darken-1' : 'primaryDash'"
                  @click="pagina = 'lista'"
                  >Salvos</v-btn
                >
              </v-col>
              <v-col cols="6" sm="6">
                <v-btn
                  text
                  :color="pagina == 'novo' ? 'grey darken-1' : 'primaryDash'"
                  @click="pagina = 'novo'"
                  >Novo</v-btn
                >
              </v-col>
            </v-row>
          </div>

          <div v-if="pagina == 'novo'">
            <div class="novo pa-1 text-left">
              <div class="pa-2 font-weight-bold">
                Novo anexo:
              </div>

              <div>
                <v-text-field
                  name="name"
                  label="Nome do anexo"
                  id="anexoName"
                ></v-text-field>
              </div>
              <v-file-input
                counter
                label="Arquivo para anexo"
                id="anexoFile"
                truncate-length="15"
              ></v-file-input>

              <v-btn color="primary" @click="sendFile()">
                Enviar arquivo
              </v-btn>
            </div>
          </div>
          <div v-if="pagina == 'lista'">
            <div class="lista">
              <div class="pa-2 font-weight-normal text-body-1">
                <v-badge right color="primary">
                  <span slot="badge">{{
                    getSolicitacaoFind.anexos.length
                  }}</span>
                  <!--slot can be any component-->
                  <div>Anexos registrados</div>
                </v-badge>
              </div>
              <div class="listaArquivos d-flex flex-wrap justify-left mb-6">
                <div
                  class="ma-1"
                  v-for="(anexo, index) in getSolicitacaoFind.anexos"
                  :key="index"
                >
                  <v-card
                    max-width="150"
                    class="pa-2 text-center elevation-2 w150 text-truncate"
                  >
                    <div class="text-body-2 grey--text text--darken-1 py-1">
                      {{ anexo.dataCadastro | dataFormate }}
                    </div>
                    <div class="py-1">
                      <v-avatar
                        @click="openAnexo(anexo.url, anexo.id)"
                        size="60"
                        class="cursorPointer"
                        color="rgba(50,50,50,0.05)"
                      >
                        <v-icon>
                          mdi-link
                        </v-icon>
                      </v-avatar>
                    </div>
                    <div class="text-body-2 text-wrap">
                      {{ anexo.nome }}
                    </div>
                    <div class="py-1" v-if="getPermissao == 'ADMIN'">
                      <v-btn
                        @click="removerAnexo(anexo.id)"
                        small
                        text
                        block
                        color="red darken-2 white--text elevation-0"
                      >
                        EXCLUIR
                      </v-btn>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceSolicitacoes from "@/middleware/solicitacoesDePagamento";

export default {
  props: ["solicitacao"],
  data() {
    return {
      dialog: true,
      include: {},
      pagina: "lista",
      sample: {
        file: "",
        nome: ""
      }
    };
  },
  computed: {
    getSolicitacao() {
      return { ...this.solicitacao };
    },
    getPermissao() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    },
    getSolicitacaoFind() {
      let id = this.solicitacao.id;
      return this.$store.getters["aluno/getSolicitacaoDePagamentoById"](id);
    }
  },
  methods: {
    openAnexo(link, target) {
      window.open(link, target);
    },
    removerAnexo(idAnexo) {
      let idSolicitacao = this.solicitacao.id;
      serviceSolicitacoes
        .removerAnexo(idSolicitacao, idAnexo)
        .then(resp => {
          this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
          this.$root.infoModal(resp.data);
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    close() {
      this.$emit("close");
    },
    createFormData() {
      let makefile = document.querySelector("#anexoFile").files[0];
      let makeName = document.querySelector("#anexoName").value;
      let formData = new FormData();
      formData.append("file", makefile);
      formData.append("nome", makeName);
      this.include = formData;
    },
    sendFile() {
      this.createFormData();
      serviceSolicitacoes
        .enviarAnexo(this.solicitacao.id, this.include)
        .then(() => {
          document.querySelector("#anexoFile").value = "";
          document.querySelector("#anexoName").value = "";
          this.$root.infoModal("Enviado com sucesso");
          this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.error(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.w150 {
  width: 150px;
  max-width: 150px;
}
</style>
