<template>
  <div class="pa-4">
    <div class="text-right mt-n3 mr-n3">
      <v-btn icon color="primary" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="pa-2 text-center d-flex justify-center align-center">
      <div class="d-inline-block mr-2">
        <v-icon size="35" color="black">
          mdi-barcode
        </v-icon>
      </div>
      <div class="d-inline-block font-weight-bold text-h6">Boleto</div>
    </div>
    <div v-if="chapter == 1" class="text-center">
      <div class="pa-2 text-center">
        <div class="text-overline font-weight-normal text-uppercase">
          Data de Vencimento
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ divida.dataVencimento | getData }}
        </div>
      </div>
      <div class="pa-2">
        <div class="text-overline font-weight-normal text-uppercase">
          Valor
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ divida.valor | currency }}
        </div>
      </div>
      <div class="pa-2">
        <div class="text-overline font-weight-normal text-uppercase">
          Descrição
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ divida.descricao | existeDados }}
        </div>
      </div>
      <div
        class="py-3 text-center font-weight-normal text-body-2 grey--text text--lighten-1"
      >
        Ao gerar o boleto, você não poderá mais alterar o meio de pagamento
        desta contribuição.
      </div>
      <div class="pa-2 text-center">
        <v-btn @click="gerarBoleto()" depressed color="primary"
          >Gerar boleto</v-btn
        >
      </div>
    </div>
    <div v-if="chapter == 2">
      <div class="pa-2 text-center text-subtitle-2">
        Boleto gerado com sucesso!
      </div>
      <div class="pa-2">
        <div class="text-overline font-weight-normal text-uppercase">
          Data de Vencimento
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ boletoGerado.dataVencimento | getData }}
        </div>
      </div>
      <div class="pa-2">
        <div class="text-overline font-weight-normal text-uppercase">
          Valor
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ boletoGerado.valor | currency }}
        </div>
      </div>
      <div class="pa-2">
        <div class="text-overline font-weight-normal text-uppercase">
          Descrição
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ boletoGerado.descricao | existeDados }}
        </div>
      </div>
      <div class="pa-2">
        <div class="text-overline font-weight-normal text-uppercase">
          Código de Barras
        </div>
        <div class="text-body-2 grey--text text--darken-1">
          {{ boletoGerado.codigoBarras }}
        </div>
      </div>
      <div class="text-center py-4">
        <a
          :target="gerarURLBoleto(boletoGerado.boletoUrl)"
          :href="gerarURLBoleto(boletoGerado.boletoUrl)"
          ><v-btn color="primary">ABRIR BOLETO</v-btn></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import serviceContribuicao from "@/middleware/contribuicoes";
import serviceDeposito from "@/middleware/depositos";
import { URL_BASE } from "@/constants/base";
import { TIPO_DIVIDA_CONTRIBUICAO, TIPO_DIVIDA_DEPOSITO } from "@/constants";
import moment from "moment";

export default {
  props: {
    divida: {
      type: Object,
      required: true
    },
    tipo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chapter: 1,
      boletoGerado: {}
    };
  },
  mounted() {
    this.chapter = 1;
    this.boletoGerado = {};
  },
  filters: {
    existeDados(dados) {
      if (!dados) return "Sem registro";
      else {
        return dados;
      }
    },
    getData(dt) {
      if (!dt) return "Sem registro";
      else {
        return moment(dt).format("DD/MM/YYYY");
      }
    }
  },
  methods: {
    gerarURLBoleto(url) {
      return URL_BASE + url;
    },
    gerarBoleto() {
      if (this.tipo === TIPO_DIVIDA_CONTRIBUICAO) {
        serviceContribuicao
          .gerarBoletoDaContribuicao(this.divida.contribuicaoId)
          .then(resp => {
            this.$emit("update");
            this.chapter = 2;
            this.boletoGerado = resp.data;
          })
          .catch(err => {
            this.$root.errorModal(err.data);
            this.$emit("close");
          });
        return;
      }
      if (this.tipo === TIPO_DIVIDA_DEPOSITO) {
        serviceDeposito
          .gerarBoletoDaDeposito(this.divida.depositoId)
          .then(resp => {
            this.$emit("update");
            this.chapter = 2;
            this.boletoGerado = resp.data;
          })
          .catch(err => {
            this.$root.errorModal(err.data);
            this.$emit("close");
          });
        return;
      }
    }
  },
  computed: {
    boletoURL() {
      return URL_BASE + this.divida.boletoUrl;
    }
  }
};
</script>

<style></style>
