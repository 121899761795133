<template>
  <div>
    <v-dialog v-model="modal" persistent max-width="500px">
      <v-card class="mx-auto pa-5">
        <v-row class="pa-1">
          <v-col cols="9" class="title">
            Novo item
          </v-col>
          <v-col cols="3" class="close text-right">
            <v-icon @click="close()">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div class="containInputs pa-0">
          <div class="pa-3 text-center">
            <v-select
              :items="storeItens"
              v-model="selected"
              item-text="nome"
              return-object
              label="Selecione o item desejado *"
            ></v-select>
          </div>
          <div class="text-center" v-if="include">
            <div class="pa-0 text-center">
              <v-checkbox
                label="Calcular pela quantidade de formandos"
                v-model="include.calcularPorFormando"
                class="text-center"
              ></v-checkbox>
            </div>
            <div class="pa-2" v-if="include.calcularPorFormando">
              <div class="font-weight-bold">
                Quantidade de Alunos
              </div>
              <div class="text-h4 primary--text">
                {{ quantidadeDeAlunos }}
              </div>
            </div>
            <div class="pa-2 py-3">
              <v-text-field
                name="ValorUnit"
                prefix="R$"
                label="Valor Unitário"
                v-model="valorUnitario"
                outlined
                v-currency="{
                  locale: 'pt-Pt',
                  currency: null,
                  valueAsInteger: false,
                  distractionFree: false,
                  precision: 2,
                  autoDecimalMode: true,
                  valueRange: { min: 0 },
                  allowNegative: false
                }"
                hide-details
              ></v-text-field>
            </div>
            <div class="pa-2 py-3">
              <v-text-field
                outlined
                label="Quantidade do item selecionado"
                v-model="include.qtdItem"
                type="number"
                hide-details
              ></v-text-field>
            </div>
            <div class="px-2 text-center py-3">
              <div class="font-weight-bold py-1">
                Descrição
              </div>
              <div class="text-body-2 font-weight-normal">
                {{ include.descricao | existeConteudo }}
              </div>
            </div>
            <div class="pa-2"></div>
            <div class="text-center py-5">
              <v-divider></v-divider>
              <div class="grey--text text--darken-1 text-h6 font-weight-light">
                Valor total do item
              </div>
              <div
                class="total text-h5 font-weight-bold"
                :class="{
                  'red--text': include.valor < 0.01,
                  'primary--text': include.valor > 0.0
                }"
              >
                {{ include.valor | currency }}
              </div>
              <v-divider></v-divider>
            </div>
            <div class="pa-2">
              <v-btn
                color="primary"
                @click="concluir()"
                :disabled="include.valor < 0.01"
                >Adicionar item</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import controllerService from "@/middleware/tiposController";
export default {
  props: ["quantidadeDeAlunos"],
  data() {
    return {
      modal: true,
      storeItens: [],
      selected: "",
      valorUnitario: 0,
      include: false
    };
  },
  filters: {
    existeConteudo(valor) {
      if (valor) {
        return valor;
      } else {
        return "SEM DESCRIÇÃO";
      }
    }
  },
  methods: {
    concluir() {
      if (this.include.valor < 0.01) {
        this.$root.errorModal(
          "Valor total do item não pode ser zero ou negativo"
        );
        return false;
      }
      let itemResponse = { ...this.include };
      this.$emit("addItem", itemResponse);
    },
    close() {
      this.$emit("close");
    },
    convertStringToNumber(valorStr) {
      if (valorStr !== null && valorStr !== undefined && valorStr !== "") {
        let over1 = String(valorStr);
        let over2 = over1.replace("/./g", "");
        let over3 = over2.replace(",", ".");
        let over4 = over3.replace(/[^0-9.-]+/g, "");
        return Number(over4);
      }
      return 0;
    },
    calculaValores() {
      let numb_valorUnit = this.convertStringToNumber(this.valorUnitario);
      let numb_qtdItem = Number(this.include.qtdItem);
      let numb_qtdAluno = this.convertStringToNumber(this.quantidadeDeAlunos);
      if (this.include.calcularPorFormando == true) {
        this.include.valor = numb_valorUnit * numb_qtdItem * numb_qtdAluno;
      } else {
        this.include.valor = numb_valorUnit * numb_qtdItem;
      }
    }
  },
  watch: {
    "include.qtdItem"() {
      this.calculaValores();
    },
    "include.calcularPorFormando"() {
      this.calculaValores();
    },
    valorUnitario() {
      this.calculaValores();
    },
    selected: function() {
      this.include = this.selected;
      this.valorUnitario = this.selected.valor;
    }
  },
  beforeMount() {
    // chama API para puxar icons/itens
    controllerService.getItensCompleto().then(resp => {
      this.storeItens = resp.data; // <!--{id, imgUrl, nome, sigla, valor}-->
    });
  }
};
</script>

<style lang="scss" scoped>
$inputColor: #7275ff;
input {
  border-color: $inputColor;
}
</style>
