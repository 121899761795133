<template>
  <div>
    <!-- ALTERAR O CLOSE QUANDO FOR CAMPANHA -->

    <div v-if="etapaPix !== getTypeEtapa.FCZ && etapa !== 3">
      <div class="text-right">
        <v-btn icon color="primary" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="text-center font-weight-bold text-h5 mt-n5">
        <v-row>
          <v-col class="text-right">
            <img src="@/assets/pix-logo.svg" width="30" />
          </v-col>
          <v-col class="text-left">Pix</v-col>
        </v-row>
      </div>
    </div>

    <div v-if="etapa == 1">
      <div v-if="etapaPix === getTypeEtapa.INICIAL">
        <div class="pa-2 text-center">
          <div class="text-overline grey--text text--darken-3">
            valor do pix
          </div>
          <div class="text-h4 primary--text">
            {{ divida.valor | currency }}
          </div>
        </div>
        <div class="pa-2 text-center">
          <div class="text-overline font-weight-normal text-uppercase">
            Descrição
          </div>
          <div class="text-body-2 grey--text text--darken-1">
            {{ divida.descricao }}
          </div>
        </div>
        <div
          class="py-3 text-center font-weight-normal text-body-2 grey--text text--lighten-1"
        >
          <div v-if="metodo && metodo == 'regerar'">
            Você pode gerar novamente o pix para efetuar o pagamento desta
            contribuição
          </div>
          <div v-else>
            Ao gerar o pix, você não poderá mais alterar o meio de pagamento
            desta contribuição.
          </div>
        </div>
        <div class="text-center py-1">
          <v-btn
            @click="inicializarProcessoPix()"
            color="teal lighten-1"
            class="white--text"
            depressed
            >Gerar Pix</v-btn
          >
        </div>
      </div>
      <div v-if="etapaPix === getTypeEtapa.FCZ">
        <vOfferFCZInPayment
          @confirmedOption="gerarPixContribuicao"
          @declinedOption="gerarPixContribuicao"
          @close="$emit('close')"
          contribuicao="divida"
        />
      </div>
      <div
        v-if="etapaPix === getTypeEtapa.AGUARDANDO"
        class="contentAguardando"
      >
        <div class="py-4">Aguarde estamos gerando seu PIX</div>
        <div class="my-3 ">
          <v-icon size="35" class="animate-spin">mdi-loading</v-icon>
        </div>
      </div>
    </div>

    <div v-if="etapa == 2" class="pa-4">
      <vPixView
        @pagamentoEfetuado="pagamentoEfetuadoComSucesso"
        :isCustom="true"
        @close="$emit('close')"
        :divida="recebido"
        :tipo="tipo"
      />
    </div>

    <div v-if="etapa == 3" class="pa-4">
      <vPagamentoSucesso @close="$emit('close')" :responsePix="responsePix" />
    </div>
  </div>
</template>

<script>
import serviceContribuicoes from "@/middleware/contribuicoes";
import serviceDeposito from "@/middleware/depositos";
import moment from "moment";
import vPixView from "./infoPixContent.vue";
import vPagamentoSucesso from "./pagamentoPixSucesso.vue";
import vOfferFCZInPayment from "./offerFCZ/offerFCZInPayment.vue";
import { TIPO_DIVIDA_CONTRIBUICAO, TIPO_DIVIDA_DEPOSITO } from "@/constants";

export default {
  props: {
    divida: {
      type: Object,
      required: true
    },
    tipo: {
      type: String,
      required: true
    },
    metodo: {
      type: String,
      required: false
    }
  },
  components: {
    vPixView,
    vPagamentoSucesso,
    vOfferFCZInPayment: vOfferFCZInPayment
  },
  data() {
    return {
      dividaPayload: null,
      responsePix: null,
      etapaPix: "INICIAL",
      timerPix: null,
      countPix: 0,
      etapa: 1,
      recebido: {},
      TYPE_ETAPA: {
        INICIAL: "INICIAL",
        FCZ: "FCZ",
        AGUARDANDO: "AGUARDANDO",
        GERADO: "GERADO"
      }
    };
  },
  mounted() {
    this.etapa = 1;
    this.dividaPayload = { ...this.divida };
  },
  beforeDestroy() {
    clearTimeout(this.timerPix);
  },
  filters: {
    dataFormate(val) {
      if (val) return moment(val).format("DD/MM/YYYY");
      else return "S/REGISTRO";
    }
  },
  computed: {
    getTypeEtapa() {
      return this.TYPE_ETAPA;
    }
  },
  methods: {
    pagamentoEfetuadoComSucesso(response) {
      this.responsePix = response;
      this.etapa = 3;
    },
    /**
     * verifica até 4x depois de 5 segundos se o pix foi gerado
     */
    handlerContribuicao() {
      this.countPix += 1;
      if (this.countPix === 4) {
        this.$root.errorModal(
          "Não foi possivel gerar o Pix, tente novamente mais tarde."
        );
        this.$emit("close");
        return false;
      }
      this.timerPix = setTimeout(() => {
        serviceContribuicoes
          .getStatusPix(this.dividaPayload.contribuicaoId)
          .then(resp => {
            console.log(resp);
            if (resp.data.pixId && resp.data.qrcodeBase64) {
              clearTimeout(this.timerPix);
              this.recebido = resp.data;
              this.etapa = 2;
            } else {
              this.handlerContribuicao();
            }
          })
          .catch(err => {
            console.error(err);
            clearTimeout(this.timerPix);
            this.$root.errorModal(err.data);
            this.$emit("close");
          });
      }, 5000);
    },
    handlerDeposito() {
      this.countPix += 1;
      if (this.countPix === 4) {
        this.$root.errorModal(
          "Não foi possivel gerar o Pix, tente novamente mais tarde."
        );
        this.$emit("close");
        return false;
      }
      this.timerPix = setTimeout(() => {
        serviceDeposito
          .getStatusPix(this.dividaPayload.depositoId)
          .then(resp => {
            console.log(resp);
            if (resp.data.pixId && resp.data.qrcodeBase64) {
              clearTimeout(this.timerPix);
              this.recebido = resp.data;
              this.etapa = 2;
            } else {
              this.handlerDeposito();
            }
          })
          .catch(err => {
            clearTimeout(this.timerPix);
            this.$root.errorModal(err.data);
            this.$emit("close");
          });
      }, 5000);
    },
    inicializarProcessoPix() {
      if (this.tipo === TIPO_DIVIDA_CONTRIBUICAO) {
        this.$emit("updateStateCustomStyle", true);
        this.$emit("updateStateSizeModal", "large");
        this.etapaPix = this.TYPE_ETAPA.FCZ;
      } else if (this.tipo === TIPO_DIVIDA_DEPOSITO) {
        this.gerarPixDeposito();
      } else {
        this.$root.errorModal("Tipo de divida inválido");
        this.$emit("close");
      }
    },
    gerarPixContribuicao(promotion) {
      console.log("gerarPixContribuicao", promotion);
      let payload = {
        contribuicaoId: this.dividaPayload.contribuicaoId
      };

      if (promotion) {
        payload.id = promotion.id;
      }

      this.$emit("updateStateCustomStyle", false);
      this.$emit("updateStateSizeModal", "normal");
      this.etapaPix = this.TYPE_ETAPA.AGUARDANDO;

      serviceContribuicoes
        .gerarPix(payload)
        .then(resp => {
          console.log("gerarPixContribuicao", resp.data);

          //this.$emit("update");
          this.handlerContribuicao();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.$emit("close");
        });
    },
    gerarPixDeposito() {
      serviceDeposito
        .gerarPix(this.dividaPayload.depositoId)
        .then(resp => {
          console.log("gerarPixDeposito", resp.data);
          this.etapaPix = this.TYPE_ETAPA.AGUARDANDO;
          this.$emit("update");
          this.handlerDeposito();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.$emit("close");
        });
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
.contentAguardando {
  text-align: center;
}
</style>
