var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permissaoDeAcessoAoMenu)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"white":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,false,2524810596)},[_c('v-list',{staticClass:"pa-0 ma-0 "},[_c('v-list-item',{staticClass:"itemMenu ma-0 pa-0"},[_c('v-list-item-action',{staticClass:"ma-0 pa-0 "},[_c('v-list',{staticClass:"pa-0 ma-0"},[(_vm.getPermissaoUserAuth == 'ADMIN')?_c('v-list-item',{on:{"click":function($event){return _vm.cancelarContrato()}}},[_vm._v(" Cancelar contrato ")]):_vm._e(),(_vm.getPermissaoUserAuth == 'ADMIN')?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('abrirContribuicoes')}}},[_vm._v(" Contribuições ")]):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.reenviarEmail()}}},[_vm._v(" Reenviar convite ")]),(
                _vm.getParticipanteRole !== 'COMISSAO' &&
                  _vm.getParticipanteRole !== 'ADMIN' &&
                  _vm.getParticipanteConvite == 'AUTORIZADO'
              )?_c('v-list-item',{on:{"click":function($event){return _vm.setComissao()}}},[_vm._v("Adicionar comissário")]):_vm._e(),(
                _vm.getParticipanteRole !== 'COMISSAO' &&
                  _vm.getParticipanteRole !== 'ADMIN' &&
                  _vm.getParticipanteConvite == 'AUTORIZADO'
              )?_c('v-list-item',{on:{"click":function($event){return _vm.setAdmin()}}},[_vm._v("Adicionar administrador")]):_vm._e(),(_vm.getParticipanteRole == 'COMISSAO')?_c('v-list-item',{on:{"click":function($event){return _vm.removeComissao()}}},[_vm._v("Remover comissário")]):_vm._e(),(_vm.getParticipanteConvite == 'AUTORIZADO')?_c('v-list-item',{on:{"click":function($event){return _vm.desativarAluno()}}},[_vm._v("Desativar usuário")]):_vm._e(),(_vm.permissaoParaExcluirParticipante)?_c('v-list-item',{on:{"click":function($event){return _vm.excluirParticipante()}}},[_vm._v(" Excluir Participante ")]):_vm._e(),(
                _vm.getParticipanteConvite == 'NAO_AUTORIZADO' ||
                  _vm.getParticipanteConvite == 'PENDENTE'
              )?_c('v-list-item',{on:{"click":function($event){return _vm.aceitarAluno()}}},[_vm._v("Ativar Participante")]):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }