<template>
  <div>
    <v-row justify="center" align="center" class="px-4">
      <v-col cols="12" md="6">
        <vTitulo>Plano de Arrecadação</vTitulo>
      </v-col>
      <v-col cols="12" md="6" class="text-center text-md-right text-center">
        <v-btn
          color="primary"
          style="width: 100px;"
          large
          elevation="0"
          class="font-weight-bold mx-2 ma-1"
          v-if="autorizacaoParaEdicao"
          @click="salvaPlano()"
          >SALVAR</v-btn
        >
      </v-col>
    </v-row>
    <div id="editavel" v-if="autorizacaoParaEdicao">
      <v-row justify="center" align="center">
        <v-col cols="12" v-if="this.handleErros.length > 0" id="errosList">
          <v-card
            class="pa-sm-8 pa-6 ma-2 ma-sm-6 mt-0 rounded-lg white redshadow"
          >
            <div>
              <div class="pb-2 red--text text--darken-3 font-weight-black">
                Erros encontrados:
              </div>
              <ul>
                <li
                  :key="index"
                  v-for="(item, index) in this.handleErros"
                  class="red--text text--darken-3 py-2 font-weight-normal"
                >
                  {{ item.nome }}
                </li>
              </ul>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-sm-8 pa-6 ma-2 ma-sm-6 mt-0 lowShadow rounded-lg">
            <div>
              <h2>
                Base de calculo
              </h2>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model.number="include.qtdAluno"
                    type="number"
                    name="quantidadeAlunos"
                    :rules="ruleQtdAluno"
                    label="Número de participantes"
                    :error="include.qtdAluno < 1"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <vDateMonth
                    label="Inícia do pagamento"
                    @handleDate="setDateMouthInitialPag"
                    :defaultValue="DefaultInicialPag"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <vDateMonth
                    label="Fim do pagamento"
                    @handleDate="setDateMouthFinalPag"
                    :defaultValue="DefaultFinalPag"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="text-center"></div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    :items="tiposDeCalculo"
                    v-model="include.tipoCalculo"
                    label="Calculo a ser usado"
                    item-text="descricao"
                    item-value="id"
                    @change="scrollToEnd"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-formata-moeda="include.valorParcela"
                    v-if="include.tipoCalculo == 'VALOR_PARCELA'"
                    name="valorParcela"
                    prefix="R$ "
                    v-model.lazy="include.valorParcela"
                    v-money="$root.money"
                    label="Valor da parcela"
                  ></v-text-field>
                  <v-text-field
                    v-formata-moeda="include.valorTotal"
                    v-if="include.tipoCalculo == 'VALOR_TOTAL'"
                    v-model.lazy="include.valorTotal"
                    v-money="$root.money"
                    name="ValorTotal"
                    label="Valor total"
                    prefix="R$ "
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12">
          <div
            class="blocos text-center px-2 px-sm-6 d-flex justify-center align-center flex-row flex-wrap"
          >
            <v-card
              class="d-flex flex-column ma-2 pa-3 cardShadow lowShadow rounded-lg justify-center align-center"
              min-height="130px"
              max-width="250px"
              width="250px"
            >
              <div class="subTitulo">Valor total do evento</div>
              <div class="valor text-h6 font-weight-bold">
                {{ getValorTotal | currency }}
              </div>
            </v-card>
            <v-card
              class="d-flex flex-column ma-2 pa-3 cardShadow lowShadow rounded-lg justify-center align-center"
              min-height="130px"
              max-width="250px"
              width="250px"
            >
              <div class="subTitulo">Valor total por participantes</div>
              <div class="valor text-h6 font-weight-bold">
                {{ include.valorCotaFormando | currency }}
              </div>
            </v-card>
            <v-card
              class="d-flex flex-column ma-2 pa-3 cardShadow lowShadow rounded-lg justify-center align-center"
              min-height="130px"
              max-width="250px"
              width="250px"
            >
              <div class="subTitulo">Total de participantes</div>
              <div class="valor text-h6 font-weight-bold">
                {{ include.qtdAluno }}
              </div>
            </v-card>
            <v-card
              class="d-flex flex-column ma-2 pa-3 cardShadow lowShadow rounded-lg justify-center align-center"
              min-height="130px"
              max-width="250px"
              width="250px"
            >
              <div class="subTitulo">
                Valor da parcela por participantes
              </div>
              <div class="valor text-h6 font-weight-bold">
                {{ getValorParcela | currency }}
              </div>
            </v-card>
            <v-card
              class="d-flex flex-column ma-2 pa-3 cardShadow lowShadow rounded-lg justify-center align-center"
              min-height="130px"
              max-width="250px"
              width="250px"
            >
              <div class="subTitulo">
                Quantidade de parcelas
              </div>
              <div class="valor text-h6 font-weight-bold">
                {{ include.qtdParcela }}
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <div class="ui" v-if="include.tipoCalculo == 'DESCRICAO_ITENS'">
        <v-row>
          <v-col>
            <v-card
              class="mx-3 my-3 mx-sm-5 pa-3 px-7 cardShadow lowShadow rounded-lg"
              justify="center"
              align="center"
            >
              <v-row justify="center" align-content="center" align="center">
                <v-col cols="10">
                  <v-text-field
                    placeholder="Buscar item"
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchText"
                    width="200"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <div id="onlyView" v-if="!autorizacaoParaEdicao">
      <v-card class="ma-3 lowShadow" id="basecalculo">
        <div>
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>
                Base de calculo
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="text-center">
              <div class="font-weight-bold">Participantes</div>
              <div class="text-h4 font-weight-light primaryDash--text py-1">
                {{ getPlano.qtdAluno }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <div class="font-weight-bold">
                Calculo usado
              </div>
              <div class="text-h4 font-weight-light primaryDash--text py-1">
                {{ getCalculoForId(getPlano.tipoCalculo) }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="text-center">
              <div class="font-weight-bold">Inicio de pagamento</div>
              <div class="text-h4 font-weight-light primaryDash--text py-1">
                {{ this.include.mesInicio }}/{{ this.include.anoInicio }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <div class="font-weight-bold">Fim de pagamento</div>
              <div class="text-h4 font-weight-light primaryDash--text py-1">
                {{ this.include.mesFormatura }}/{{ this.include.anoFormatura }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <div class="blocos text-center px-3 mt-4">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" md="3" class="text-center">
            <v-card
              class="bloco pa-3 mx-auto cardShadow lowShadow rounded-lg"
              min-height="130px"
              width="100%"
            >
              <div class="subTitulo">Valor total do evento</div>
              <div class="valor text-h6 font-weight-bold">
                {{ getPlano.valorTotal | currency }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="containCard">
            <v-card
              class="bloco pa-3 mx-auto cardShadow lowShadow rounded-lg"
              min-height="130px"
              width="100%"
            >
              <div class="subTitulo">Valor total por participantes</div>
              <div class="valor text-h6 font-weight-bold">
                {{ getPlano.valorCotaFormando | currency }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="containCard">
            <v-card
              class="bloco pa-3 mx-auto cardShadow lowShadow rounded-lg"
              min-height="130px"
              width="100%"
            >
              <div class="subTitulo">Total de participantes</div>
              <div class="valor text-h6 font-weight-bold">
                {{ getPlano.qtdAluno }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="containCard">
            <v-card
              class="bloco pa-3 mx-auto lowShadow rounded-lg"
              min-height="130px"
              width="100%"
            >
              <div class="subTitulo">
                Valor da parcela por participantes
              </div>
              <div class="valor text-h6 font-weight-bold">
                {{ getPlano.valorParcela | currency }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      v-if="include.tipoCalculo == 'DESCRICAO_ITENS'"
      class="mt-3 pa-2 mx-2 mx-sm-4"
    >
      <v-row justify="center" align="center" class="pa-2">
        <v-col cols="8" sm="6">
          <div class="text-h6 secondary--text text-center text-sm-left">
            Lista de itens
          </div>
        </v-col>
        <v-col cols="4" sm="6" class="text-sm-right text-left">
          <v-btn
            color="primary"
            text
            class="pa-4 mx-auto secondary--text elevation-0"
            @click="novoItem()"
            v-if="autorizacaoParaEdicao"
          >
            <v-icon size="35">mdi-plus-box</v-icon>
            <span class="d-none d-sm-block">
              Novo Item
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <div class="mt-2">
        <v-card
          v-for="(item, index) in getItens"
          :key="index"
          class="pa-4 mt-1 border-grey white elevation-0"
        >
          <v-row justify="center" align="center">
            <v-col cols="7" sm="8" md="9">
              <div class="font-weight-bold text-subtitle-1">
                {{ item.nome }}
              </div>
              <div class="font-weight-light text-body-1">
                {{ item.valor | currency }}
              </div>
            </v-col>
            <v-col cols="5" sm="4" md="2">
              <div
                v-if="!autorizacaoParaEdicao"
                class="text-right text-body-2 grey--text text--lighten-1"
              >
                <v-avatar
                  style="background-color: rgba(194, 207, 224, 0.2);"
                  class="mr-1"
                >
                  <v-icon
                    small
                    color="#C2CFE0"
                    class="primary--text"
                    title="Excluir usuário"
                    @click="infoDescricao(item)"
                    >mdi-information-variant</v-icon
                  >
                </v-avatar>
              </div>
              <v-row
                justify="center"
                align="center"
                v-if="autorizacaoParaEdicao"
              >
                <v-col cols="4">
                  <v-avatar
                    style="background-color: rgba(194, 207, 224, 0.2);"
                    class="mr-1"
                  >
                    <v-icon
                      small
                      color="#C2CFE0"
                      class="primary--text"
                      title="Excluir usuário"
                      @click="infoDescricao(item)"
                      >mdi-information-variant</v-icon
                    >
                  </v-avatar>
                </v-col>
                <v-col cols="4">
                  <v-avatar
                    class="mr-1"
                    style="background-color: rgba(194, 207, 224, 0.2);"
                  >
                    <v-icon
                      small
                      class=" primary--text"
                      color="#C2CFE0"
                      title="Editar dados do usuário"
                      @click="OpenEditItem(item)"
                      >mdi-pencil</v-icon
                    >
                  </v-avatar>
                </v-col>
                <v-col cols="4">
                  <v-avatar
                    style="background-color: rgba(194, 207, 224, 0.2);"
                    class="mr-1"
                  >
                    <v-icon
                      small
                      color="#C2CFE0"
                      class="primary--text"
                      title="Excluir usuário"
                      @click="removeItem(item)"
                      >mdi-delete</v-icon
                    >
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>

    <div id="espacamento" class="pa-5"></div>

    <newItem
      v-if="dialogNovoItem == true"
      @addItem="addItemInList"
      @close="closeNewItem"
      :quantidadeDeAlunos="include.qtdAluno"
    />
    <vRemoveDialog
      @closeRemove="CloseRemoveItem"
      :item="itemSelected"
      v-if="dialogRemove == true"
    />
    <vEditItem
      v-if="dialogEditItem == true"
      @close="CloseEditItem"
      @concluirEdit="alterarItemDaLista"
      :item="itemSelected"
      :quantidadeDeAlunos="include.qtdAluno"
    />
    <vModalConfirm
      :dados="{ cota: include.valorCotaFormando }"
      @confirmar="handleConfirmado"
      @cancelar="handleCancelar"
      v-if="dialogConfirm == true"
    />

    <!-- scroll -->
    <div ref="scroll"></div>
  </div>
</template>

<script>
import tipoService from "@/middleware/tiposController";
import serviceTurma from "@/middleware/turmaController";
import newItem from "./novoItem.vue";
//import vItemBloco from "./vItemBloco";
//import vItemBlocoAdd from "./vItemBlocoAdd";
import vRemoveDialog from "./removerDialog";
import vEditItem from "./editItem";
import moment from "moment";
import vModalConfirm from "./confirmPlan";

export default {
  components: {
    newItem,
    //vItemBloco,
    //vItemBlocoAdd,
    vRemoveDialog,
    vEditItem,
    vModalConfirm
  },
  data() {
    return {
      toggleViewer: false,
      existePlano: false,
      dataInicialDefinida: false,
      dataFinalDefinida: false,
      itemSelected: "",
      dialogRemove: false,
      dialogEditItem: false,
      dialogNovoItem: false,
      dialogConfirm: false,
      DefaultInicialPag: "",
      DefaultFinalPag: "",
      debounceRevisor: false,
      ruleQtdAluno: [
        value => !!value || "Required.",
        value => value >= 1 || "No minímo 1 participante"
      ],
      include: {
        statusPlano: null,
        anoFormatura: null,
        anoInicio: null,
        id: 0,
        itens: [],
        mesFormatura: null,
        mesInicio: null,
        qtdAluno: 0,
        qtdParcela: 0,
        tipoCalculo: "VALOR_PARCELA",
        valorCotaFormando: 0,
        valorParcela: 0,
        valorTotal: 0
      },
      searchText: "",
      toggleList: true,
      tiposDeCalculo: [],
      headers: [
        { text: "Nome", value: "nome" },
        { text: "Valor", value: "valor" },
        { text: "Ações", value: "acoes", sortable: false, align: "end" }
      ],
      handleErros: []
    };
  },
  beforeMount() {
    this.buscaTiposCalc();
    this.recuperaPlano();
  },
  mounted() {
    this.buscaTiposCalc();
    this.recuperaPlano();
    this.tratamentoDeDataParaPagamento();
    /*this.DefaultInicialPag = `${moment().get("year")}-${moment().get("month") +
      1}`;
    this.DefaultFinalPag = `${moment().get("year") + 1}-${moment().get(
      "month"
    ) + 1}`;
    */
  },
  computed: {
    autorizacaoParaEdicao() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] == "ADMIN";
    },
    getPlano() {
      return this.$store.getters["aluno/getPlano"];
    },
    CompCalculoUsado() {
      if (!this.tiposDeCalculo) return "Undefined";
      let respostaObj = this.tiposDeCalculo.find(
        pro => pro.id == this.include.tipoCalculo
      );
      if (respostaObj) {
        return respostaObj.descricao;
      } else {
        return respostaObj;
      }
    },
    getItens() {
      return this.include.itens.filter(item => {
        let NameUp = item.nome.toUpperCase();
        let SearchUp = this.searchText.toUpperCase();
        return NameUp.indexOf(SearchUp) != -1;
      });
    },
    getCalcSelect() {
      return this.include.tipoCalculo;
    },
    getValorTotal() {
      return this.$root.converteValor(this.include.valorTotal);
    },
    getValorParcela() {
      return this.$root.converteValor(this.include.valorParcela);
    },
    getQtdAluno() {
      return this.include.qtdAluno;
    },
    getAnoForm() {
      return this.include.anoFormatura;
    },
    getMesForm() {
      return this.include.anoFormatura;
    },
    getAnoInicial() {
      return this.include.anoInicio;
    },
    getMesInicial() {
      return this.include.mesInicio;
    }
  },
  watch: {
    /*getPlano(vard) {
      this.include = vard;
    },*/
    getQtdAluno() {
      //if (qtd < 1) this.include.qtdAluno = 1; revisando
      this.revisarValores();
    },
    getAnoForm() {
      this.revisarValores();
    },
    getMesForm() {
      this.revisarValores();
    },
    getAnoInicial() {
      this.revisarValores();
    },
    getMesInicial() {
      this.revisarValores();
    },
    getCalcSelect() {
      this.trocar_tipo_de_calculo(); // TODO: refatorando
    },
    getValorTotal() {
      this.revisarValores();
    },
    getValorParcela() {
      this.revisarValores();
    }
  },
  methods: {
    infoDescricao(item) {
      if (item && item.descricao) {
        this.$root.infoModal(item.descricao);
      } else {
        this.$root.infoModal("Este item não tem descrição");
      }
    },
    tratamentoDeDataParaPagamento() {
      let tratadoMesInicio = String(
        this.include.mesInicio < 10
          ? "0" + this.include.mesInicio
          : this.include.mesInicio
      );
      let tratadoMesFormatura = String(
        this.include.mesFormatura < 10
          ? "0" + this.include.mesFormatura
          : this.include.mesFormatura
      );
      this.DefaultInicialPag = this.include.anoInicio + "-" + tratadoMesInicio;
      this.DefaultFinalPag =
        this.include.anoFormatura + "-" + tratadoMesFormatura;
    },
    getCalculoForId(idCalc) {
      if (!idCalc) return "Undefined";
      let respostaObj = this.tiposDeCalculo.find(pro => pro.id == idCalc);
      if (respostaObj) {
        return respostaObj.descricao;
      } else {
        return respostaObj;
      }
    },
    recuperaPlano() {
      this.include = { ...this.$store.getters["aluno/getPlano"] };
      this.DefaultInicialPag = "";
      this.DefaultFinalPag = "";
    },
    buscaTiposCalc() {
      tipoService
        .getTiposCalculo()
        .then(resp => {
          this.tiposDeCalculo = resp.data;
          // ok
        })
        .catch(err => {
          this.$root.errorModal(err);
        });
    },
    handleCancelar() {
      this.dialogConfirm = false;
    },
    handleConfirmado() {
      let turmaID = this.$store.getters["aluno/getTurmaAtiva"];
      this.include.valorTotal = this.$root.converteValor(
        this.include.valorTotal
      );
      this.include.valorParcela = this.$root.converteValor(
        this.include.valorParcela
      );
      this.include.valorCotaFormando = this.$root.converteValor(
        this.include.valorCotaFormando
      );
      let dadosDoPlano = this.include;
      serviceTurma
        .setPlanoTurma(turmaID, dadosDoPlano)
        .then(() => {
          serviceTurma
            .finalizaPlano(turmaID)
            .then(resp2 => {
              this.$root.sucessoModal(resp2.data);
              this.$store.dispatch("aluno/REQUEST_TURMA");
              this.$store.dispatch("aluno/REQUEST_PLANO");
            })
            .catch(err => {
              this.$root.errorModal(`Erro: ${err.data}`);
            });
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.error(err);
        });
      this.dialogConfirm = false;
    },
    finalizarPlano() {
      this.verificadoresInclude();
      if (this.handleErros.length > 0) {
        this.$root.errorModal(
          "Verifique os erros encontrados antes de finalizar o plano atual"
        );
        return false;
      } else {
        this.dialogConfirm = true;
      }
    },
    verificadoresInclude() {
      this.handleErros = [];
      if (this.include.tipoCalculo == "DESCRICAO_ITENS") {
        if (this.include.itens.length < 1) {
          this.handleErros.push({
            nome: "O plano por itens, é obrigatório adicionar no mínimo 1 item"
          });
        }
      }
      if (this.include.qtdAluno < 1) {
        //this.$root.errorModal("Quantidade de participantes não pode ser nulo ou negativo");
        this.handleErros.push({
          nome: "Quantidade de participantes não pode ser vazio ou negativo"
        });
      }
      if (this.include.valorParcela < 0.01) {
        //this.$root.errorModal("Valor da parcela não pode ser nulo ou negativo");
        this.handleErros.push({
          nome: "Valor da parcela não pode ser vazio ou negativo"
        });
      }
      if (this.include.valorCotaFormando < 0.01) {
        //this.$root.errorModal("Valor total por aluno não pode ser nulo ou negativo");
        this.handleErros.push({
          nome: "Valor total por participante não pode ser vazio ou negativo"
        });
      }
      return this.handleErros.length == 0;
    },
    salvaPlano() {
      let turmaID = this.$store.getters["aluno/getTurma"].id;
      let opt = this.verificadoresInclude();
      if (!opt) {
        this.$root.errorModal("Verifique os erros encontrados");
        return false;
      }
      this.include.valorTotal = this.$root.converteValor(
        this.include.valorTotal
      );
      this.include.valorParcela = this.$root.converteValor(
        this.include.valorParcela
      );
      this.include.valorCotaFormando = this.$root.converteValor(
        this.include.valorCotaFormando
      );
      serviceTurma
        .setPlanoTurma(turmaID, this.include)
        .then(() => {
          this.$store
            .dispatch("aluno/REQUEST_TURMA")
            .then(() => {
              this.$store
                .dispatch("aluno/REQUEST_PLANO")
                .then(() => {
                  this.$root.sucessoModal("Dados salvos com sucesso.");
                })
                .catch(() => {
                  this.$root.errorModal(
                    "Erro ao carregar plano, atualize a pagina"
                  );
                });
            })
            .catch(() => {
              this.$root.errorModal(
                "Erro ao carregar turma, atualize a pagina"
              );
            });
        })
        .catch(err => {
          window.console.error(err);
          this.$root.errorModal(err.data);
        });
    },
    trocar_tipo_de_calculo() {
      let selecao = this.getCalcSelect;
      this.debounceRevisor = true;
      this.include.valorParcela = 0;
      this.include.valorTotal = 0;
      this.include.valorCotaFormando = 0;
      if (selecao == "DESCRICAO_ITENS") {
        this.calcular_itens();
      } else if (selecao == "VALOR_TOTAL") {
        this.calcular_valorTotal();
      } else if (selecao == "VALOR_PARCELA") {
        this.calcular_parcela();
      }
      this.diffMeses();
    },
    revisarValores() {
      if (this.debounceRevisor) return false;
      this.debounceRevisor = true;
      let selecao = this.getCalcSelect;
      if (selecao == "DESCRICAO_ITENS") {
        this.calcular_itens();
      } else if (selecao == "VALOR_TOTAL") {
        this.calcular_valorTotal();
      } else if (selecao == "VALOR_PARCELA") {
        this.calcular_parcela();
      }
      this.diffMeses();
    },
    calcular_valorTotal() {
      this.callResultItens(this.getValorTotal);
    },
    calcular_parcela() {
      let meses = this.diffMeses();
      this.include.valorCotaFormando =
        Number(this.getValorParcela) * Number(meses);
      this.include.valorTotal =
        Number(this.include.valorCotaFormando) * Number(this.include.qtdAluno);

      this.debounceRevisor = false;
    },
    callResultItens(calc_soma) {
      let meses = this.diffMeses();
      this.include.qtdMeses = meses;
      //this.include.valorTotal = Number(calc_soma);
      this.include.valorCotaFormando =
        Number(calc_soma) / Number(this.include.qtdAluno);
      this.include.valorParcela = Number(
        this.include.valorCotaFormando / meses
      );
      this.debounceRevisor = false;
    },
    calcular_itens() {
      var calc_soma = 0;
      if (this.include.itens.length == 0) {
        this.include.valorTotal = 0;
        this.include.valorParcela = 0;
        this.include.valorCotaFormando = 0;
      } else {
        calc_soma = this.include.itens
          .map(element => Number(element.valor))
          .reduce((CalcSoma, valor) => CalcSoma + valor);
        this.include.valorTotal = calc_soma;
      }
      this.callResultItens(calc_soma);
    },
    diffMeses() {
      let calculoMesFormatura;
      let calculoAnoFormatura;
      let calculoMesInicio;
      let calculoAnoInicio;

      if (this.include.mesFormatura && this.include.anoFormatura) {
        calculoMesFormatura = this.include.mesFormatura;
        calculoAnoFormatura = this.include.anoFormatura;
      } else if (!this.include.mesFormatura || !this.include.anoFormatura) {
        calculoMesFormatura = moment().get("month");
        calculoAnoFormatura = moment().get("year");
      }

      if (this.include.mesInicio && this.include.anoInicio) {
        calculoMesInicio = this.include.mesInicio;
        calculoAnoInicio = this.include.anoInicio;
      } else if (!this.include.mesInicio || !this.include.anoInicio) {
        calculoMesInicio = moment().get("month");
        calculoAnoInicio = moment().get("year");
      }

      let calculoDataFormatura =
        calculoAnoFormatura + "/" + calculoMesFormatura;
      let calculoDataInicio = calculoAnoInicio + "/" + calculoMesInicio;
      var qtdMeses = moment(calculoDataFormatura, "YYYY/MM").diff(
        moment(calculoDataInicio, "YYYY/MM"),
        "months",
        true
      );
      this.include.qtdParcela = qtdMeses;
      return qtdMeses;
    },
    removeItem(item) {
      let ar = this.include.itens.find(it => it.id == item.id);
      let indexQuery = this.include.itens.indexOf(ar);
      if (indexQuery != -1) {
        this.include.itens.splice(indexQuery, 1);
      }
      this.calcular_itens();
      this.dialogRemove = false;
    },
    alterarItemDaLista(item) {
      this.include.itens.find((it, index) => {
        if (item.id == it.id) {
          this.include.itens.splice(index, 1, item);
        }
      });
      this.calcular_itens();
      this.dialogEditItem = false;
    },
    addItemInList(item) {
      this.include.itens.push(item);
      this.calcular_itens();
      this.dialogNovoItem = false;
    },
    setDateMouthFinalPag(valor) {
      let dateFinalChanged = valor;
      if (
        this.include.anoInicio == 0 ||
        this.include.anoInicio == null ||
        this.include.anoInicio == undefined
      ) {
        this.$root.errorModal(
          "É necessario adicionar uma data inicial primeiro"
        );
        return false;
      }
      if (this.dataInicialDefinida) {
        let dataInicial = `${this.include.anoInicio}-${this.include.mesInicio}`;
        // refatorando é depois isAfter()
        let x = moment(dateFinalChanged).isAfter(dataInicial);
        if (x) {
          let tmp = dateFinalChanged.split("-");
          this.include.mesFormatura = tmp[1];
          this.include.anoFormatura = tmp[0];
          this.dataFinalDefinida = true;
          this.diffMeses();
        } else {
          this.$root.errorModal(
            "A data para o pagamento final deve ser maior que a inicial."
          );
          return false;
        }
      } else {
        this.$root.errorModal("Defina a data inicial primeiro");
        return false;
      }
    },
    setDateMouthInitialPag(valor) {
      let dateInicialChanged = valor;
      let dataFinal = `${this.include.anoFormatura}-${this.include.mesFormatura}`;
      let x = true;
      if (this.include.anoFormatura && this.include.mesFormatura) {
        x = moment(dataFinal).isAfter(dateInicialChanged); // VERIFICAR ESSA REGRA
      }

      if (x) {
        let tmp = dateInicialChanged.split("-");
        this.include.mesInicio = tmp[1];
        this.include.anoInicio = tmp[0];
        this.$root.unit(this.include.mesInicio);
        this.$root.unit(this.include.anoInicio);
        this.dataInicialDefinida = true;
      } else {
        this.$root.errorModal(
          "A data para o pagamento final deve ser maior que a inicial."
        );
      }
    },
    OpenEditItem(item) {
      this.itemSelected = item;
      this.dialogEditItem = true;
    },
    CloseEditItem() {
      this.itemSelected = "";
      this.dialogEditItem = false;
    },
    CloseRemoveItem() {
      this.itemSelected = "";
      this.dialogRemove = false;
    },
    OpenRemoveItem(item) {
      this.itemSelected = item;
      this.dialogRemove = true;
    },
    novoItem() {
      this.dialogNovoItem = true;
    },
    closeNewItem() {
      this.dialogNovoItem = false;
    },
    setToggleList(valor) {
      this.toggleList = valor;
    },
    scrollToEnd() {
      setTimeout(() => {
        var element = this.$refs.scroll;
        element.scrollIntoView();
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
.redshadow {
  box-shadow: 0px 0px 6px 2px rgba(200, 0, 0, 0.1);
}
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  text-align: center;
  align-content: center;
  align-items: center;
  align-self: center;
  .c1 {
    text-align: left;
  }
}
.roundStep {
  padding: 30px;
  background-color: #eceff5;
  color: #818181;
  border-radius: 100px;
  font-size: 120px;
  display: inline-grid;
}
.smallText {
  margin-top: 10px;
  color: #778192;
  font-size: 16px;
}
.grid {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
}
.icons {
  color: #5f5f5f;
}
.ativo {
  color: black !important;
}
.ui {
  .subTitulo {
    color: #37447e;
    font-weight: 900;
  }
}
.blocos {
  .bloco {
    //display: inline-grid;
    //grid-template-columns: 1;
    //grid-template-rows: 60px 60px;
    text-align: center;
    align-content: center;
    align-items: center;
    align-self: center;
    //max-width: 300px;
    //width: 100%;
  }
  .subTitulo {
    font-size: 15px;
    color: #6f7cb2;
    font-weight: 100;
    text-align: center;
    align-content: center;
    align-items: center;
    align-self: center;
  }
  .valor {
    text-align: center;
    color: #091133;
    font-size: 26px;
    word-wrap: break-word;
    font-family: "Ubuntu", sans-serif;
  }
}
</style>
