import axios from "axios";
export default {
  atendimentoPorID(id) {
    return axios.get(`/v1/central-atendimento/atendimento/${id}`);
  },
  meusAtendimentos() {
    return axios.get("/v1/central-atendimento/atendimentos/me");
  },
  cancelarAtendimento(id) {
    return axios.post(`/v1/central-atendimento/cancelar-atendimento/${id}`);
  },
  concluirAtendimento(id) {
    return axios.post(`/v1/central-atendimento/concluir-atendimento/${id}`);
  },
  novaMensagem(mensagem) {
    return axios.post("/v1/central-atendimento/nova-mensagem", {
      atendimentoId: mensagem.atendimentoId,
      comentario: mensagem.comentario
    });
  },
  novoAtendimento(atendimento) {
    return axios.post(`/v1/central-atendimento/novo-atendimento`, {
      descricao: atendimento.descricao,
      setor: atendimento.setor,
      titulo: atendimento.titulo
    });
  }
};
