<template>
  <div>
    <vUpdate v-if="getContentLoader" />
    <vTurmasSelect
      @onSelectEvent="selectedEvent"
      v-if="!getContentLoader && existsEvents && !hasSelectedEvent"
    />
    <div
      v-if="modoAdmin"
      style="background-color: #aa1616; color: white; padding: 10px"
    >
      Atenção modo ADMIN ativo !
    </div>
    <div
      v-if="!getContentLoader && hasEventSelectAndExists"
      class="layoutAluno"
    >
      <vHeader class="layHeader" />
      <vMenu class="layMenu" />
      <vContent class="layContent" />
    </div>
    <vNovoEvento />
    <vModalPWA
      :installPrompt="payloadInstallPrompt"
      v-if="displayModalPWA"
      @fecharModalPWA="closeModalPwa"
    />
    <div
      v-if="
        !getContentLoader &&
          hasClassUser &&
          getCustoZeroModal &&
          hasEventSelectAndExists
      "
    >
      <v-dialog
        :fullscreen="$vuetify.breakpoint.smAndDown"
        v-model="dialogFCZ"
        persistent
        max-width="800"
      >
        <v-card elevation="2" class="cardCustoZero">
          <custoZeroVue
            @close="closeModalFCZ()"
            @confirm="confirmModalFCZ()"
            :hasButton="true"
          ></custoZeroVue>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import vHeader from "./vHeader";
import vContent from "./content/index";
import vMenu from "./vMenu";
import vNovoEvento from "./global/modalNovoEvento/index.vue";
import vUpdate from "./updateLoad";
import vModalPWA from "@/components/site/modalPWA.vue";
import vTurmasSelect from "./initTurmas.vue";
import custoZeroVue from "@/components/custoZero.vue";
import ServiceCustoZero from "@/middleware/custozero";

export default {
  components: {
    vHeader,
    vContent,
    vMenu,
    vNovoEvento,
    vUpdate,
    vModalPWA,
    vTurmasSelect,
    custoZeroVue
  },
  data() {
    return {
      dialogFCZ: true,
      hasSelectedEvent: false,
      installBtn: "none",
      displayModalPWA: false,
      payloadInstallPrompt: null
    };
  },
  methods: {
    conditionalRenderFCZ() {
      return (
        !this.getContentLoader && this.hasClassUser && this.getCustoZeroModal
      );
    },
    confirmModalFCZ() {
      ServiceCustoZero.concordarCustoZero()
        .then(() => {
          this.$root.sucessoModal(
            "Parabéns você entrou no programa Formatura Custo Zero."
          );
          this.dialogFCZ = false;
          this.$store.commit("aluno/setAceiteFCZ", true);
          this.$store.commit("aluno/setExibirFCZ", false);
          this.$router.push("/aluno/dashboard/programa-custo-zero");
        })
        .catch(error => {
          console.error(error);
          this.$root.errorModal(error);
        });
    },
    closeModalFCZ() {
      this.dialogFCZ = false;
    },
    selectedEvent() {
      this.hasSelectedEvent = true;
    },
    closeModalPwa() {
      this.displayModalPWA = false;
    },
    redirectVerify() {
      if (!this.$store.getters["aluno/getContentLoader"]) {
        console.log(this.$store.getters["global/getModuleFCZ"]);
        console.log(this.$store.getters["aluno/getTurmaConfig"].enableFCZ);
        if (
          this.$store.getters["global/getModuleFCZ"] &&
          this.$store.getters["aluno/getTurmaConfig"].enableFCZ
        ) {
          console.log("TROCOU A ROTA PARA FCZ!!");
          this.$router.replace("/aluno/dashboard/programa-custo-zero");
        }
      } else {
        setTimeout(() => {
          this.redirectVerify();
        }, 500);
      }
    }
  },
  mounted() {
    this.redirectVerify();
  },
  computed: {
    modoAdmin() {
      return this.$store.getters["aluno/userIsAdmin"];
    },
    getCustoZeroModal() {
      const custozero = this.$store.getters["aluno/getUserFCZ"];
      const flagTurmaFCZ = this.$store.getters["aluno/getTurmaConfig"]
        .enableFCZ;
      const flagGlobalFCZ = this.$store.getters["global/getModuleFCZ"];
      if (flagGlobalFCZ) {
        if (!flagTurmaFCZ) {
          return false;
        }

        if (custozero.aceiteFCZ) {
          return false;
        }

        if (custozero.exibirFCZ) {
          return true;
        }
      }
      return false;
    },
    hasClassUser() {
      let turmas = this.$store.getters["aluno/getTurmas"];
      if (!turmas) return false;
      else return true;
    },
    hasEventSelectAndExists() {
      let turmas = this.$store.getters["aluno/getTurmas"];
      if (!turmas || turmas.length < 2) return true;
      else {
        if (this.hasSelectedEvent) return true;
        else return false;
      }
    },
    existsEvents() {
      if (!this.$store.getters["aluno/getTurmas"]) return false;
      else {
        return this.$store.getters["aluno/getTurmas"].length > 1;
      }
    },
    getContentLoader() {
      return this.$store.getters["aluno/getContentLoader"];
    },
    getMenuToggle() {
      return this.$store.getters["aluno/getMenuToggle"];
    }
  },
  beforeMount() {
    this.$store.dispatch("global/REQUEST_MODULES");
    this.$store.dispatch("aluno/RELOAD_ALL");

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.payloadInstallPrompt = e;
      this.installBtn = "block";
      this.displayModalPWA = true;
    });
  }
};
</script>

<style lang="scss">
* {
  font-family: "Poppins", sans-serif !important;
}
</style>
<style lang="scss" scoped>
.cardCustoZero {
  padding: 10px;
  overflow-y: auto;
  max-height: 90vh;
  margin: 0 auto;
}
.layoutAluno {
  min-height: 100vh;
  background-color: #f5f6f8;
}
</style>
