export const codeBilheteStatus = {
  NULO: null,
  NAO_PAGO: "NAO_PAGO",
  PAGO: "PAGO",
  PAGO_GERANDO_BILHETES: "PAGO_GERANDO_BILHETES"
};

export const nameBilheteStatus = {
  NULO: "Todos",
  PAGO: "Pago",
  NAO_PAGO: "Não pago",
  PAGO_GERANDO_BILHETES: "Processando pagamento"
};

export const generateArrayStatusPagamentoBilhete = hasAll => {
  let list = [
    { name: nameBilheteStatus.NAO_PAGO, code: codeBilheteStatus.NAO_PAGO },
    { name: nameBilheteStatus.PAGO, code: codeBilheteStatus.PAGO }
  ];

  if (hasAll) {
    list.unshift({
      name: nameBilheteStatus.NULO,
      code: codeBilheteStatus.NULO
    });
  }
  return list;
};
