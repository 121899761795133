<template>
  <v-avatar :size="renderSize" class="text-center justify-center">
    <v-img :src="renderImagem" class="text-center justify-center" alt="" />
  </v-avatar>
</template>
<script>
import imagemURL from "@/assets/sem-foto-perfil.png";
export default {
  props: ["size", "imagem"],
  data() {
    return {
      foto: imagemURL
    };
  },
  computed: {
    renderSize() {
      if (this.size != undefined || this.size != null) return this.size;
      else return 48;
    },
    renderImagem() {
      if (
        this.imagem == undefined ||
        this.imagem == null ||
        this.imagem == ""
      ) {
        return this.foto;
      } else {
        return this.imagem;
      }
    }
  }
};
</script>
