<template>
  <div>
    <div class=" pa-2 justify-center align-center">
      <vTitulo>Gerar parcelas</vTitulo>
    </div>
    <div class="px-5">
      <v-row class="justify-center items-center">
        <v-col cols="12" sm="6" class="text-left">
          <v-text-field
            rounded
            hide-details
            depressed
            placeholder="Procurar integrante"
            v-model="search"
            class="font-weight-medium lowShadow white px-2 py-2 mx-4 mx-sm-0"
          >
            <template v-slot:append>
              <v-icon>
                mdi-magnify
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="text-right"> </v-col>
      </v-row>
    </div>
    <!-- d-flex flex-wrap justify-space-around -->

    <div id="datatable" class="containDataTableAndMenu">
      <div class="containBtns">
        <button class="btnCustom" @click="selecionarTodos()">
          <div class="btnContentFlex" style="">
            <div class="mr-2">
              <v-icon size="14" color="black">
                mdi-checkbox-multiple-marked-outline
              </v-icon>
            </div>
            <span>
              Selecinar todos
            </span>
          </div>
        </button>
        <button class="btnCustom" @click="limparTodos()">
          <div class="btnContentFlex">
            <div class="mr-2">
              <v-icon size="14" color="black">
                mdi-trash-can-outline
              </v-icon>
            </div>
            <span>
              limpar seleção
            </span>
          </div>
        </button>
        <button
          class="btnCustom principal"
          @click="gerarParcelas()"
          :disabled="listaDeSelecionados.length == 0"
        >
          Lançar parcelas
        </button>
      </div>
      <v-data-table
        :headers="headers"
        :items="getLista"
        :items-per-page="99999"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody class="text-center">
            <tr v-for="item in items" :key="item.usuarioId">
              <td
                class="text-center"
                style="font-size: 12px; font-family: monospace;"
              >
                <v-checkbox
                  @change="checkupItem"
                  v-model="item.checked"
                ></v-checkbox>
              </td>
              <td class="text-left">
                <PerfilPhotoOrLetterInDataTable
                  :nome="item.nome"
                  :url="item.fotoUrl"
                />
              </td>
              <td class="text-left">USR-{{ item.usuarioId }}</td>
              <td class="text-left">{{ item.nome }} {{ item.sobreNome }}</td>
              <td class="text-left">{{ item.nomeFormando }}</td>
              <td class="text-left">
                {{ filterPhone(item.telefone) }}
              </td>
              <td class="text-left">
                {{ item.email | existeRegistro }}
              </td>
              <td class="text-right">
                <v-btn
                  title="Contribuições"
                  aria-label="Contribuições"
                  icon
                  @click="abrirContribuicoes(item)"
                >
                  <v-icon>mdi-text-box-search</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <vContribuicoes
      v-if="modalContribuicoes"
      @close="closeContribuicoes"
      :user="payloadUser"
    />
    <vGerarContribuicoesPorParticipante
      v-if="modalNovaContribuicao"
      @close="closeNovaContribuicao"
      :user="payloadUser"
    />
    <vGerarParcelasParaTodos
      v-if="modalGerarParcelaParaTodos"
      :users="listaDeSelecionados"
      @close="modalGerarParcelaParaTodos = false"
    />
  </div>
</template>

<script>
import serviceTurma from "@/middleware/turmaController";
import vContribuicoes from "./modalContribuicoes.vue";
import vGerarParcelasParaTodos from "./vGerarParcelasParaTodos.vue";
import { normalize } from "@/constants/stringUtils";
import vGerarContribuicoesPorParticipante from "./gerarContribuicoesPorParticipante.vue";
import PerfilPhotoOrLetterInDataTable from "@/components/user/PerfilPhotoOrLetterInDataTable.vue";

export default {
  components: {
    vContribuicoes,
    vGerarContribuicoesPorParticipante,
    vGerarParcelasParaTodos,
    PerfilPhotoOrLetterInDataTable
  },
  data() {
    return {
      menuFiltro: false,
      filtro: 6,
      headers: [
        {
          text: "Selecione",
          align: "left",
          value: "checked"
        },
        { text: "foto", value: "fotoUrl", align: "left" },
        { text: "ID", value: "usuarioId", align: "left" },
        { text: "Nome", value: "nome", align: "left" },
        { text: "Formando", value: "nomeFormando", align: "left" },
        { text: "Telefone", value: "telefone", align: "left" },
        { text: "Email", value: "email", align: "left" },
        { text: "Opções", value: "", sortable: false, align: "right" }
      ],
      listaDeSelecionados: [],
      modalGerarParcelaParaTodos: false,
      modalContribuicoes: false,
      modalNovaContribuicao: false,
      payloadUser: {},
      search: "",
      alunos: []
    };
  },
  beforeMount() {
    this.requestAlunos();
  },
  filters: {
    existeRegistro(val) {
      if (val) return val;
      else return "Sem registro";
    }
  },
  computed: {
    viewportWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      } else {
        return "230";
      }
    },
    getLista() {
      // let findString = String(this.search).toUpperCase();
      // if (findString == "" || findString == null || findString == undefined) {
      //   return this.alunos;
      // }

      let list = this.alunos.filter(item => {
        let NameUp = normalize(item.nome);
        let nomeFormandoUp = normalize(item.nomeFormando);
        let SearchUp = normalize(this.search);
        let SobreNome = normalize(item.sobreNome);
        let userId = String(item.usuarioId);
        return (
          NameUp.match(SearchUp) ||
          SobreNome.match(SearchUp) ||
          nomeFormandoUp.match(SearchUp) ||
          userId.match(SearchUp)
        );
      });

      return list.filter(item => {
        if (this.filtro == "1") return true;
        else if (this.filtro == "2") return item.permissaoTurma == "COMISSAO";
        else if (this.filtro == "3") return item.conviteStatus == "PENDENTE";
        else if (this.filtro == "4")
          return item.conviteStatus == "NAO_AUTORIZADO";
        else if (this.filtro == "5")
          return item.conviteStatus == "CONTRATO_CANCELADO";
        else if (this.filtro == "6") return item.conviteStatus == "AUTORIZADO";
      });
    }
  },
  methods: {
    selecionarTodos() {
      this.alunos.forEach(item => {
        item.checked = true;
      });
      this.checkupItem();
    },
    limparTodos() {
      this.alunos.forEach(item => {
        item.checked = false;
      });
      this.checkupItem();
    },
    checkupItem() {
      let listaAlunos = this.alunos.filter(item => {
        if (item.checked) return true;
      });
      console.log(listaAlunos);
      this.listaDeSelecionados = listaAlunos;
    },
    gerarParcelas() {
      this.modalGerarParcelaParaTodos = true;
    },
    filterPhone(phone) {
      if (!phone) return "-";
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    },
    closeNovaContribuicao() {
      this.payloadUser = {};
      this.modalNovaContribuicao = false;
    },
    novaContribuicao(item) {
      this.payloadUser = item;
      this.modalNovaContribuicao = true;
    },
    closeContribuicoes() {
      this.payloadUser = {};
      this.modalContribuicoes = false;
    },
    abrirContribuicoes(item) {
      this.payloadUser = item;
      this.modalContribuicoes = true;
    },
    requestAlunos() {
      serviceTurma
        .getMetaAlunos()
        .then(resp => {
          let payloadParticipantes = resp.data.map(item => {
            return {
              ...item,
              checked: false
            };
          });
          this.alunos = payloadParticipantes;
          this.alunos.sort((a, b) => {
            const nameA = normalize(a["nomeFormando"]);
            const nameB = normalize(b["nomeFormando"]);

            if (!nameA) {
              return 1;
            }
            if (!nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.containBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: $xs) {
    flex-direction: column;
    & > button {
      margin: 5px auto;
      width: 96%;
      text-align: center;
    }
  }
}
.containDataTableAndMenu {
  margin: 25px 22px;
  overflow-x: auto;

  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(50, 50, 50, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.btnContentFlex {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.btnCustom {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.1);
  color: rgba(50, 50, 50);
  border: 1px solid rgba(50, 50, 50, 0.2);
  font-size: 14px;
  margin-right: 10px;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.2);
  }

  &.principal {
    background-color: #37447e;
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: rgba(50, 50, 50, 0.2) !important;
    color: black;
    border: 1px solid rgba(50, 50, 50, 0);
    box-shadow: none;
  }
}
.br-20 {
  border-radius: 20px;
}
.cardCota {
  display: inline-grid;
  margin: 10px;
  background-color: white;
  width: 250px;
  min-width: 250px;
  min-height: 260px;
  border-radius: 20px;
  text-align: center;
  &.userContain {
    //grid-template-columns: 1fr;
    display: inline-block;
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
  }

  &.plusBtn {
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    vertical-align: center;
    min-height: 330px;
  }
}
.menu {
  position: absolute !important;
  margin-top: -100px;
  margin-left: 175px;
}
.descricaoADD {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding-top: 5px;
  color: #778192;
}
.dadosFinanceiros {
  color: #2f3a4a;
  font-family: Roboto !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.txtFormando {
  padding: 10px;
  color: #2f3a4a;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.containDescricao {
  padding: 10px;
  .nomecompleto {
    color: #2f3a4a;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .telefone {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    padding-top: 5px;
    color: #778192;
  }
  .email {
    padding-top: 5px;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    color: #778192;
  }
}
.totalMensalidade {
  padding: 10px 0px;
  .texto {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #6e6b7b !important;
  }
  .valor {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 23px !important;
    line-height: 34px !important;
    color: #8f85ff !important;
  }
}
</style>
