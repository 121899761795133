<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card class="pa-6">
        <div class="title py-5">
          <center>
            Tem certeza de que deseja excluir
            <span class="primary--text">{{ this.item.nome }}</span> ?
          </center>
        </div>
        <v-card-actions>
          <v-row style="text-align:center">
            <v-col>
              <v-btn class="elevation-0" color="primary" large @click="close()">
                Cancelar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn class="primary--text elevation-0" large @click="remover()">
                Excluir item
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      dialog: true
    };
  },
  methods: {
    remover() {
      this.$root.infoModal("Esta função está sendo implementada");
    },
    close() {
      this.$emit("closeRemove");
    }
  }
};
</script>

<style></style>
