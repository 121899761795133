<template>
  <div class="mx-4">
    <div class="text-center text-sm-left primaryDash--text ">
      <vTitulo class="d-inline-block">
        Relatórios do evento
      </vTitulo>
    </div>
    <v-card class="pa-4">
      <div>
        <v-select
          label="Tipo de relatório"
          v-model="modeloSelecionado"
          :items="modelosRelatorio"
          item-text="text"
          item-value="id"
        ></v-select>
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            hide-details
            type="date"
            label="Período Inicial"
            v-model="include.dataInicial"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            hide-details
            type="date"
            label="Período Final"
            v-model="include.dataFinal"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-row class="font-weight-medium">
            <v-col cols="12" sm="6">
              <div
                class="radioCustom"
                @click="include.tipoRel = 'PDF'"
                :class="{ ativo: include.tipoRel == 'PDF' }"
              >
                <input
                  class="mr-3"
                  type="radio"
                  v-model="include.tipoRel"
                  value="PDF"
                />
                PDF
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div
                class="radioCustom"
                @click="include.tipoRel = 'XLS'"
                :class="{ ativo: include.tipoRel == 'XLS' }"
              >
                <input
                  class="mr-3"
                  type="radio"
                  v-model="include.tipoRel"
                  value="XLS"
                />
                EXCEL
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-btn
            color="primaryDash"
            class="white--text"
            depressed
            :loading="loading"
            @click="gerarRelatorio()"
            >Gerar</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <div>
      <embed
        v-if="filePDF"
        id="plugin"
        :src="filePDF"
        width="100%"
        height="1000px"
        name="plugin"
        type="application/pdf"
      />
    </div>
  </div>
</template>

<script>
import serviceRelatorio from "@/middleware/relatorios";
import { formatDateEn } from "@/constants/dateUtils";
export default {
  data() {
    return {
      modelosRelatorio: [
        { id: "EXTRATO", text: "Extrato Geral" },
        { id: "EM_ABERTO", text: "Contribuições em Aberto" },
        { id: "LISTA_USUARIOS", text: "Dados dos Participantes" }
      ],
      loading: false,
      include: {
        dataInicial: null,
        dataFinal: formatDateEn(new Date()),
        tipoRel: "PDF"
      },
      modeloSelecionado: "EXTRATO",
      filePDF: null
    };
  },
  methods: {
    gerarRelatorio() {
      if (this.modeloSelecionado === "EXTRATO") {
        serviceRelatorio
          .extratoMovimentacao(this.include)
          .then(response => {
            this.callbackReport(response, null);
          })
          .catch(err => {
            this.callbackReport(null, err);
          });
        return;
      }
      if (this.modeloSelecionado === "EM_ABERTO") {
        serviceRelatorio
          .inadimplentes(this.include)
          .then(response => {
            this.callbackReport(response, null);
          })
          .catch(err => {
            this.callbackReport(null, err);
          });
        return;
      }
      if (this.modeloSelecionado === "LISTA_USUARIOS") {
        serviceRelatorio
          .listaUsuariosDaTurma(this.include)
          .then(response => {
            this.callbackReport(response, null);
          })
          .catch(err => {
            this.callbackReport(null, err);
          });
        return;
      }
      alert("Você precisa selecionar um tipo de relatório!");
    },
    callbackReport(response, err) {
      if (err) {
        this.$root.errorModal(err.data);
        return;
      }
      if (response) {
        if (this.include.tipoRel === "PDF") {
          this.filePDF = window.URL.createObjectURL(response.data);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            response.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          document.body.appendChild(link);
          link.click();
          this.$root.sucessoModal("Relatório gerado com sucesso!");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.radioCustom {
  //teste
  cursor: pointer;
  padding: 6px 12px;
  border: 1px solid rgba(150, 150, 150, 0.3);
  border-radius: 5px;
  margin: 0 2px;
  &.ativo {
    border: 1px solid rgb(10, 85, 170);
    //teste
    input {
    }
  }
}
</style>
