<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="pa-4">
      <vGerarPix
        @close="$emit('close')"
        :divida="divida"
        :tipo="tipo"
        metodo="regerar"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import vGerarPix from "./gerarPix.vue";
export default {
  props: {
    divida: {
      type: Object,
      required: true
    },
    tipo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  components: {
    vGerarPix
  }
};
</script>

<style></style>
