<template>
  <div>
    <div class="pa-5">
      <div>
        <v-autocomplete
          :items="listaCompletaInstituicoes"
          :search-input.sync="searchInstituicao"
          color="primary"
          item-text="nome"
          item-value="id"
          label="Instituição"
          placeholder="Encontre sua instituição"
          multiple
          return-object
          chips
          small-chips
          v-model="include.instituicoes"
          @change="setChangeInstituicao"
          class="text-truncate"
          disabled
        >
          <template v-slot:selection="data">
            <div>
              <v-chip>
                {{ data.item.nome }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-icon>
                <div>
                  <v-icon
                    color="rgba(50,50,50,0.2)"
                    v-text="'mdi-domain'"
                  ></v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.nome"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template slot="no-data">
            <div class="pa-2" v-if="addInst == false">
              Digite para encontrar sua instituição
            </div>
            <div class="pa-2" v-if="addInst == true">
              <small>
                Não encontramos o sua instituição, deseja adicionar:
              </small>
              <br />
              <div class="pa-1">
                <strong>{{ searchInstituicao }}</strong>
              </div>
              <v-btn
                color="primary"
                class="pa-1 ma-1"
                @click="setNovaInstituicao()"
              >
                confirmar instituição
              </v-btn>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <v-autocomplete
          :items="listaCompletaCursos"
          :search-input.sync="searchCurso"
          color="primary"
          label="Cursos"
          item-text="nome"
          item-value="id"
          placeholder="Encontre seu curso"
          return-object
          chips
          small-chips
          multiple
          v-model="include.cursos"
          @change="setChangeCurso"
          class="text-truncate"
          disabled
        >
          <template v-slot:selection="data">
            <div>
              <v-chip>
                {{ data.item.nome }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-icon>
                <div>
                  <v-icon
                    color="rgba(50,50,50,0.2)"
                    v-text="'mdi-school'"
                  ></v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.nome"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template slot="no-data">
            <div class="pa-2" v-if="addCurso == false">
              Digite para encontrar seu curso
            </div>
            <div class="pa-2" v-if="addCurso == true">
              <small>
                Não encontramos o seu curso, deseja adicionar:
              </small>
              <br />
              <div class="pa-1">
                <strong>{{ searchCurso }}</strong>
              </div>
              <v-btn color="primary" class="pa-1 ma-1" @click="setNovoCurso()">
                confirmar curso
              </v-btn>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <v-select
          :items="listaDePeriodos"
          label="Período"
          item-text="nome"
          item-value="value"
          v-model="selectedPeriodo"
          disabled
        ></v-select>
      </div>
      <div>
        <v-text-field
          label="Número aproximado de participantes"
          type="number"
          v-model.number="include.numeroFormando"
          disabled
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          label="Nome da turma"
          v-model="include.nome"
          disabled
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          name="formaturaData"
          label="Data aproximada do evento *"
          v-mask="['##/##/####']"
          hint="formato dd/mm/aaaa"
          v-model="include.dataFormatura"
          disabled
        ></v-text-field>
      </div>
      <div>
        <v-select
          :items="listaEstados"
          label="Estado *"
          v-model="include.estadoId"
          item-text="nome"
          item-value="id"
          @change="onChangeEstado"
          disabled
        ></v-select>
      </div>
      <div>
        <v-select
          :items="listaCidades"
          label="Cidade * "
          v-model="include.cidadeId"
          item-text="descricao"
          item-value="id"
          disabled
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import serviceInstituicao from "@/middleware/instituicaoController";
import serviceCursos from "@/middleware/cursoController";
import serviceLocalidade from "@/middleware/localidadeController";
import controllerTurma from "@/middleware/turmaController";
import moment from "moment";
import { URL_APP } from "@/constants/base";
import EVENTOS from "@/constants/participante/eventos";

export default {
  data() {
    return {
      loading: false,
      prioridadeMounted: true,
      searchInstituicao: "",
      searchCurso: "",
      listaIncludesInstituicoes: [],
      listaCompletaInstituicoes: [],
      listaBuscaInstituicoes: [],
      listaBuscaCursos: [],
      listaIncludesCursos: [],
      listaCompletaCursos: [],
      listaEstados: [],
      listaCidades: [],
      timeSearch: 300,
      addCurso: false,
      addInst: false,
      listaDePeriodos: [
        { nome: "Integral", value: "DIURNO" },
        { nome: "Matutino", value: "MATUTINO" },
        { nome: "Vespertino", value: "VESPERTINO" },
        { nome: "Noturno", value: "NOTURNO" }
      ],
      selectedPeriodo: "MATUTINO",
      include: {
        id: null,
        nome: "",
        cidadeId: 0,
        estadoId: 0,
        cursos: [],
        instituicoes: [],
        dataFormatura: moment().format("DD/MM/YYYY"),
        tipoEvento: EVENTOS.FORMATURA,
        numeroFormando: 0,
        periodo: "MATUTINO"
      },
      resposta: ""
    };
  },
  mounted() {
    this.getEstados();
    this.include = this.$store.getters["aluno/getTurma"];
    this.include.dataFormatura = moment(
      this.include.dataFormatura,
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");

    this.onChangeEstado();
    this.listaCompletaInstituicoes = this.include.instituicoes;
    this.listaCompletaCursos = this.include.cursos;
    this.prioridadeMounted = false;
  },
  computed: {
    getUserRoleEdit() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] !== "ADMIN";
    },
    getURL_APP() {
      return URL_APP;
    },
    getModal() {
      return this.$store.getters["aluno/getModalNovaTurma"];
    }
  },
  watch: {
    listaBuscaInstituicoes() {
      let itensAtivos = this.listaIncludesInstituicoes;
      let itensSearch = this.listaBuscaInstituicoes;
      this.listaCompletaInstituicoes = itensSearch.concat(itensAtivos);
    },
    listaBuscaCursos() {
      let itensAtivos = this.listaIncludesCursos;
      let itensSearch = this.listaBuscaCursos;
      this.listaCompletaCursos = itensSearch.concat(itensAtivos);
    },
    selectedPeriodo(val) {
      let resp = val.toUpperCase();
      this.include.periodo = resp;
    },
    searchCurso() {
      this.debounceCursos();
    },
    searchInstituicao() {
      this.debounceInstituicao();
    }
  },
  methods: {
    onChangeEstado() {
      serviceLocalidade.getCidadeForEstado(this.include.estadoId).then(resp => {
        this.listaCidades = resp.data;
      });
      if (!this.prioridadeMounted) {
        this.include.cidadeId = null;
      }
    },
    getEstados() {
      serviceLocalidade.getEstado().then(resp => {
        this.listaEstados = resp.data;
      });
    },
    setChangeInstituicao(value) {
      this.listaIncludesInstituicoes = value;
      this.include.instituicoes = value;
    },
    setChangeCurso(value) {
      this.listaIncludesCursos = value;
      this.include.cursos = value;
    },
    setNovaInstituicao() {
      let payload = {
        nome: this.searchInstituicao.trim() || "",
        sigla: "",
        id: 0
      };
      serviceInstituicao
        .includeInst(payload)
        .then(() => {
          this.debounceInstituicao();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          window.console.log(err);
        });
    },
    setNovoCurso() {
      let payload = {
        nome: this.searchCurso.trim() || "",
        id: 0
      };
      serviceCursos
        .includeCurso(payload)
        .then(() => {
          this.debounceCursos();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          window.console.log(err);
        });
    },
    debounceCursos() {
      clearTimeout(this._timerId);
      let search = this.searchCurso ? this.searchCurso : "";
      search = search.trim();
      this._timerId = setTimeout(() => {
        if (!search) {
          return false;
        }
        serviceCursos
          .buscar(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listaBuscaCursos = resp.data;
              if (resp.data.length < 1 && search.length > 0) {
                this.addCurso = true;
              } else {
                this.addCurso = false;
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    debounceInstituicao() {
      clearTimeout(this._timerId);
      let search = this.searchInstituicao ? this.searchInstituicao : "";
      search = search.trim();
      this._timerId = setTimeout(() => {
        if (!search) {
          this.addInst = false;
          return false;
        }
        serviceInstituicao
          .buscar(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listaBuscaInstituicoes = resp.data;
              if (resp.data.length < 1 && search.length > 1) {
                this.addInst = true;
              } else {
                this.addInst = false;
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    cadastrarEvento() {
      this.include.numeroFormando = Number(this.include.numeroFormando);
      if (!this.validateData()) return false;
      if (!this.validacoesCampos()) return false;
      this.loading = true;
      let payload = { ...this.include };
      payload.dataFormatura = moment(
        this.include.dataFormatura,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      controllerTurma
        .alterarEvento(payload)
        .then(() => {
          this.$store.dispatch("aluno/REQUEST_TURMA");
          this.$store.dispatch("aluno/REQUEST_USER");
          this.$root.infoModal("Alterado com sucesso!");
          this.loading = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    validateData() {
      var dataValue = this.include.dataFormatura;
      let dt = moment(dataValue, "DD/MM/YYYY");
      if (!dataValue || dataValue.length != 10) {
        this.$root.errorModal(
          "O formato da data está inválido. modelo DD/MM/AAAA"
        );
        return false;
      }
      if (!dt.isValid()) {
        this.$root.errorModal("A data está inválida");
        return false;
      }
      if (!moment().isBefore(dt)) {
        this.$root.errorModal("A data deve ser maior que o dia atual");
        return false;
      }
      return true;
    },
    validacoesCampos() {
      let estadoID = this.include.estadoId;
      let cidadeID = this.include.cidadeId;
      if (!this.include.instituicoes || this.include.instituicoes == "") {
        this.$root.errorModal("Instituição Inválida");
        return false;
      }
      if (!this.include.cursos || this.include.cursos == "") {
        this.$root.errorModal("Curso Inválido");
        return false;
      }
      if (!this.include.numeroFormando || this.include.numeroFormando <= 0) {
        this.$root.errorModal("Quantidade inválida");
        return false;
      }
      if (!this.include.periodo) {
        this.$root.errorModal("Periodo incorreto");
        return false;
      }
      if (!estadoID) {
        this.$root.errorModal("Estado não está selecionado");
        return false;
      }
      if (!cidadeID) {
        this.$root.errorModal("Cidade não está selecionada");
        return false;
      }
      if (!this.include.nome || this.include.length < 3) {
        this.$root.errorModal("Nome inválido, deve conter 3 caracteres");
        return false;
      }
      if (!this.include.dataFormatura) {
        this.$root.errorModal("Data da Formatura está incorreta");
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.mensagem {
  font-size: 16px;
  color: rgb(30, 30, 30);
}
.exposicao {
  padding: 20px 10px;
}
$btnAction: #111b47;
.btnFechar {
  color: white;
  background-color: $btnAction;
  width: 80%;
}
.request {
  padding: 10px;
  font-size: 20px;
  color: rgb(30, 30, 30);
  text-align: center;
  .msg {
    padding: 10px 0px;
    font-size: 16px;
  }
  button {
    padding: 10px 14px;
    display: inline-block;
    margin: 0 auto;
  }
}
.titulo {
  font-size: 25px;
  padding: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: center;
}
</style>
