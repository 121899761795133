<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="550px"
    transition="dialog-transition"
  >
    <v-card class="pa-2">
      <div class="d-grid grid-col-1-auto">
        <vModalTitulo class="pa-4">GERAR CONTRIBUIÇÃO</vModalTitulo>
        <div>
          <v-btn icon text @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="pa-2">
        <div class="form">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="6">
              <div class="text-center">
                <v-text-field
                  :value="dataVencimentoValue"
                  readonly
                  label="Data de Vencimento"
                  hide-details
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="text-center">
                <v-text-field
                  v-model.lazy="includeValor"
                  v-money="$root.money"
                  hide-details
                  prefix="R$"
                  id="valorParaPagar"
                  label="Valor do Serviço ou Produto"
                />
              </div>
            </v-col>
          </v-row>
          <div class="text-center py-3">
            <v-text-field
              v-model="include.descricao"
              label="Descreva o produto ou serviço"
            ></v-text-field>
          </div>

          <div class="pa-2 text-center">
            <v-btn
              :loading="loading"
              color="primary"
              @click="gerarContribuicao()"
              >GERAR CONTRIBUIÇÃO</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import serviceContribuicoes from "@/middleware/contribuicoes";

export default {
  data() {
    return {
      dialog: true,
      loading: false,
      includeValor: 0,
      dataVencimentoValue: "",
      include: {
        dataVencimento: "",
        descricao: "",
        valor: 0
      }
    };
  },
  methods: {
    dataVencimentoUI(dt) {
      this.dataVencimentoValue = moment(dt, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    tratamentoDeValor() {
      let ValorInclude = this.includeValor;
      if (ValorInclude.indexOf(".") !== -1) {
        ValorInclude = ValorInclude.replaceAll(".", "");
      }
      if (ValorInclude.indexOf(",") !== -1) {
        ValorInclude = ValorInclude.replaceAll(",", ".");
      }
      ValorInclude = Number(ValorInclude);
      if (ValorInclude < 0 || !ValorInclude) {
        this.$root.errorModal("Valor não pode ser menor ou igual a zero.");
        return false;
      }
      return Number(ValorInclude);
    },
    gerarContribuicao() {
      if (!this.tratamentoDeValor()) {
        return false;
      }
      if (this.include.descricao.length < 3) {
        this.$root.errorModal("Descrição está muito pequena");
        return false;
      }
      this.loading = true;
      this.include.valor = this.tratamentoDeValor();
      let payload = {
        dataVencimento: this.include.dataVencimento,
        descricao: this.include.descricao,
        valor: this.include.valor
      };
      serviceContribuicoes
        .gerarContribuicao(payload)
        .then(() => {
          this.$emit("update");
          this.$root.infoModal(
            "Contribuição gerada com sucesso, consulte na lista"
          );
          this.loading = false;
          this.$emit("close");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    }
  },
  mounted() {
    let next10 = moment()
      .add(10, "d")
      .format("YYYY-MM-DD");
    this.include.dataVencimento = next10;
    this.dataVencimentoUI(next10);
  }
};
</script>

<style lang="scss" scoped>
.cardAction {
  border: 3px solid rgba(255, 255, 255, 0);
  &:hover {
    border: 3px solid $primary;
  }
}
.okClass {
  color: rgba(20, 114, 34, 1);
}
.errorClass {
  color: rgba(114, 34, 34, 1);
}
</style>
