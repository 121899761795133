<template>
  <div v-show="user.enderecoCompleto">
    <v-row class="mb-5 align-center justify-center">
      <v-col cols="12" sm="7">
        <vTitulo>Perfil</vTitulo>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        class="text-left d-none d-sm-block text-center justify-center"
      >
        <v-btn color="primary" large @click="abrirContrato()"
          >Ver contrato</v-btn
        >
      </v-col>
    </v-row>

    <div class="design">
      <img src="@/assets/design/bubble.svg" alt="" />
    </div>
    <div class="perfilSection cardShadow mb-5">
      <div class="fotoExposta">
        <a class="relative" @click="selectImagem()">
          <avatar color="white" :size="126" :imagem="user.fotoUrl" /><br />
          <div class="containUploadBtn">
            <v-btn color="primary"
              ><v-icon size="20" color="white">mdi-camera-plus</v-icon>
              <span class="ml-1">Alterar</span></v-btn
            >
          </div>
        </a>
      </div>
      <div class="d-block d-sm-none pa-5 my-3 mt-6 text-center">
        <v-btn color="primary" @click="abrirContrato()">Ver contrato</v-btn>
      </div>
      <div
        class="text-center text-subtitle-1 grey--text text--darken-1 py-8 px-5 font-weight-light"
      >
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="defineImagem()"
          accept=".png,.jpg,.jpeg,.svg"
          v-show="false"
        />
        Quem nunca sonhou em um dia chegar lá não sabe o valor que tem uma
        vitória. A gente sonhou, acreditou e chegou aqui. Viva!
      </div>
      <v-row class="text-center justify-center" justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            class="justify-center"
            label="Nome"
            v-model="user.nome"
          />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            class="justify-center"
            label="Sobrenome"
            v-model="user.sobreNome"
          />
        </v-col>
      </v-row>
      <v-row class="text-center justify-center" justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            class="justify-center"
            label="Email"
            :value="user.email"
            disabled
          />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            v-mask="'##/##/####'"
            label="Data de Nascimento"
            :value="userDataNascimento"
            v-model="userDataNascimento"
          />
        </v-col>
      </v-row>
      <v-row class="text-center justify-center" justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="CPF" :value="user.cpf" disabled />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="RG" :value="user.rg" disabled />
        </v-col>
      </v-row>
      <v-row class="text-center justify-center" justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            class="justify-center"
            label="Telefone"
            v-model="user.telefone"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            class="justify-center"
            label="Nome do Formando"
            v-model="user.nomeFormando"
          />
        </v-col>
      </v-row>
      <v-row class="text-center justify-center" justify="center">
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            class="justify-center"
            label="Pai"
            v-model="user.nomePai"
          />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field label="Mãe" v-model="user.nomeMae" />
        </v-col>
      </v-row>
      <div class="pa-3 mb-3 py-4 grey lighten-3 rounded-lg text-center">
        <div class="pa-0">
          <v-avatar
            size="45"
            color="secondaryDash"
            class="text-center mt-n10 cursorPointer"
            style="position: relative;"
            @click="openEditEndereco()"
          >
            <v-icon size="30" dark>
              mdi-pencil
            </v-icon>
          </v-avatar>
        </div>
        <v-row v-if="user.enderecoCompleto">
          <v-col cols="12" sm="6">
            <div class="font-weight-bold">CEP</div>
            <div>{{ user.enderecoCompleto.cep }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold">Cidade, UF</div>
            <div>
              {{ user.enderecoCompleto.cidadeNome }},
              {{ user.enderecoCompleto.estado }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold" style="word-wrap: auto">
              Logradouro
            </div>
            <div>
              {{ user.enderecoCompleto.logradouro }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold">Número</div>
            <div>
              {{ user.enderecoCompleto.numero }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold">Bairro</div>
            <div>
              {{ user.enderecoCompleto.bairro }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="font-weight-bold">Complemento</div>
            <div>
              {{ user.enderecoCompleto.complemento }}
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        class="text-subtitle-1 text-center pa-5 my-2 black--text"
        v-if="avisoEnderecoEditado"
      >
        <v-alert dense type="info" color="secondary">
          O endereço <strong>foi modificado em tela</strong>, para salvar todas
          as modificações basta clicar em <strong>salvar</strong>.
        </v-alert>
      </div>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-btn color="primary" @click="POST_DADOS_USER()">
            SALVAR
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <vModalEditEndereco
      @save="saveEditEndereco"
      @close="closeEditEndereco"
      v-if="toggleModalEndereco"
      :endereco="user.enderecoCompleto"
    />
  </div>
</template>

<script>
import avatar from "@/components/global/avatar";
import userController from "@/middleware/userController";
import vModalEditEndereco from "./editEndereco";
import moment from "moment";

export default {
  components: {
    avatar,
    vModalEditEndereco
  },
  data() {
    return {
      file: {},
      user: {},
      userDataNascimento: "",
      toggleModalEndereco: false,
      avisoEnderecoEditado: false
    };
  },
  watch: {
    GET_USER() {
      this.getUserDados();
    }
  },
  computed: {
    GET_USER_DATANASCIMENTO() {
      let dataNascOriginal = this.$store.getters["aluno/getUser"]
        .dataNascimento;
      let dataPayload = moment(dataNascOriginal, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
      return dataPayload;
    },
    GET_USER() {
      return this.$store.getters["aluno/getUser"];
    }
  },
  methods: {
    getUserDados() {
      let payload = this.$store.getters["aluno/getUser"];
      let dataBin = payload.dataNascimento;
      let dataFormatadaParaUsuario = moment(dataBin, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
      this.userDataNascimento = dataFormatadaParaUsuario;
      this.user = { ...payload };
      this.user.dataNascimento = dataFormatadaParaUsuario;
    },
    openEditEndereco() {
      this.toggleModalEndereco = true;
    },
    closeEditEndereco() {
      this.toggleModalEndereco = false;
    },
    saveEditEndereco(DadosEndereco) {
      this.user.enderecoCompleto = DadosEndereco;
      this.avisoEnderecoEditado = true;
      this.closeEditEndereco();
    },
    abrirContrato() {
      let URL_CONTRATO = this.user.contratoUrl;
      window.open(`${URL_CONTRATO}`, "_viewContratoUrl");
    },
    uploadImagem() {
      let id = this.user.id;
      let imagem = this.file;
      userController
        .uploadImagemPerfil(id, imagem)
        .then(() => {
          this.$root.ATUALIZA_DADOS(); //TODO:  debugar old feature exists new feature via action
          this.$root.infoModal("Imagem atualizado com sucesso");
        })
        .catch(err => {
          console.error(err);
          this.$root.infoModal("Ocorreu um erro, consulte o suporte");
        });
    },
    defineImagem() {
      let makefile = document.querySelector("#file").files[0];
      let formData = new FormData();
      formData.append("file", makefile);
      this.file = formData;
      this.uploadImagem();
    },
    selectImagem() {
      document.getElementById("file").click();
    },
    verificacoes() {
      if (!this.$root.validaDataNascimento(this.userDataNascimento)) {
        this.$root.errorModal(
          "Data de Nascimento não está conforme dd/mm/aaaa (exemplo 01/02/2000)"
        );
        return false;
      }
      if (!this.user.nome || this.user.nome.length < 3) {
        this.$root.errorModal("Nome está com menos de três letras");
        return false;
      }
      if (!this.user.sobreNome || this.user.sobreNome.length < 3) {
        this.$root.errorModal("Sobrenome não está conforme");
        return false;
      }
      if (!this.user.nomeMae || this.user.nomeMae.length < 3) {
        this.$root.errorModal("Nome da mãe não está conforme");
        return false;
      }
      return true;
    },

    POST_DADOS_USER() {
      let id = this.user.id;
      if (!this.verificacoes()) return false;
      let payloadData = moment(this.userDataNascimento, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      let payload = {
        dataNascimento: payloadData,
        enderecoCompleto: this.user.enderecoCompleto,
        endereco: "",
        nome: this.user.nome,
        sobreNome: this.user.sobreNome,
        nomeMae: this.user.nomeMae,
        nomePai: this.user.nomePai,
        telefone: this.user.telefone,
        nomeFormando: this.user.nomeFormando
      };
      userController
        .updateUserDados(id, payload)
        .then(() => {
          this.$root.infoModal("Alterado com sucesso");
          this.avisoEnderecoEditado = false;
          this.$store.dispatch("aluno/REQUEST_USER");
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.error(err);
        });
    }
  },
  mounted() {
    this.getUserDados();
  }
};
</script>

<style lang="scss" scoped>
.containUploadBtn {
  display: inline-block;
  margin-top: 10px;
}
.design {
  img {
    margin-left: 50px;
  }
}
.perfilSection {
  background-color: rgb(255, 255, 255);
  display: block;
  position: relative;
  margin: 0px auto;
  padding: 20px;
  min-width: auto;
  width: 83%;
  margin-top: -100px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 2px rgba(50, 50, 50, 0.1);
  .fotoExposta {
    text-align: center;
    margin-top: -100px;
    z-index: -2;
    img {
      width: 146px;
      height: 146px;

      object-fit: cover;
      border-radius: 100px;
    }
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    .scope {
      .label {
        font-size: 11px;
        font-weight: $fontDashMedium;
        color: $colorDarkPurple;
        padding: 5px;
        height: 15px;
      }
      .input {
        font-size: 13;
        font-weight: $fontDashRegular;
        &::placeholder {
          font-size: 13px;
          font-weight: $fontDashMedium;
        }
      }
    }
  }
}
</style>
