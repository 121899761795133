<template>
  <div>
    <div
      class="text-center text-sm-left text-h5 text-sm-h5 font-weight-bold primaryDash--text pa-3 pa-sm-5 justify-center"
    >
      <div class="d-inline-block">
        Pagamentos a fornecedores
      </div>
    </div>

    <div class="pa-2 ma-2 text-center text-sm-left">
      <v-btn
        color="secondaryDash"
        class="white--text"
        @click="abrirModalSelectSolicitacao()"
        depressed
        v-if="isAdmin"
      >
        Nova Solicitação
      </v-btn>
    </div>

    <div class="lista ma-0 ma-sm-2">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6" class="text-center text-sm-left">
          <div
            class="text-body-1 py-2 grey--text text--darken-1 d-inline-grid grid-col-auto-1 text-left"
          >
            <v-icon class="px-1">mdi-view-list-outline</v-icon>
            <div>
              Solicitações
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="py-2 text-center">
            <v-btn
              @click="abrirModalLegenda()"
              depressed
              color="white"
              class="text-body-1 lowShadow d-inline-grid grid-col-auto-1 justify-center align-center"
            >
              <v-icon class="px-1">mdi-information-outline</v-icon>
              <div>Legendas</div>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-expansion-panels accordion class="elevation-0 lowShadow">
        <v-expansion-panel
          v-for="(item, index) in getSolicitacoes"
          :key="index"
        >
          <v-expansion-panel-header class="pa-1">
            <div class="d-grid grid-col-auto-1">
              <div class="d-inline-block mx-1">
                <v-icon
                  :color="getColorStatus(item.status)"
                  class="pa-2"
                  :title="getLegendaStatus(item.status)"
                >
                  mdi-circle
                </v-icon>
              </div>
              <div
                class="d-inline-block mx-1 font-weight-bold text-body-1 text-left py-2 cor-text-header text-wrap"
              >
                {{ item.descricao }}
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="innerExPan grey lighten-5 pa-0 ma-0"
          >
            <div class="uiInteraction"></div>
            <div class="adicionarAnexo pa-2 text-center">
              <div class="mx-1">
                <v-row justify="center" class="text-center">
                  <v-col
                    cols="12"
                    :sm="isAdmin && !['PAGO'].includes(item.status) ? 6 : 12"
                  >
                    <v-btn
                      @click="abrirAnexoSolicitacao(item)"
                      text
                      color=""
                      class="cor-valor"
                      append-icon="mdi-paperclip"
                    >
                      <v-icon>
                        mdi-paperclip
                      </v-icon>
                      anexos
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="isAdmin && !['PAGO'].includes(item.status)"
                  >
                    <v-btn
                      @click="reprovarSolicitacao(item)"
                      text
                      :loading="loadingCancelar"
                      v-if="isAdmin"
                      color="red lighten-1"
                    >
                      <v-icon class="mr-1">
                        mdi-cancel
                      </v-icon>
                      Cancelar
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="infoSolicitacao cor-valor">
              <div class="pa-3">
                <div class="px-2 text-body-2 font-weight-bold text-center">
                  Pagar até o dia:
                </div>
                <div class="px-2 text-h5 text-center font-weight-light">
                  {{ item.dataPagamento | dataFormate }}
                </div>
                <div class="px-2 text-body-2 font-weight-bold text-center">
                  Pagamento para:
                </div>
                <div class="px-2 text-h5 text-center font-weight-light">
                  {{ existeDadosFornecedor(item) }}
                </div>
                <div class="py-2 "></div>
                <div
                  class="text-h4 px-5 pa-0 pb-2 text-center font-weight-normal"
                >
                  {{ item.valor | currency }}
                </div>
                <div class="py-2 "></div>
                <div class="px-5 text-body-1 font-weight-bold text-center">
                  <v-chip outlined :color="getColorStatus(item.status)">
                    {{ item.status | statusTranslater }}
                  </v-chip>
                </div>
              </div>
            </div>
            <div class="votos">
              <v-row
                no-gutters
                class="mx-1"
                v-if="isCommission && getPermissaoVotoStatus(item.status)"
              >
                <v-col cols="12" sm="6" class="pa-1 text-center text-sm-right">
                  <v-btn
                    color="grey lighten-3 elevation-0"
                    @click="votarSIM(item.id)"
                    large
                    :loading="loadingVotacao"
                  >
                    <v-icon size="25" color="#32d280">mdi-thumb-up</v-icon>
                    <span class="ml-2">
                      APROVAR
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="pa-1 text-center text-sm-left">
                  <v-btn
                    color="grey lighten-3 elevation-0"
                    @click="votarNAO(item.id)"
                    :loading="loadingVotacao"
                    large
                  >
                    <v-icon size="25" color="#d23732">mdi-thumb-down</v-icon>
                    <span class="ml-2">
                      REPROVAR
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="mx-1"
                v-if="isAdmin && ['EMPATE'].includes(item.status)"
              >
                <v-col cols="12" sm="6" class="pa-1 text-center text-sm-right">
                  <v-btn
                    color="grey lighten-3 elevation-0"
                    @click="votarSIM(item.id)"
                    large
                    :loading="loadingVotacao"
                  >
                    <v-icon size="25" color="#32d280">mdi-gavel</v-icon>
                    <span class="ml-2">
                      APROVAR
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="pa-1 text-center text-sm-left">
                  <v-btn
                    color="grey lighten-3 elevation-0"
                    @click="votarNAO(item.id)"
                    :loading="loadingVotacao"
                    large
                  >
                    <v-icon size="25" color="#d23732">mdi-gavel</v-icon>
                    <span class="ml-2">
                      REPROVAR
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="ma-0 pa-2">
              <!-- VOTOS -->
              <div
                class="d-block justify-left align-content-start white fill-height"
              >
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" sm="7" md="6" class="order-2 order-sm-1">
                    <div class="votos text-center text-sm-left">
                      <div class="pa-2 font-weight-bold">
                        Votos
                      </div>
                      <div class="votosCards" v-if="item.votos.length > 0">
                        <v-card
                          :key="index2"
                          v-for="(itemVoto, index2) in item.votos"
                          width="285"
                          class="elevation-0 grey lighten-4 mt-2 d-sm-block d-inline-block"
                        >
                          <v-row class="pa-0 ma-0" justify="center">
                            <v-col cols="4" sm="4" class="text-left">
                              <v-avatar
                                size="75"
                                color="grey lighten-2"
                                class="white--text"
                              >
                                <v-img :src="itemVoto.fotoUrl"></v-img>
                              </v-avatar>
                            </v-col>
                            <v-col cols="8" sm="8" class="text-center">
                              <div
                                class="text-body-1 py-2 font-weight-normal text-truncate"
                              >
                                {{ itemVoto.usuarioNome }}
                              </div>
                              <div class="text-body-2">
                                <v-icon
                                  v-if="itemVoto.voto == 'SIM'"
                                  color="#32d280"
                                  size="35"
                                  >mdi-thumb-up</v-icon
                                >
                                <v-icon
                                  size="35"
                                  v-if="itemVoto.voto == 'NAO'"
                                  color="#d23732"
                                  >mdi-thumb-down</v-icon
                                >
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="5" md="6" class="order-1 order-sm-2">
                    <div class="analise">
                      <div
                        class="pa-2 text-center text-sm-left font-weight-bold"
                      >
                        Contagem
                      </div>
                      <div class="likes">
                        <div class="pa-2 text-center text-sm-left">
                          <div class="d-inline-grid">
                            <v-icon size="35" class="cor-valor"
                              >mdi-thumb-up</v-icon
                            >
                          </div>
                          <div class="d-inline-grid ml-2">
                            <div class="text-h4 cor-valor">
                              {{ item.percentualVotoSim }}%
                            </div>
                          </div>
                        </div>
                        <div class="pa-2 text-center text-sm-left">
                          <div class="d-inline-grid">
                            <v-icon size="35" class="cor-valor"
                              >mdi-thumb-down</v-icon
                            >
                          </div>
                          <div class="d-inline-grid ml-2">
                            <div class="text-h4 cor-valor">
                              {{ item.percentualVotoNao }}%
                            </div>
                          </div>
                        </div>
                        <div class="pa-2 text-center text-sm-left">
                          <div class="d-inline-grid">
                            <v-icon size="35" class="cor-valor"
                              >mdi-account-question</v-icon
                            >
                          </div>
                          <div class="d-inline-grid ml-2">
                            <div class="text-h4 cor-valor">
                              {{ item.percentualVotoPendente }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <vGuiaLegendas v-if="modalGuiaLegendas" @close="closeModalLegenda" />
    <vAnexosDaSolicitacao
      @close="fecharAnexoSolicitacao"
      :solicitacao="itemSolicitacaoAnexo"
      v-if="modalAnexoSolicitacao"
    />
    <vNovaSolicitacao
      @close="closeModalSolicitacao"
      v-if="modalNovaSolicitacao"
    />
    <vNovaSolicitacaoInterna
      @close="closeModalSolicitacaoInterna"
      v-if="modalNovaSolicitacaoInterna"
    />
    <vSelecionaTipoDeSolicitacao
      v-if="modalTipoSolicitacao"
      @selecionaTipo="selecionaTipoSolicitacao"
      @close="modalTipoSolicitacao = false"
    />
  </div>
</template>

<script>
import vNovaSolicitacao from "./novaSolicitacao";
import serviceSolicitacoes from "@/middleware/solicitacoesDePagamento";
import vGuiaLegendas from "./guiaStatus";
import vAnexosDaSolicitacao from "./modalAnexosDaSolicitacao";
import vSelecionaTipoDeSolicitacao from "./vSelecionaTipoDeSolicitacao.vue";
import vNovaSolicitacaoInterna from "./novaSolicitacaoInterna.vue";

export default {
  components: {
    vNovaSolicitacao,
    vNovaSolicitacaoInterna,
    vGuiaLegendas,
    vAnexosDaSolicitacao,
    vSelecionaTipoDeSolicitacao
  },
  data() {
    return {
      modalNovaSolicitacaoInterna: false,
      modalTipoSolicitacao: false,
      modalAnexoSolicitacao: false,
      loadingVotacao: false,
      modalGuiaLegendas: false,
      modalNovaSolicitacao: false,
      itemSolicitacaoAnexo: {},
      loadingCancelar: false
    };
  },
  mounted() {
    this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
  },
  methods: {
    existeDadosFornecedor(dados) {
      if (dados.fornecedor && dados.fornecedor.nome) {
        return dados.fornecedor.nome;
      } else if (dados.destino) {
        return dados.destino;
      } else {
        return "";
      }
    },
    closeModalSolicitacaoInterna() {
      this.modalNovaSolicitacaoInterna = false;
    },
    abrirModalSelectSolicitacao() {
      this.modalTipoSolicitacao = true;
    },
    selecionaTipoSolicitacao(tipo) {
      this.modalTipoSolicitacao = false;
      if (tipo === "evento") {
        this.modalNovaSolicitacaoInterna = true;
      } else if (tipo === "fornecedor") {
        this.modalNovaSolicitacao = true;
      } else {
        this.$root.errorModal("TIPO de solicitação não implementada");
      }
    },
    getPermissaoVotoStatus(statusName) {
      console.log(statusName);
      if (statusName === "AGUARDANDO_AUTORIZACAO_COMISSAO") {
        return true;
      } else if (statusName === "AUTORIZADO") {
        return true;
      } else {
        return false;
      }
    },
    reprovarSolicitacao(solicitacao) {
      this.loadingCancelar = true;
      serviceSolicitacoes
        .cancelarSolicitacao(solicitacao.id)
        .then(resp => {
          this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
          this.loadingCancelar = false;
          this.$root.infoModal(resp.data);
        })
        .catch(err => {
          this.loadingCancelar = false;
          this.$root.errorModal(err.data);
          console.error(err);
        });
    },
    fecharAnexoSolicitacao() {
      this.modalAnexoSolicitacao = false;
      this.itemSolicitacaoAnexo = {};
    },
    abrirAnexoSolicitacao(item) {
      this.itemSolicitacaoAnexo = item;
      this.modalAnexoSolicitacao = true;
    },
    abrirModalLegenda() {
      this.modalGuiaLegendas = true;
    },
    closeModalLegenda() {
      this.modalGuiaLegendas = false;
    },
    closeModalSolicitacao() {
      this.modalNovaSolicitacao = false;
    },
    removerSolicitacao(solicitacaoID) {
      serviceSolicitacoes.removerSolicitacao(solicitacaoID).then;
      //this.$root.
    },
    votarSIM(solicitacaoID) {
      this.loadingVotacao = true;
      serviceSolicitacoes
        .setVotoSIM(solicitacaoID)
        .then(this.votoConfirmado)
        .catch(this.votoError);
    },
    votarNAO(solicitacaoID) {
      this.loadingVotacao = true;
      solicitacaoID;
      serviceSolicitacoes
        .setVotoNAO(solicitacaoID)
        .then(this.votoConfirmado)
        .catch(this.votoError);
    },
    votoError(err) {
      this.loadingVotacao = false;
      this.$root.errorModal(err.data);
      console.error(err);
    },
    votoConfirmado() {
      this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
      this.$root.sucessoModal("Voto efetuado com sucesso!");
      this.loadingVotacao = false;
    },
    getLegendaStatus(val) {
      if (val == "AGUARDANDO_AUTORIZACAO_COMISSAO")
        return "Aguardando autorização da comissão";
      if (val == "AUTORIZADO") return "Aprovado pela comissão";
      if (val == "RECUSADO_PELA_COMISSAO")
        return "Recusado por falta de autorização da comissão";
      if (val == "RECUSADO_PELA_PLATAFORMA") return "Recusado pela plataforma";
      if (val == "RECUSADO_SEM_SALDO")
        return "Recusado por falta de saldo na data do pagamento";
      if (val == "PAGO") return "Pago";
      if (val == "CANCELADO") return "Cancelado";
      if (val == "EMPATE") return "Empate";
    },
    getColorStatus(val) {
      if (val == "AGUARDANDO_AUTORIZACAO_COMISSAO") return "#6C5CE7";
      if (val == "AUTORIZADO") return "#0984E3";
      if (val == "RECUSADO_PELA_COMISSAO") return "#EA5455";
      if (val == "RECUSADO_PELA_PLATAFORMA") return "#EA5455";
      if (val == "RECUSADO_SEM_SALDO") return "#EA5455";
      if (val == "PAGO") return "#28C76F";
      if (val == "CANCELADO") return "#B2BEC3";
      if (val == "EMPATE") return "#ffa801";
    }
  },
  filters: {
    hasData(val) {
      if (val) {
        return val;
      } else {
        return "S/ REGISTRO";
      }
    },
    statusTranslater(val) {
      if (val == "AGUARDANDO_AUTORIZACAO_COMISSAO")
        return "Aguardando autorização da comissão";
      if (val == "AGUARDANDO_ANALISE") return "Em analise";
      if (val == "AUTORIZADO") return "Aprovado pela comissão";
      if (val == "RECUSADO_PELA_COMISSAO") return "Recusado pela comissão";
      if (val == "RECUSADO_PELA_PLATAFORMA") return "Recusado pela plataforma";
      if (val == "RECUSADO_SEM_SALDO") return "Recusado por saldo";
      if (val == "PAGO") return "Pago";
      if (val == "CANCELADO") return "Cancelado";
      if (val == "EMPATE") return "Empate";
    }
  },
  computed: {
    getSolicitacoes() {
      return this.$store.getters["aluno/getSolicitacoesDePagamento"];
    },
    isCommission() {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "COMISSAO") {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN" ||
        this.$store.getters["aluno/userIsAdmin"]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$likeColor: #32d280;
$unlikeColor: #d23732;
$expansion-panel-content-padding: 0px;

.cor-text-header {
  color: #5e5873;
}
.cor-valor {
  color: #5e5873;
}
.v-expansion-panel-content__wrap {
  padding: 0px;
}
.votos {
  //overflow-y: auto;
  //overflow-x: hidden;
  .votosCards {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
  }
}
</style>
