<template>
  <div class="pa-5 pa-sm-8">
    <v-text-field
      label="Nome do evento *"
      v-model="include.nome"
      disabled
    ></v-text-field>
    <v-text-field
      label="Descrição  *"
      v-model="include.tipoEventoDescricao"
      disabled
    ></v-text-field>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          hide-details
          label="CEP"
          v-model="include.enderecoCompleto.cep"
          :loading="loadingCEP"
          v-mask="['#####-###']"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <v-btn
          :loading="loadingCEP"
          color="#9B51E0"
          depressed
          dark
          large
          @click="buscarCEP()"
          disabled
          >Buscar CEP</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          hide-details
          :items="listaEstados"
          label="Estado *"
          v-model="include.estadoId"
          item-text="nome"
          item-value="id"
          :loading="loadingEstado"
          no-data-text="Carregando"
          @change="onChangeEstado()"
          disabled
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          hide-details
          :items="listaCidades"
          label="Cidade * "
          v-model="include.cidadeId"
          item-text="descricao"
          item-value="id"
          no-data-text="Pesquise o estado primeiro"
          @change="onChangeCidade()"
          :loading="loadingCidades"
          disabled
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          hide-details
          v-model="include.enderecoCompleto.logradouro"
          label="logradouro"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          hide-details
          v-model="include.enderecoCompleto.bairro"
          label="Bairro"
          disabled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          hide-details
          v-model="include.enderecoCompleto.numero"
          label="Número"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          hide-details
          v-model="include.enderecoCompleto.complemento"
          label="complemento"
          disabled
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import serviceLocal from "@/middleware/localidadeController";
import EVENTOS from "@/constants/participante/eventos";
import serviceTurma from "@/middleware/turmaController";

export default {
  beforeMount() {
    this.buscaEstado();
  },
  mounted() {
    this.include = this.$store.getters["aluno/getTurma"];
    this.buscaCidade(this.include.estadoId);
    this.restrictMount = false;
  },
  data() {
    return {
      loading: false,
      loadingCidades: false,
      loadingEstado: false,
      loadingCEP: false,
      listaEstados: [],
      listaCidades: [],
      restrictMount: true,
      include: {
        nome: "",
        tipoEventoDescricao: "",
        estadoId: "",
        cidadeId: "",
        tipoEvento: EVENTOS.OUTRO,
        enderecoCompleto: {
          logradouro: "",
          cidadeNome: "",
          bairro: "",
          estado: "",
          numero: "",
          complemento: "",
          cep: "",
          cidadeIbge: 0
        }
      }
    };
  },
  computed: {
    getUserRoleEdit() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] !== "ADMIN";
    },
    getEstadoSetadoToggle() {
      return this.include.estadoId ? false : true;
    }
  },
  methods: {
    salvarAlteracao() {
      if (!this.verificaDados()) return false;
      let CEPTratamento = this.include.enderecoCompleto.cep;
      if (CEPTratamento.includes("-")) {
        let tratamento1 = CEPTratamento.replace("-", "");
        this.include.enderecoCompleto.cep = tratamento1;
      }
      serviceTurma
        .alterarEvento(this.include)
        .then(() => {
          this.$store.dispatch("aluno/REQUEST_TURMA");
          this.$store.dispatch("aluno/REQUEST_USER");
          this.$root.infoModal("Alterado com sucesso!");
          this.loading = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    verificaDados() {
      if (!this.include.nome && this.include.nome.length < 3) {
        this.$root.errorModal("Nome inválido");
        return false;
      }
      if (
        !this.include.tipoEventoDescricao ||
        this.include.tipoEventoDescricao.length < 5
      ) {
        this.$root.errorModal("Descrição do evento muito pequena");
        return false;
      }
      if (this.include.enderecoCompleto.cep.length !== 9) {
        this.$root.errorModal("CEP inválido");
        return false;
      }
      if (
        !this.include.enderecoCompleto.logradouro ||
        this.include.enderecoCompleto.logradouro.length < 3
      ) {
        this.$root.errorModal("Logradouro inválido");
        return false;
      }
      if (
        !this.include.enderecoCompleto.bairro ||
        this.include.enderecoCompleto.bairro.length < 3
      ) {
        this.$root.errorModal("Bairro inválido");
        return false;
      }
      if (
        !this.include.enderecoCompleto.cep ||
        this.include.enderecoCompleto.cep.length !== 9
      ) {
        this.$root.errorModal("CEP inválido");
        return false;
      }
      if (!this.include.cidadeId || !this.include.enderecoCompleto.cidadeNome) {
        this.$root.errorModal("Cidade não pode ficar vazio");
        return false;
      }
      return true;
    },
    buscaEstado() {
      this.loadingEstado = true;
      serviceLocal
        .getEstado()
        .then(resp => {
          this.listaEstados = resp.data;
          this.loadingEstado = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loadingEstado = false;
        });
    },
    buscaCidade() {
      serviceLocal
        .getCidadeForEstado(this.include.estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loadingCidades = false;
        });
    },
    onChangeEstado() {
      this.loadingCidades = false;
      this.include.enderecoCompleto.estado = String(this.include.estadoId);
      this.buscaCidade();
      if (!this.restrictMount) {
        this.include.enderecoCompleto.cidadeNome = null;
        this.include.cidadeId = null;
      }
    },
    onChangeCidade() {
      this.include.enderecoCompleto.cidadeNome = String(this.include.cidadeId);
    },
    buscaCidadeAndFind(payload) {
      serviceLocal
        .getCidadeForEstado(this.include.estadoId)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
          let payloadCidade = this.listaCidades.find(
            item =>
              item.descricao.toUpperCase() === payload.localidade.toUpperCase()
          );
          this.include.cidadeId = payloadCidade.id;
          this.include.enderecoCompleto.cidadeNome = String(payloadCidade.id);
          this.loadingCEP = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loadingCidades = false;
          this.loadingCEP = false;
        });
    },
    proccessCityAndState(dados) {
      if (this.listaEstados.length > 0) {
        let payloadEstado = this.listaEstados.find(item => item.uf == dados.uf);
        this.include.estadoId = payloadEstado.id;
        this.include.enderecoCompleto.estado = String(payloadEstado.id);
        this.buscaCidadeAndFind(dados);
      } else {
        this.buscaEstado();
        setTimeout(() => {
          this.proccessCityAndState(dados);
        }, 500);
      }
    },
    processarDadosDoCEP(dados) {
      this.loadingCEP = true;
      this.include.enderecoCompleto.bairro = dados.bairro;
      this.include.enderecoCompleto.logradouro = dados.logradouro;
      this.include.enderecoCompleto.cidadeIbge = Number(dados.ibge);
      this.include.enderecoCompleto.bairro = dados.bairro;
      this.proccessCityAndState(dados);
    },
    buscarCEP() {
      let CEPToSearch = this.include.enderecoCompleto.cep;
      if (CEPToSearch.length !== 9) {
        this.$root.errorModal("CEP inválido");
        return false;
      }
      if (CEPToSearch.includes("-")) {
        CEPToSearch = CEPToSearch.replace("-", "");
      }
      serviceLocal
        .getCep(CEPToSearch)
        .then(resp => {
          this.processarDadosDoCEP(resp.data);
        })
        .catch(err => {
          console.log(err);
          this.$root.errorModal(err.data);
        });
    }
  }
};
</script>
